import { ViewModelAwareInterface } from "src/app/shared/models/view-model-aware.interface";
import { StepViewModelAwareInterface } from "src/app/shared/models/step-view-model-aware.interface";
import { ChangeDetectorRef, Component } from "@angular/core";
import { ReceiptStepsService } from "../../services/receipt-steps.service";
import { ActivatedRoute } from "@angular/router";
import { RouteStateService } from "src/app/shared/services/route-state.service";
import { AuthService, ModalService, OnlineService, TelemetryService } from "@nts/std";
import { Observable } from "rxjs";
import { ReceiptLongOpViewModel } from "../../view-models/receipt-long-op.view-model";
import { ExpenseState } from "src/app/expense-annotation/generated/domain-models/enums/generated-expense-state";
import { ReceiptLongOpApiClient } from "../../api-clients/receipt-long-op.api-client";
import { CreateExpenseAnnotationLongOpApiClient } from "src/app/create-expense-annotation-long-op/api-clients/create-expense-annotation-long-op.api-client";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  template: ''
})
export abstract class BaseStepComponent<T extends StepViewModelAwareInterface> implements ViewModelAwareInterface {

  viewModel: T;
  inIframe = false;

  constructor(
    protected readonly receiptStepsService: ReceiptStepsService,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly routeStateService: RouteStateService,
    protected readonly receiptLongOpApiClient: ReceiptLongOpApiClient,
    protected readonly cd: ChangeDetectorRef,
    protected readonly onlineService: OnlineService,
    protected readonly authService: AuthService,
    protected readonly telemetryService: TelemetryService,
    protected readonly createExpenseAnnotationLongOpApiClient: CreateExpenseAnnotationLongOpApiClient,
    protected readonly sanitizer: DomSanitizer,
    protected readonly modalService: ModalService

  ) {
    this.activatedRoute.paramMap.pipe(this.untilDestroyed()).subscribe((paramMap) => {
      this.routeStateService.updateState(paramMap)
    })

    this.activatedRoute.queryParamMap.pipe(this.untilDestroyed()).subscribe((queryParamMap) => {
      this.routeStateService.updateState(null, queryParamMap)
    })
  }

  abstract onViewModelLoaded(): Promise<void>;

  abstract untilDestroyed(): <U>(source: Observable<U>) => Observable<U>

  async isReadOnly(rootViewModel: ReceiptLongOpViewModel) {
    const currentUserId = await this.authService.getCurrentUserId();
    return !((
      rootViewModel.params.expenseState.value === ExpenseState.WebEditing ||
      rootViewModel.params.expenseState.value === ExpenseState.IntegrateDocumentation
    ) && currentUserId === rootViewModel?.params?.userRef?.id?.value);
  }

  async beforeNextAction(): Promise<void> {
  }
}
