import { CommandFactory, FilledButtonType, MessageResourceManager, ModalViewModelInterface, UIResultCommandInterface, ViewModelTypeDecorator } from "@nts/std";
import { ChangeDetectorRef } from "@angular/core";

@ViewModelTypeDecorator(ExpenseTypeInstructionViewModel)
export class ExpenseTypeInstructionViewModel implements ModalViewModelInterface<any, any> {

  /**
   * The change detector
   */
  cd: ChangeDetectorRef;

  /**
   * The filled button type
   */
  filledButtonType = FilledButtonType;

  /**
   * The modal title
   */
  modalTitle = MessageResourceManager.Current.getMessage('ExpenseData_ExpenseTypeInstruction_Title');

  /**
   * The modal subtitle
   */
  modalSubtitle = '';

  /**
   * The modal commands
   */
  modalCommands = [];

  /**
   * The show footer flag
   */
  showFooter = true;

  /**
   * The confirm command
   */
  private closeCommand: UIResultCommandInterface<boolean, any>;

  rememberToHideThisMessage = false;

  constructor(
    public currentInstructions: string,
    public currentColor: string,
    public currentActiveColor: string,
    public currentHoverColor: string
  ) {
    this.setupCommands();
  }

  /**
   * Setup the commands
   */
  private setupCommands() {
    this.closeCommand = CommandFactory.createResultUICommand<boolean>(async () => {
      return this.rememberToHideThisMessage
    });
    this.closeCommand.displayName = MessageResourceManager.Current.getMessage("ExpenseData_ExpenseTypeInstruction_Close_DisplayName");
    this.closeCommand.closeModal = true;
    this.closeCommand.isCancel = false;
    this.closeCommand.isDefault = true;
    this.modalCommands.push(this.closeCommand);
  }

  /**
   * Initialize the view model
   */
  async initialize(cd): Promise<void> {
    this.cd = cd;
  }
}
