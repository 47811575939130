import { SafeResourceUrl } from '@angular/platform-browser';
import { LocalstorageHelper, OnlineService } from '@nts/std/src/lib/utility';
import { EnumPropertyViewModel, ExternalViewModelTypeDecorator, MessageResourceManager, ModalService, NNumericPropertyViewModel, SourceMessage } from '@nts/std';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ExpenseState } from 'src/app/expense-annotation/generated/domain-models/enums/generated-expense-state';
import { ExpenseModel } from 'src/app/expense-model/domain-models/expense-model';
import { CommissionIdentity } from 'src/app/external-remote/commission/commission.identity';
import { GeneralSubjectIdentity } from 'src/app/external-remote/general-subject/general-subject.identity';
import { LeadIdentity } from 'src/app/external-remote/lead/lead.identity';
import { StepViewModelAwareInterface } from 'src/app/shared/models/step-view-model-aware.interface';
import { ColorUtility } from 'src/app/shared/services/color-utility';
import { ExpenseClassificationMapper } from 'src/app/shared/services/expense-classification-mapper';
import { ExpenseStateMapper } from 'src/app/shared/services/expense-state-mapper';
import { ExpenseClassification } from '../generated/domain-models/enums/generated-expense-classification';
import { GeneratedExpenseDataViewModel } from '../generated/view-models/generated-expense-data.view-model';
import { ReceiptLongOpOrchestratorViewModel } from './receipt-long-op.orchestrator-view-model';
import { ErrorManager } from '../services/errorr-manager.service';
import { ReceiptParamsViewModel } from './receipt-params.view-model';
import { WeaSettingsDataDto } from 'src/app/create-expense-annotation-long-op/api-clients/dto/wea-settings-data.dto';
import { LeadExtViewModel } from './lead.ext-view-model';
import { CustomerExtViewModel } from './customer.ext-view-model';
import { CommissionExtViewModel } from './commission.ext-view-model';
import { ExtendedAvailableExpenseExtViewModel } from './extended-available-expense.ext-view-model';
import { ExtendedAvailableExpense } from '../domain-models/extended-available-expense';
import { ExpenseFileCollectionItemViewModel } from './expense-file.collection-item-view-model';
import { ExpenseTypeInstructionViewModel } from './modals/expense-type-instruction.view-model';
import { PersonalExpenseIdentity } from 'src/app/user-available-expenses/domain-models/personal-expense.identity';
import { AvailableExpenseIdentity } from 'src/app/expense-model/domain-models/available-expense.identity';

export class ExpenseDataViewModel extends GeneratedExpenseDataViewModel implements StepViewModelAwareInterface {

  @ExternalViewModelTypeDecorator(LeadExtViewModel)
  public leadRef: LeadExtViewModel;

  @ExternalViewModelTypeDecorator(CustomerExtViewModel)
  public customerRef: CustomerExtViewModel;

  @ExternalViewModelTypeDecorator(CommissionExtViewModel)
  public commissionRef: CommissionExtViewModel;

  currentImageModalSrc: SafeResourceUrl = null;
  currentImageModalBlob: Blob;
  currentImageModalName: string;
  currentItemInFullScreen: ExpenseFileCollectionItemViewModel;
  currentColor = null;
  currentHoverColor = null;
  currentActiveColor = null;
  currentExpenseClassificationType: ExpenseClassification;
  expenseClassificationEnum = ExpenseClassification;
  isValid = new BehaviorSubject<boolean>(false);
  hasActionsButtons = new BehaviorSubject<boolean>(true);
  isPreviousDisabled = new BehaviorSubject<boolean>(false);
  hideNext = new BehaviorSubject<boolean>(false);
  fullExpenseModel: ExpenseModel = null;
  extendedAvailableExpenses: ExtendedAvailableExpense[] = [];
  isRemoteUpload = false;
  expenseState: EnumPropertyViewModel;
  expenseStateMapper = ExpenseStateMapper;
  showCurrentState: boolean;
  configuration: WeaSettingsDataDto;
  onlineService: OnlineService;
  modalService: ModalService;
  allFilesUploaded: boolean = true

  private _extendedExpenseTypeId: NNumericPropertyViewModel;

  public get extendedExpenseTypeId(): NNumericPropertyViewModel {
    return this.getNNumericPropertyViewModel((value) => { this._extendedExpenseTypeId = value; }, this._extendedExpenseTypeId, 'extendedExpenseTypeId');
  }

  @ExternalViewModelTypeDecorator(ExtendedAvailableExpenseExtViewModel)
  public extendedExpenseTypeRef: ExtendedAvailableExpenseExtViewModel;

  private navigateFullScreenImage(direction: 'next' | 'previous') {
    // costruisce una lista di file che hanno un'anteprima
    const filesWithPreview = this.files.filter(file => file.filePreviewUrl != null);

    // se non ci sono file con anteprima, esce
    if (filesWithPreview.length == 0) {
      return;
    }

    // individua l'indice del file corrente
    const currentIndex = filesWithPreview.indexOf(this.currentItemInFullScreen);

    // se l'indice è -1, esce
    if (currentIndex == -1) {
      return;
    }

    let newIndex: number;
    if (direction === 'next') {
      newIndex = (currentIndex < filesWithPreview.length - 1) ? currentIndex + 1 : 0;
    } else {
      newIndex = (currentIndex > 0) ? currentIndex - 1 : filesWithPreview.length - 1;
    }

    this.openFileFullScreen(
      filesWithPreview[newIndex].filePreviewUrl,
      filesWithPreview[newIndex].fileObject ?? filesWithPreview[newIndex].fileBlob,
      filesWithPreview[newIndex].originalFileName.value,
      filesWithPreview[newIndex]
    );
  }

  /**
   * Visualizza il prossimo file in modalità fullscreen
   *
   * @returns
   */
  showFullScreenNextImage() {
    this.navigateFullScreenImage('next');
  }

  /**
   * Visualizza il file precedente in modalità fullscreen
   *
   * @returns
   */
  showFullScreenPreviousImage() {
    this.navigateFullScreenImage('previous');
  }

  async initPresentationLogic(
    expenseClassificationType: ExpenseClassification,
    fullExpenseModel: ExpenseModel,
    extendedAvailableExpenses: ExtendedAvailableExpense[],
    expenseAnnotationId: number,
    remoteExpense: boolean,
    currencySymbol: string = null,
    expenseState: EnumPropertyViewModel,
    configuration: WeaSettingsDataDto,
    customerIdentity: GeneralSubjectIdentity,
    leadIdentity: LeadIdentity,
    commissionIdentity: CommissionIdentity,
    onlineService: OnlineService,
    modalService: ModalService,
    isEnabled: boolean,
  ) {

    this.isEnabled = isEnabled;
    if (extendedAvailableExpenses.length == 0) {
      await this.extendedExpenseTypeRef.setCodeValue(null);
    } else {
      if (this.extendedExpenseTypeRef?.id?.value > 0) {
        const found = extendedAvailableExpenses.find((eap) => eap.id === this.extendedExpenseTypeRef?.id?.value)
        if (!found) {
          await this.extendedExpenseTypeRef.setCodeValue(null);
        }
      }
    }

    this.showCurrentState = expenseAnnotationId > 0 && remoteExpense === true;
    this.currentExpenseClassificationType = expenseClassificationType;
    this.currentColor = ColorUtility.shade(ExpenseClassificationMapper.getColorByClassificationType(expenseClassificationType), -0.1);
    this.currentActiveColor = ColorUtility.shade(this.currentColor, -0.1)
    this.currentHoverColor = ColorUtility.shade(this.currentColor, -0.3)
    this.isRemoteUpload = expenseAnnotationId > 0;
    this.expenseState = expenseState;
    this.configuration = configuration;
    this.onlineService = onlineService;
    this.modalService = modalService;
    this.fullExpenseModel = fullExpenseModel;
    // #5331 - per attivare lo sblocco del tasto continua appena si modifica e senza uscire dal campo.
    // ATTENZIONE, instantModelChange crea problemi con inputMask, perchè valida appena premi un tasto, non usarlo
    // this.expenseTotalAmount.instantModelChange = true;


    if (currencySymbol) {
      this.expenseTotalAmount.metadataDescription = MessageResourceManager.Current.getMessage(this.expenseTotalAmount.propertyMetaData.descriptions.descriptionKey) + ' (' + currencySymbol + ')';
      this.expenseTotalAmount.metadataShortDescription = MessageResourceManager.Current.getMessage(this.expenseTotalAmount.propertyMetaData.descriptions.displayNameKey) + ' (' + currencySymbol + ')';
    }

    if (!this.isEnabled) {
      this.expenseDate.isEnabled = isEnabled;
      if (this.extendedExpenseTypeRef) {
        this.extendedExpenseTypeRef.isEnabled = isEnabled;
      }
      this.description.isEnabled = isEnabled;
      this.expenseTotalAmount.isEnabled = isEnabled;
      this.note.isEnabled = isEnabled;
      this.files.isEnabled = isEnabled;
      this.expenseTypeDescription.isEnabled = isEnabled;
      this.leadRef.isEnabled = isEnabled;
      this.commissionRef.isEnabled = isEnabled;
      this.customerRef.isEnabled = isEnabled;
    }

    this.commissionRef.isVisible = commissionIdentity?.id == null;
    this.leadRef.isVisible = leadIdentity?.id == null;
    this.customerRef.isVisible = customerIdentity?.id == null;

    if (this.commissionRef.isVisible) {
      this.commissionRef.isVisible = configuration?.manageCommission === true;
      this.commissionRef.defaultColor = this.currentColor;
      this.commissionRef.activeColor = this.currentActiveColor;
      this.commissionRef.hoverColor = this.currentHoverColor;
    }

    if (this.leadRef.isVisible) {
      this.leadRef.isVisible = configuration?.manageLead === true;
      this.leadRef.defaultColor = this.currentColor;
      this.leadRef.activeColor = this.currentActiveColor;
      this.leadRef.hoverColor = this.currentHoverColor;
    }

    if (this.customerRef.isVisible) {
      this.customerRef.isVisible = configuration?.manageCustomer === true;
      this.customerRef.defaultColor = this.currentColor;
      this.customerRef.activeColor = this.currentActiveColor;
      this.customerRef.hoverColor = this.currentHoverColor;
    }

    this.expenseDate.defaultColor = this.currentColor;
    this.expenseDate.activeColor = this.currentActiveColor;
    this.expenseDate.hoverColor = this.currentHoverColor;

    if (this.extendedExpenseTypeRef) {
      this.extendedExpenseTypeRef.defaultColor = this.currentColor;
      this.extendedExpenseTypeRef.activeColor = this.currentActiveColor;
      this.extendedExpenseTypeRef.hoverColor = this.currentHoverColor;
    }

    this.description.defaultColor = this.currentColor;
    this.description.activeColor = this.currentActiveColor;
    this.description.hoverColor = this.currentHoverColor;

    this.expenseTotalAmount.defaultColor = this.currentColor;
    this.expenseTotalAmount.activeColor = this.currentActiveColor;
    this.expenseTotalAmount.hoverColor = this.currentHoverColor;

    this.note.defaultColor = this.currentColor;
    this.note.activeColor = this.currentActiveColor;
    this.note.hoverColor = this.currentHoverColor;

    this.files.defaultColor = this.currentColor;
    this.files.activeColor = this.currentActiveColor;
    this.files.hoverColor = this.currentHoverColor;

    this.extendedExpenseTypeRef.isRequired = this.extendedExpenseTypeRef.isEnabled;

    this.handleOfflineLogics();
  }

  getExpenseTypeInstructionKey(
    modelCode: number,
    personalExpense: PersonalExpenseIdentity,
    availableExpense: AvailableExpenseIdentity
  ) {
    let personalExpenseId = 0;
    let availableExpenseId = 0;

    if (personalExpense?.personalExpenseId > 0) {
      personalExpenseId = personalExpense?.personalExpenseId
    }

    if (availableExpense?.availableExpenseId > 0) {
      availableExpenseId = availableExpense?.availableExpenseId
    }
    return `RememberExpenseTypeMessage_M${modelCode}_P${personalExpenseId}_A${availableExpenseId}`;
  }

  checkFileValidation() {
    this.allFilesUploaded = this.files.every(file => (file.progress == null || file.progress == 100) && file.fileErrors.length == 0)
  }

  handleOfflineLogics() {
    this.onlineService.isOnline$.subscribe(() => {
      const isOnline = this.onlineService.isOnline;
      this.commissionRef.zoomSearchIsVisible = isOnline;
      this.customerRef.zoomSearchIsVisible = isOnline;
      this.leadRef.zoomSearchIsVisible = isOnline;

      this.commissionRef.zoomAddIsVisible = isOnline;
      this.customerRef.zoomAddIsVisible = isOnline;
      this.leadRef.zoomAddIsVisible = isOnline;

      this.commissionRef.zoomViewIsVisible = isOnline;
      this.customerRef.zoomViewIsVisible = isOnline;
      this.leadRef.zoomViewIsVisible = isOnline;
    })
  }

  openFileFullScreen(
    currentImageModalSrc: SafeResourceUrl,
    currentImageModalBlob: Blob,
    currentImageModalName: string,
    item: ExpenseFileCollectionItemViewModel
  ) {
    this.currentImageModalSrc = currentImageModalSrc;
    this.currentImageModalBlob = currentImageModalBlob;
    this.currentImageModalName = currentImageModalName;
    this.currentItemInFullScreen = item;
  }

  async validateStep(): Promise<void> {
    this.validate();
    this.checkFileValidation();
    //#5053 -> se stiamo aggiungendo una spesa che non è di tipo rimb. km allora il prezzo totale
    //deve essere > 0
    const parent = this.parent as ReceiptParamsViewModel;
    if (parent?.expenseSelection.expenseClassification.value != ExpenseClassification.MileageRefound) {
      if (this.expenseTotalAmount.value == undefined || this.expenseTotalAmount.value <= 0) {
        const errorObj = ErrorManager.getCustomError(
          this.expenseTotalAmount.metadataDescription,
          'expenseTotalAmount',
          MessageResourceManager.Current.getMessage("ExpenseTotalAmountCannotBeZero"),
          "MIN_VALUE_ERROR",
        )
        this.expenseTotalAmount.addError(SourceMessage.ViewModel, errorObj)
      }
    }
    const validExpenseType = this.extendedExpenseTypeRef?.id?.value != null && this.extendedExpenseTypeRef?.id?.value > 0;

    this.isValid.next(!this.hasErrors && validExpenseType && this.allFilesUploaded)

  };

  override async postInit(): Promise<void> {
    await super.postInit();

    if (this.extendedExpenseTypeRef) {
      this.extendedExpenseTypeRef.externalDomainModelChanged.subscribe(async () => {
        if (this.extendedExpenseTypeRef?.description?.value?.length > 0) {
          this.description.value = this.extendedExpenseTypeRef?.description?.value;

          const personalExpenseInstructions = (this.extendedExpenseTypeRef.getDomainModel().personalExpenseInstructions?.instructions ?? '').trim();
          const availableExpenseInstructions = (this.extendedExpenseTypeRef.getDomainModel().availableExpenseInstructions?.instructions ?? '').trim();

          const currentInstructions = personalExpenseInstructions.length > 0 ? personalExpenseInstructions : availableExpenseInstructions;

          if (currentInstructions.length > 0) {

            const skipMessage = await LocalstorageHelper.getStorageItem(this.getExpenseTypeInstructionKey(
              this.fullExpenseModel.modelCode,
              this.extendedExpenseTypeRef.getDomainModel().personalExpenseIdentity,
              this.extendedExpenseTypeRef.getDomainModel().availableExpenseIdentity,
            ), null, true, true, true)

            if (skipMessage !== true) {
              const expenseTypeInstructionViewModel = new ExpenseTypeInstructionViewModel(
                currentInstructions,
                this.currentColor,
                this.currentActiveColor,
                this.currentHoverColor
              );
              const panelClass = ['expense-type-instruction-modal'];

              if (window !== window.top || window.location.search.includes('iframe=true')) {
                panelClass.push('iframe');
              }

              const result = await this.modalService.showCustomModalWithResultAsync<boolean>(
                expenseTypeInstructionViewModel,
                false,
                false,
                false,
                {
                  disableClose: true,
                  panelClass
                }
              );

              if (!result.cancel && result.result === true) {
                // devo valorizzare un flag nello storage che ricordi che la prossima volta non deve essere visualizzata
                LocalstorageHelper.setStorageItem(this.getExpenseTypeInstructionKey(
                  this.fullExpenseModel.modelCode,
                  this.extendedExpenseTypeRef.getDomainModel().personalExpenseIdentity,
                  this.extendedExpenseTypeRef.getDomainModel().availableExpenseIdentity,
                ), true, null, true, true, true)
              }
            }
          }
        }
      })
    }

    //proposta automatica di cliente in base al cambio commessa.
    this.commissionRef.externalDomainModelChanged.subscribe(async _ => {
      //se la commessa ha valore(il cambio potrebbe essere un annullamento)
      //lo faccio solo se il cliente è visibile -> se non è visibile vuol dire che non era
      //stato settato sulla nota spese e quindi non può essere settato dopo
      if (this.customerRef.isVisible && this.commissionId.value != undefined && this.commissionRef.customerId.value != undefined &&
        this.commissionRef.customerId.value != this.customerRef.id.value) {
        //setto il codevalue del cliente = a quello eventuale della commessa.
        await this.customerRef.setCodeValue(this.commissionRef.customerId.value)
      }
    })

    //proposta automatica di lead in base al cambio cliente.
    this.customerRef.externalDomainModelChanged.subscribe(async _ => {
      //se la commessa ha valore(il cambio potrebbe essere un annullamento)
      //lo faccio solo se il lead è visibile -> se non è visibile vuol dire che non era
      //stato settato sulla nota spese e quindi non può essere settato dopo
      if (this.leadRef.isVisible && this.customerId.value != undefined && this.customerRef.leadId.value != undefined &&
        this.customerRef.leadId.value != this.leadRef.id.value) {
        //setto il codevalue del cliente = a quello eventuale della commessa.
        await this.leadRef.setCodeValue(this.customerRef.leadId.value)
      }
    })

    //proposta automatica di lead in base al cambio cliente.
    this.leadRef.externalDomainModelChanged.subscribe(async _ => {
      //se il lead ha valore(il cambio potrebbe essere un annullamento)
      if (this.customerRef.isVisible && this.leadId.value != undefined) {
        const orchestrator = this.externalRetriever as ReceiptLongOpOrchestratorViewModel
        const response = await firstValueFrom(orchestrator.apiClient.getCustomerByLeadIdentity(this.leadRef?.domainModel?.currentIdentity))
        if (response.operationSuccedeed && response.result != undefined) {
          //setto il codevalue del cliente = a quello eventuale della commessa.
          await this.customerRef.setCodeValue(response.result.id)
        }
      }
    })
  }

  newLabels(): BehaviorSubject<any[]> {
    return (this.parent as ReceiptParamsViewModel).newLabels$;
  }
}
