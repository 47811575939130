import { Pipe, PipeTransform } from "@angular/core";

// Pipe to sort array of pvm by a property of the pvm
@Pipe({
  name: "sortPropertyViewModelByProperty",
  standalone: true,
  pure: false
})
export class ArraySortPropertyViewModelByPropertyPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return null;
    }
    array.sort((a: any, b: any) => {
      if (a[field].value < b[field].value) {
        return -1;
      } else if (a[field].value > b[field].value) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
