import { ExpenseFile } from '../domain-models/expense-file';
import { GeneratedExpenseFileCollectionViewModel } from '../generated/view-models/generated-expense-file.collection-view-model';
import { ExpenseFileCollectionItemViewModel } from './expense-file.collection-item-view-model';

export class ExpenseFileCollectionViewModel extends GeneratedExpenseFileCollectionViewModel {

  async removeFile(itemIndex: number): Promise<void> {
    this.remove(itemIndex);
  }

  async moveUp(itemIndex: number): Promise<void> {
    this.moveItemUp(itemIndex);
  }

  async moveDown(itemIndex: number): Promise<void> {
    this.moveItemDown(itemIndex);
  }

  // Il nuovo elemento viene aggiunto in cima alla lista
  async addNewItem(): Promise<ExpenseFileCollectionItemViewModel> {
    const entity: ExpenseFile = this.collection.createTypedDomainModel();

    const newItem = await this.addFromEntity(entity, true);
    newItem.rowNumber.value = 0;

    // scalo di 1 tutti gli altri elementi
    this.forEach((item) => {
      if (item !== newItem) {
        item.rowNumber.value++;
      }
    });

    return newItem;
  }
}
