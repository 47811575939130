import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ExpenseSelectionViewModel } from 'src/app/receipt-long-op/view-models/expense-selection.view-model';
import { ExpenseSelection } from 'src/app/receipt-long-op/domain-models/expense-selection';
import { ExpenseClassification } from 'src/app/receipt-long-op/generated/domain-models/enums/generated-expense-classification';
import { firstValueFrom, Observable } from 'rxjs';
import { EnterpriseDataDto, MessageResourceManager, NTSTranslatePipe, ToastMessage, ToastMessageType } from '@nts/std';
import { ExpenseModel } from 'src/app/expense-model/domain-models/expense-model';
import { UserAvailableExpensesIdentity } from 'src/app/user-available-expenses/domain-models/user-available-expenses.identity';
import { PersonalExpense } from 'src/app/user-available-expenses/domain-models/personal-expense';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { ExpenseClassificationBoxComponent } from './expense-classification-box/expense-classification-box.component';
import { BaseStepComponent } from '../base-step.component';

@UntilDestroy()
@Component({
  selector: 'nts-expense-selection-step',
  templateUrl: './expense-selection-step.component.html',
  styleUrls: ['./expense-selection-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NTSTranslatePipe,
    NgIf,
    SvgIconComponent,
    ExpenseClassificationBoxComponent,
    NgFor,
    AsyncPipe
  ]
})
export class ExpenseSelectionStepComponent extends BaseStepComponent<ExpenseSelectionViewModel> {

  expenseClassificationList = [];
  expenseTypeEnum = ExpenseSelection;

  untilDestroyed(): <U>(source: Observable<U>) => Observable<U> {
    return untilDestroyed(this);
  }

  async onViewModelLoaded() {

    this.receiptStepsService.stepLoading.next(true);

    const rootViewModel = this.receiptStepsService.orchestratorViewModel.rootViewModel;

    // recupero la lista di expenseType associati al modello scelto
    const selectedModelCode = rootViewModel.params.expenseModelData.expenseModelRef.modelCode.value

    const tenantId: number = await this.authService.getTenantId();
    const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);

    // se non ho popolato gli availableModels li popolo
    if (rootViewModel.params.expenseModelData.availableModels == null) {
      const availableModelsResponse = await firstValueFrom(this.receiptLongOpApiClient.getAvailableModels(undefined, enterpriseData.companyId))
      if (availableModelsResponse.operationSuccedeed === true) {
        rootViewModel.params.expenseModelData.availableModels = availableModelsResponse.result || [];
      } else {
        rootViewModel.params.expenseModelData.availableModels = [];
      }
    }

    if (rootViewModel.params.expenseModelData.availableModels.length > 0) {
      const fullExpenseModel: ExpenseModel = rootViewModel.params.expenseModelData.availableModels.find((e: ExpenseModel) => e.modelCode === selectedModelCode)
      const availableExpenses = fullExpenseModel?.availableExpenses?.collectionItems;

      const expensesIdentity = new UserAvailableExpensesIdentity();
      expensesIdentity.userId = await this.authService.getCurrentUserId();
      expensesIdentity.companyId = enterpriseData.companyId;

      const personalExpenseResponse = await firstValueFrom(this.receiptLongOpApiClient.getPersonalExpenses(expensesIdentity));
      let personalExpenses: PersonalExpense[] = [];
      if (personalExpenseResponse?.operationSuccedeed == true) {
        personalExpenses = personalExpenseResponse.result ?? [];
      }

      if ((availableExpenses && availableExpenses.length > 0) || (personalExpenses && personalExpenses.length > 0)) {
        this.expenseClassificationList = Object.values(ExpenseClassification)
          .filter((v) => !isNaN(Number(v)))
          // devo filtrare l'expenseClassification in base al modello scelto
          // Il modello scelto ha associato una lista di expense type che a sua volta hanno associato un expenseClassification
          .filter((element: number) =>
            availableExpenses?.find((ae) => ae.expenseTypeRef?.refoundClass === element) != null ||

            // Verifico se ho delle spese personali che fanno parte dell'expense classification corrente
            personalExpenses.find((pe) => pe.expenseTypeRef?.refoundClass === element) != null
          );
      }
    }

    const viewModel = this.receiptStepsService.getCurrentState() as ExpenseSelectionViewModel;
    const queryParams = await firstValueFrom(this.routeStateService.queryParams);
    const expenseModelId = parseInt(queryParams['expenseModelId'], 10);
    const expenseAnnotationId = parseInt(queryParams['expenseAnnotationId'], 10);
    const remoteExpense = queryParams['remoteExpense'] === 'true';

    await viewModel.initPresentationLogic(
      expenseAnnotationId,
      remoteExpense,
      this.expenseClassificationList,
      expenseModelId,
      rootViewModel.params.expenseState,
      !(await this.isReadOnly(rootViewModel))
    )

    this.receiptStepsService.orchestratorViewModel.rootViewModelModified.pipe(untilDestroyed(this)).subscribe(() => {
      viewModel.validateStep();
    });

    viewModel.validateStep();

    const params = await firstValueFrom(this.routeStateService.params);
    const currentAction = params.get('action');

    if (currentAction === 'add' && viewModel.isValid.value === true) {
      if (!this.receiptStepsService.orchestratorViewModel.errorDuringAdd) {
        this.receiptStepsService.nextStep();
      } else {
        this.receiptStepsService.stepLoading.next(false);
      }
    } else if (currentAction === 'add' && viewModel.isValid.value === false) {
      const toastMessage: ToastMessage = {
        message: MessageResourceManager.Current.getMessage('MissinigDataInStep'),
        type: ToastMessageType.warn,
        title: MessageResourceManager.Current.getMessage('std_Warning')
      };
      this.receiptStepsService.orchestratorViewModel.toastMessageService.showToast(toastMessage);
      this.receiptStepsService.orchestratorViewModel.errorDuringAdd = true;
      this.receiptStepsService.stepLoading.next(false);
    } else {
      this.receiptStepsService.stepLoading.next(false);
    }
    this.viewModel = viewModel;

    const title = MessageResourceManager.Current.getMessage("ExpenseSelectionStep_Title");
    this.telemetryService.trackPageView({
      name: title,
      properties: {
        selectedExpenseClassification: ExpenseClassification[this.viewModel.expenseClassification.value]
      }
    });

    this.cd.detectChanges();
  }

  selectExpenseClassification(expenseClassification: ExpenseClassification) {
    this.viewModel.expenseClassification.setValue(expenseClassification);
    this.telemetryService.trackEvent({
      name: 'WEA_ReceiptLongOp_ExpenseSelectionStep_SelectExpenseClassification',
      properties: {
        expenseClassification: ExpenseClassification[expenseClassification]
      }
    });
    this.receiptStepsService.nextStep();
  }
}
