
import {
  IdentityTypeDecorator, OCCModel, ModelTypeNameDecorator, Expose, AllowedChars, NumberDecorator,
  StringDecorator, EnumDecorator
} from '@nts/std';
import { UserOfTenant } from './user-of-tenant';
import { UserOfTenantIdentity } from './user-of-tenant.identity';


@IdentityTypeDecorator(UserOfTenantIdentity)
@ModelTypeNameDecorator('UserOfTenant')
export class UserOfTenantExtended extends UserOfTenant {

  // tslint:disable-next-line: variable-name
  private _email: string;

  @Expose()
  get email(): string {
    return this.getValue<string>(() => this._email, 'email');
  }
  set email(value: string) {
    this.setValue<string>(() => { this._email = value; }, value, 'email');
  }
}
