import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { PaymentDataViewModel } from 'src/app/receipt-long-op/view-models/payment-data.view-model';
import { firstValueFrom, Observable } from 'rxjs';
import { DecodeTextBoxComponent, EnterpriseDataDto, ExpandableComponent, ExternalListComponent, LabelledTextBoxComponent, MessageResourceManager, NTSTranslatePipe, SpacerBoxComponent, ToastMessage, ToastMessageType } from '@nts/std';
import { ExpenseModel } from 'src/app/expense-model/domain-models/expense-model';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf } from '@angular/common';
import { BaseStepComponent } from '../base-step.component';

@UntilDestroy()
@Component({
  selector: 'nts-payment-data-step',
  templateUrl: './payment-data-step.component.html',
  styleUrls: ['./payment-data-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SvgIconComponent,
    NTSTranslatePipe,
    ExpandableComponent,
    ExternalListComponent,
    LabelledTextBoxComponent,
    SpacerBoxComponent,
    DecodeTextBoxComponent,
    NgIf
  ]
})
export class PaymentDataStepComponent extends BaseStepComponent<PaymentDataViewModel> {

  untilDestroyed(): <U>(source: Observable<U>) => Observable<U> {
    return untilDestroyed(this);
  }

  async onViewModelLoaded() {
    this.receiptStepsService.stepLoading.next(true);
    const viewModel = this.receiptStepsService.getCurrentState() as PaymentDataViewModel;
    const rootViewModel = this.receiptStepsService.orchestratorViewModel.rootViewModel;
    const selectedModelCode = rootViewModel.params.expenseModelData.expenseModelRef?.modelCode?.value;

    const tenantId: number = await this.authService.getTenantId();
    const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);

    // se non ho popolato gli availableModels li popolo
    if (rootViewModel.params.expenseModelData.availableModels == null) {
      const availableModelsResponse = await firstValueFrom(this.receiptLongOpApiClient.getAvailableModels(undefined, enterpriseData.companyId))
      if (availableModelsResponse.operationSuccedeed === true) {
        rootViewModel.params.expenseModelData.availableModels = availableModelsResponse.result || [];
      } else {
        rootViewModel.params.expenseModelData.availableModels = [];
      }
    }

    let fullExpenseModel: ExpenseModel = null;

    if (rootViewModel.params.expenseModelData.availableModels.length > 0) {
      fullExpenseModel = rootViewModel.params.expenseModelData.availableModels.find((e: ExpenseModel) => e.modelCode === selectedModelCode)
    }

    const extendedAvailablePayments = await this.receiptStepsService.orchestratorViewModel.getExtendedAvailablePayments(selectedModelCode);

    const queryParams = await firstValueFrom(this.routeStateService.queryParams);
    const expenseAnnotationId = parseInt(queryParams['expenseAnnotationId'], 10);
    const remoteExpense = queryParams['remoteExpense'] === 'true';

    await viewModel.initPresentationLogic(
      rootViewModel.params.expenseSelection.expenseClassification.value,
      fullExpenseModel,
      extendedAvailablePayments,
      expenseAnnotationId,
      remoteExpense,
      rootViewModel.params.expenseState,
      this.onlineService,
      !(await this.isReadOnly(rootViewModel))
    );

    this.receiptStepsService.orchestratorViewModel.rootViewModelModified.pipe(untilDestroyed(this)).subscribe(() => {
      viewModel.validateStep();
    });

    viewModel.validateStep();
    this.inIframe = this.activatedRoute.snapshot.queryParams['iframe'] === 'true' || window.self !== window.top;

    const params = await firstValueFrom(this.routeStateService.params);
    const currentAction = params.get('action');

    if (currentAction === 'add' && viewModel.isValid.value === true) {
      if (!this.receiptStepsService.orchestratorViewModel.errorDuringAdd) {
        this.receiptStepsService.nextStep();
      } else {
        this.receiptStepsService.stepLoading.next(false);
      }
    } else if (currentAction === 'add' && viewModel.isValid.value === false) {
      const toastMessage: ToastMessage = {
        message: MessageResourceManager.Current.getMessage('MissinigDataInStep'),
        type: ToastMessageType.warn,
        title: MessageResourceManager.Current.getMessage('std_Warning')
      };
      this.receiptStepsService.orchestratorViewModel.toastMessageService.showToast(toastMessage);
      this.receiptStepsService.orchestratorViewModel.errorDuringAdd = true;
      this.receiptStepsService.stepLoading.next(false);
    } else {
      this.receiptStepsService.stepLoading.next(false);
    }

    this.viewModel = viewModel;

    const customTitle = viewModel.newLabels().value[viewModel.currentExpenseClassificationType];
    const title = customTitle ?? MessageResourceManager.Current.getMessage('ExpenseClassification_' + viewModel.expenseClassificationEnum[viewModel.currentExpenseClassificationType]);
    this.telemetryService.trackPageView({ name: title });

    this.cd.detectChanges();
  };
}

