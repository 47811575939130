import { CustomPropertyViewModelDecorator, InternalViewModelTypeDecorator, NNumericPropertyViewModel, PropertyViewModelFactory, StringDecorator, StringMetaData } from "@nts/std";
import { ExternalDomainModelTypeDecorator, ExternalDomainModelTypeNameDecorator, ExternalViewModel, IdentityTypeDecorator, StringPropertyViewModel } from "@nts/std";
import { ExtendedAvailableExpenseIdentity } from "../domain-models/extended-available-expense.identity";
import { ExtendedAvailableExpense } from "../domain-models/extended-available-expense";
import { ReceiptLongOpOrchestratorViewModel } from "./receipt-long-op.orchestrator-view-model";
import { filter, merge, switchMap, take, takeUntil, tap } from "rxjs";
import { CompilationInstructionsViewModel } from "src/app/expense-model/view-models/compilation-instructions.view-model";
import { PersonalCompilationInstructionsViewModel } from "src/app/user-available-expenses/view-models/personal-compilation-instructions.view-model";

@IdentityTypeDecorator(ExtendedAvailableExpenseIdentity)
@ExternalDomainModelTypeDecorator(ExtendedAvailableExpense)
@ExternalDomainModelTypeNameDecorator('ExtendedAvailableExpense')
export class ExtendedAvailableExpenseExtViewModel extends ExternalViewModel<ExtendedAvailableExpense, ExtendedAvailableExpenseIdentity> {

    // tslint:disable-next-line: variable-name
    private _id: NNumericPropertyViewModel;

    get id(): NNumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
    }

    // tslint:disable-next-line: variable-name
    private _description: StringPropertyViewModel;

    get description(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._description = value; }, this._description, 'description');
    }

    @InternalViewModelTypeDecorator(CompilationInstructionsViewModel)
    public availableExpenseInstructions: CompilationInstructionsViewModel;

    @InternalViewModelTypeDecorator(PersonalCompilationInstructionsViewModel)
    public personalExpenseInstructions: PersonalCompilationInstructionsViewModel;

    // tslint:disable-next-line: member-ordering variable-name
    private _classification: StringPropertyViewModel;

    @CustomPropertyViewModelDecorator()
    @StringDecorator({ displayNameKey: 'ExtendedAvailableExpenser_Classification_DisplayName' })
    public get classification(): StringPropertyViewModel {
        if (this._classification == null) {
            const init = PropertyViewModelFactory.createPVMInitializationInfo<StringMetaData>(
                this, 'classification', null, this, this.modifiedSubscriber, this.eventDispatcher, null, false, false
            );
            this._classification = new StringPropertyViewModel(init);
        }
        return this._classification;
    }

    override async postInit() {

        await super.postInit();

        this.zoomAddIsEnabled = false;
        this.zoomAddIsVisible = false;
        this.zoomSearchIsEnabled = false;
        this.zoomSearchIsVisible = false;
        this.zoomViewIsEnabled = false;
        this.zoomViewIsVisible = false;


        const ovm = this.externalRetriever as ReceiptLongOpOrchestratorViewModel;
        ovm.rootViewModelChanged.pipe(
            takeUntil(this.destroySubscribers$),
            filter(() => ovm.rootViewModel != null),
            tap(() => this.classification.value = this.getClassificationDescription(ovm)),
            switchMap(() =>
                merge(
                    ovm.rootViewModel.params.newLabels$,
                    ovm.rootViewModel.params.expenseSelection.expenseClassification.propertyChanged
                )
            )
        ).subscribe(() => {
            this.classification.value = this.getClassificationDescription(ovm);
        })
    }

    getClassificationDescription(ovm: ReceiptLongOpOrchestratorViewModel): string {
        return (ovm.rootViewModel.params.newLabels$?.value && ovm.rootViewModel.params.newLabels$.value[ovm.rootViewModel.params.expenseSelection.expenseClassification.value]) ?
            ovm.rootViewModel.params.newLabels$.value[ovm.rootViewModel.params.expenseSelection.expenseClassification.value] :
            ovm.rootViewModel.params.expenseSelection.expenseClassification.formattedValue;
    }

    // Utilizza tutte le propertites passate e non solo quelle dei metadati
    // Nasconde eventualmente il codice se richiesto
    // E' necessario passare questo override visto che la chiamata ritorna un campo aggiuntivo classification che non verrebbe considerato
    protected override getDescriptionFromProperties(
        properties: Array<string | number>,
        showCodeInDescription = true,
        outputProperties: string[],
        basePlainPascalCaseFixedIdentity: { [key: string]: string | number } | null,
        decodeProperties: string[] = null,
        joinOperator = " - "
    ): string {

        // Se devo nascondere il codice
        if (!showCodeInDescription) {

            // Indici da rimuovere
            const indexToRemove: number[] = [];

            // Ciclo le property per individuare la posizione dei campi dell'identity
            for (const [i, outputProperty] of outputProperties.entries()) {
                if (this.externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames.map((i) => i.toLowerCase()).indexOf(outputProperty.toLowerCase()) > -1) {

                    // aggiungo all'inizio l'indice trovato che dovrò rimuovere
                    indexToRemove.unshift(i);
                }
            }

            // Rimuovo i campi identity dalle properties
            for (const i of indexToRemove) {
                properties.splice(i, 1);
            }

        }

        return properties.join(joinOperator);
    }
}
