import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';
import { UserAvailableExpensesIdentity } from '../../domain-models/user-available-expenses.identity';

@IdentityTypeNameDecorator('PersonalExpenseIdentity')
export class GeneratedPersonalExpenseIdentity extends UserAvailableExpensesIdentity {

	private _personalExpenseId: number;

	@Expose()
	public get personalExpenseId(): number {
		return this.getValue<number>(() => this._personalExpenseId, 'personalExpenseId');
	}
	public set personalExpenseId(value: number) {
		this.setValue<number>(() => { this._personalExpenseId = value; }, value, 'personalExpenseId');
	}
}
