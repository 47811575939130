import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseError, CommandTypes, CreateResponse, EnterpriseDataDto, FindValuesOptions, MessageCodes, MessageResourceManager, ServiceResponse, ToastMessageType, UIStarter } from "@nts/std";
import { AutoCompleteExternalOptions } from "@nts/std";
import { BaseIdentity } from "@nts/std";
import { ClassType } from "@nts/std";
import { CoreModel } from "@nts/std";
import { FindValuesResponse } from "@nts/std";
import { GenericServiceResponse, IdentityTypeDecorator, LongOpOrchestratorViewModel, RootViewModelTypeDecorator } from "@nts/std";
import { LogService, OnlineService } from "@nts/std/src/lib/utility";
import { firstValueFrom, from, map, Observable, of, take } from "rxjs";
import { ExpenseAnnotationIdentity } from "src/app/expense-annotation/domain-models/expense-annotation.identity";
import { ExpenseIdentity } from "src/app/expense-annotation/domain-models/expense.identity";
import { AvailableExpense } from "src/app/expense-model/domain-models/available-expense";
import { AvailablePayment } from "src/app/expense-model/domain-models/available-payment";
import { AvailablePaymentIdentity } from "src/app/expense-model/domain-models/available-payment.identity";
import { ExpenseModel } from "src/app/expense-model/domain-models/expense-model";
import { ExpenseType } from "src/app/expense-type/domain-models/expense-type";
import { Commission } from "src/app/external-remote/commission/commission";
import { Customer } from "src/app/external-remote/customer/customer";
import { Lead } from "src/app/external-remote/lead/lead";
import { PersonalPayment } from "src/app/user-available-payments/domain-models/personal-payment";
import { PersonalPaymentIdentity } from "src/app/user-available-payments/domain-models/personal-payment.identity";
import { UserAvailablePaymentsIdentity } from "src/app/user-available-payments/domain-models/user-available-payments.identity";
import { PersonalPayments } from "src/app/user-available-payments/generated/domain-models/enums/generated-personal-payments";
import { ReceiptLongOpApiClient } from "../api-clients/receipt-long-op.api-client";
import { UploadFileOutputDto } from "../domain-models/dto/upload-file-output.dto";
import { ExtendedAvailablePayment } from "../domain-models/extended-available-payment";
import { ReceiptLongOp } from "../domain-models/receipt-long-op";
import { ReceiptLongOpIdentity } from "../domain-models/receipt-long-op-identity";
import { ReceiptParams } from "../domain-models/receipt-params";
import { ReceiptResult } from "../domain-models/receipt-result";
import { ExpenseFileCollectionItemViewModel } from "./expense-file.collection-item-view-model";
import { ReceiptLongOpViewModel } from "./receipt-long-op.view-model";
import { ReceiptParamsViewModel } from "./receipt-params.view-model";
import { ReceiptResultViewModel } from "./receipt-result.view-model";
import { PersonalExpense } from "src/app/user-available-expenses/domain-models/personal-expense";
import { UserAvailableExpensesIdentity } from "src/app/user-available-expenses/domain-models/user-available-expenses.identity";
import { ExtendedAvailableExpense } from "../domain-models/extended-available-expense";
import { AvailableExpenseIdentity } from "src/app/expense-model/domain-models/available-expense.identity";
import { PersonalExpenseIdentity } from "src/app/user-available-expenses/domain-models/personal-expense.identity";
import { ExpenseClassification } from "../generated/domain-models/enums/generated-expense-classification";
import { ModalService } from "@nts/std";
import { EnvironmentConfiguration } from "@nts/std/src/lib/environments";
import { AuthService } from "@nts/std";
import { ToastMessageService } from "@nts/std";
import { Label } from "src/app/classification-labels/domain-models/label";
import { ExpenseFileCollectionViewModel } from "./expense-file.collection-view-model";
import { DomSanitizer } from "@angular/platform-browser";
import { UserOfTenantExtended } from "src/app/external-remote/user-of-tenant/user-of-tenant-extended";
import { ExpenseModelListResponse } from "../responses/expense-model-list-response";
import { UserOfTenantIdentity } from "src/app/external-remote/user-of-tenant/user-of-tenant.identity";

@Injectable()
@RootViewModelTypeDecorator(ReceiptLongOpViewModel)
@IdentityTypeDecorator(ReceiptLongOpIdentity)

export class ReceiptLongOpOrchestratorViewModel extends
  LongOpOrchestratorViewModel<
    ReceiptLongOpViewModel,
    ReceiptLongOpApiClient,
    ReceiptLongOp,
    ReceiptLongOpIdentity,
    ReceiptParamsViewModel,
    ReceiptResultViewModel,
    ReceiptParams,
    ReceiptResult
  > {

  errorDuringAdd = false;
  currentExpenseId = 0;
  currentExpenseAnnotationId = 0;
  syncedCommissions: Commission[] = [];
  syncedCustomers: Customer[] = [];
  syncedLeads: Lead[] = [];

  constructor(
    apiClient: ReceiptLongOpApiClient,
    modalService: ModalService,
    env: EnvironmentConfiguration,
    authService: AuthService,
    toastMessageService: ToastMessageService,
    onlineService: OnlineService,
    public domSanitizer: DomSanitizer
  ) {
    super(apiClient, modalService, env, authService, toastMessageService, onlineService);
  }

  async createLocalExpense(force?: boolean): Promise<ServiceResponse> {
    let response = new ServiceResponse();
    response.operationSuccedeed = false;
    const result = force || await this.confirmLosingUnsavedDataAsync();
    if (result) {
      try {
        if (this.actionInProgress == false) {
          this.eventDispatcher.onActionInProgress.next(true);
        }
        response = await this.createLocalExpenseWithClearAsync(true);
        LogService.debug(MessageResourceManager.Current.getMessage('CreateLongOpCompleted'));
      } catch (error) {
        LogService.warn(MessageResourceManager.Current.getMessage('CreateLongOpFailed'), error);
      } finally {
        this.eventDispatcher.onActionInProgress.next(false);
      }
    }
    return response;
  }

  protected async createLocalExpenseWithClearAsync(clearPreviousMessages): Promise<CreateResponse<ReceiptLongOp, ReceiptLongOpIdentity>> {
    const createResponse = await this.createLocalExpenseImplementationAsync(clearPreviousMessages);
    if (createResponse.operationSuccedeed) {
      // aggiorno la rotta corrente rimuovento l'eventuale identity
      this.updateJsonRouteParam();
      const params = new URLSearchParams(this.queryParams)
      UIStarter.updateCurrentRoute(this.metadata.rootFullName, undefined, undefined, '?' + params.toString());
      // Disabilito la validazione in fase di creazione
      // this.viewModelValidate(false);
    }
    return createResponse;
  }

  protected async createLocalExpenseImplementationAsync(clearPreviousMessages: boolean): Promise<CreateResponse<ReceiptLongOp, ReceiptLongOpIdentity>> {

    let ret = new CreateResponse<ReceiptLongOp, ReceiptLongOpIdentity>(this.apiClient.rootModelType);
    ret.operationSuccedeed = false;
    if (await firstValueFrom(this.canCreate(null))) {

      // TODO Tommy: crea rootViewModel virtual
      // Crea un rootViewModel vuoto così da renderizzare l'interfaccia mentre viene fatta la chiamata
      // const entityClass = NTSReflection.getClassMetadata('domainModelEntityType', this.apiClient);
      // const newEntity = this.createMockEntity<TEntity>(entityClass, this.metadata);
      // await this.tryRebuildViewModelAsyncWithoutResponse(newEntity, true, true);
      await this.preCreate();
      const response = await this.createRootEntityAsync();
      ret = response;

      // Force current user for local receipt
      response.result.params.userId = await this.authService.getCurrentUserId();
      const accessToken = await this.authService.getAccessToken()
      const parsedAccessToken = this.authService.decodeToken(accessToken);
      const userRef = new UserOfTenantExtended();
      userRef.id = response.result.params.userId;
      userRef.lastName = parsedAccessToken?.family_name;
      userRef.name = parsedAccessToken?.given_name;
      response.result.params.userRef = userRef;

      if (response.operationSuccedeed && response.result != null) {
        await this.tryRebuildViewModelAsync(response, response.result, true, clearPreviousMessages);
        this.currentState.create();
        await this.postCreate();
      } else {
        LogService.debug('createImplementationAsync failed', response);
        // this.toastMessageService.showToastsFromResponse(response);
      }
    } else {
      const error = new BaseError();
      error.code = 'NotAllowed';
      error.description = MessageResourceManager.Current.getMessageWithStrings(MessageCodes.CommandNotAllowed,
        MessageResourceManager.Current.getMessage('std_CMD_' + CommandTypes[CommandTypes.Create]));
      ret.errors = [error];
      this.notAllowedAction(CommandTypes.Create);
    }
    return ret;
  }

  async createExpenseByExpenseAnnotation(identity: ExpenseAnnotationIdentity): Promise<ServiceResponse> {
    let response = new ServiceResponse();
    response.operationSuccedeed = false;
    try {
      if (this.actionInProgress == false) {
        this.eventDispatcher.onActionInProgress.next(true);
      }
      response = await this.createExpenseAsync(identity);
      LogService.debug('createExpenseByExpenseAnnotation completed');
    } catch (error) {
      LogService.warn('createExpenseByExpenseAnnotation failed', error);
    } finally {
      this.eventDispatcher.onActionInProgress.next(false);
    }
    return response;
  }

  protected override async executeDefaultTrackPageView(): Promise<void> {
    // Disable default TrackPageView
  }

  protected async createExpenseAsync(identity: ExpenseAnnotationIdentity): Promise<CreateResponse<ReceiptLongOp, ReceiptLongOpIdentity>> {
    const createResponse = await this.createExpenseImplementationAsync(identity);
    return createResponse;
  }

  protected async createExpenseImplementationAsync(identity: ExpenseAnnotationIdentity): Promise<CreateResponse<ReceiptLongOp, ReceiptLongOpIdentity>> {

    let ret = new CreateResponse<ReceiptLongOp, ReceiptLongOpIdentity>(this.apiClient.rootModelType);
    ret.operationSuccedeed = false;
    if (await firstValueFrom(this.canCreate(null))) {

      await this.preCreate();
      const response = await this.createExpenseRootEntityAsync(identity);
      ret = response;
      if (response.operationSuccedeed && response.result != null) {

        await this.fixExtendedAvailablePayment(response.result.params);
        await this.tryRebuildViewModelAsync(response, response.result, true, true);
        this.currentState.create();
        await this.postCreate();
      } else {
        LogService.debug('createExpenseImplementationAsync failed', response);
      }
    } else {
      const error = new BaseError();
      error.code = 'NotAllowed';
      error.description = MessageResourceManager.Current.getMessageWithStrings(MessageCodes.CommandNotAllowed,
        MessageResourceManager.Current.getMessage('std_CMD_' + CommandTypes[CommandTypes.Create]));
      ret.errors = [error];
      this.notAllowedAction(CommandTypes.Create);
    }
    return ret;
  }

  async createExpenseRootEntityAsync(identity: ExpenseAnnotationIdentity): Promise<CreateResponse<ReceiptLongOp, ReceiptLongOpIdentity>> {
    return await firstValueFrom(this.apiClient.createExpenseLongOp(identity));
  }

  uploadExpenseFile(item: ExpenseFileCollectionItemViewModel, file: File): Observable<GenericServiceResponse<UploadFileOutputDto>> {
    return this.apiClient.uploadFile(file);
  }

  protected override getByObjectFromApiClient<TObject extends any>(object: TObject): Observable<GenericServiceResponse<ReceiptLongOp>> {
    const response = new GenericServiceResponse<ReceiptLongOp>(ReceiptLongOp);
    response.result = object as ReceiptLongOp;
    return of(response);
  }

  override async loadByObjectImplementationAsync<TObject extends any>(object: TObject): Promise<GenericServiceResponse<ReceiptLongOp>> {

    return firstValueFrom(this.getByObjectFromApiClient(object))
      .then(async (response) => {
        if (response.operationSuccedeed && response.result != null) {
          await this.tryRebuildViewModelAsync(response, response.result, false, true);

        } else {
          this.toastMessageService.showToastsFromResponse(response);
        }
        return response;
      }).catch(err => {
        LogService.debug('loadByObjectImplementationAsync long op failed', err);
        const response = new GenericServiceResponse<ReceiptLongOp>(ReceiptLongOp);
        response.operationSuccedeed = false;
        return response;
      });
  }

  override async getExternal<TExternalDomainModel extends CoreModel<TExternalIdentity>, TExternalIdentity extends BaseIdentity>(
    identity: TExternalIdentity,
    externalDomainModelTypeName: string,
    externalDomainModelType: ClassType<TExternalDomainModel>
  ): Promise<TExternalDomainModel> {
    switch (externalDomainModelTypeName) {

      case 'ExtendedAvailablePayment':
        const selectedModelCodeForExtendedAvailablePayment = this.rootViewModel.params.expenseModelData.expenseModelRef?.modelCode?.value;
        const extendedAvailablePayments: ExtendedAvailablePayment[] = await this.getExtendedAvailablePayments(selectedModelCodeForExtendedAvailablePayment);

        return extendedAvailablePayments.find(
          (eap) => eap.id === identity['id']
        ) as any

      case 'ExpenseModel':
        if (this.rebuildRootViewModelInProgress$.value === true) {
          return null;
        }
        const availableModelsFromExpenseModelData = this.rootViewModel.params.expenseModelData.availableModels as ExpenseModel[];
        return availableModelsFromExpenseModelData.find((e: ExpenseModel) => e.modelCode === identity['modelCode']) as any

      case 'ExtendedAvailableExpense':
        const selectedModelCodeForExtendedAvailableExpense = this.rootViewModel.params.expenseModelData.expenseModelRef?.modelCode?.value;
        const currentExpenseClassificationForExtendedAvailableExpense = this.rootViewModel.params.expenseSelection.expenseClassification.value;
        const extendedAvailableExpenses: ExtendedAvailableExpense[] = await this.getExtendedAvailableExpenses(
          selectedModelCodeForExtendedAvailableExpense,
          currentExpenseClassificationForExtendedAvailableExpense,
          this.rootViewModel?.params?.userRef?.getDomainModel()?.currentIdentity
        );

        return extendedAvailableExpenses.find(
          (eae) => eae.id === identity['id']
        ) as any


      case 'ExpenseType':
        const fullExpenseModelFromExpenseData = this.rootViewModel.params.expenseData.fullExpenseModel as ExpenseModel;
        return fullExpenseModelFromExpenseData.availableExpenses.collectionItems

          // Filtro per expenseSelection
          .filter((availableExpense: AvailableExpense) => this.rootViewModel.params.expenseSelection.expenseClassification.value === availableExpense.expenseTypeRef.refoundClass)

          .map((availableExpense: AvailableExpense) => {
            if (availableExpense.description?.length > 0) {
              availableExpense.expenseTypeRef.description = availableExpense.description;
            }
            return availableExpense.expenseTypeRef
          })

          .find((expenseType: ExpenseType) => expenseType.code === identity['code']) as any

      case 'Commission':
        if (this.onlineService.isOnline === false) {
          return this.syncedCommissions.find((commission: Commission) => commission.id === identity['id']) as any;
        }
        break;

      case 'Customer':
        if (this.onlineService.isOnline === false) {
          return this.syncedCustomers.find((customer: Customer) => customer.id === identity['id']) as any;
        }
        break;

      case 'Lead':
        if (this.onlineService.isOnline === false) {
          return this.syncedLeads.find((lead: Lead) => lead.id === identity['id']) as any;
        }
        break;
    }

    return super.getExternal(
      identity,
      externalDomainModelTypeName,
      externalDomainModelType
    )
  }

  override findValues(
    findOptions: FindValuesOptions,
    entityToLookUp = '',
    entityToLookUpFullName = ''
  ): Observable<FindValuesResponse> {

    const response = new FindValuesResponse();
    switch (entityToLookUpFullName) {

      case 'WebExpenseAnnotation.ReceiptLongOpObjects.Models.ExtendedAvailablePayment':
        const selectedModelCode = this.rootViewModel.params.expenseModelData.expenseModelRef?.modelCode?.value;
        return from(this.getExtendedAvailablePayments(selectedModelCode))
          .pipe(map((eaps: ExtendedAvailablePayment[]) => {
            response.result = eaps.map((p: ExtendedAvailablePayment) => {
              return [p.id, p.description]
            })
            return response;
          }));

      case 'WebExpenseAnnotation.ExpenseTypeObjects.Models.ExpenseType':

        const fullExpenseModelFromExpenseData = this.rootViewModel.params.expenseData.fullExpenseModel as ExpenseModel;
        response.result = fullExpenseModelFromExpenseData.availableExpenses.collectionItems

          // Filtro per expenseSelection
          .filter((e: AvailableExpense) => this.rootViewModel.params.expenseSelection.expenseClassification.value === e.expenseTypeRef.refoundClass)

          .map((e: AvailableExpense) => {
            return [e.expenseTypeRef.code, (e.description?.length > 0 ? e.description : e.expenseTypeRef.description), e.expenseTypeRef.refoundClass]
          })
        return of(response);

      case 'WebExpenseAnnotation.ReceiptLongOpObjects.Models.ExtendedAvailableExpense':
        const currentExpenseClassificationForExtendedAvailableExpense = this.rootViewModel.params.expenseSelection.expenseClassification.value;
        const selectedModelCodeForExtendedAvailableExpense = this.rootViewModel.params.expenseModelData.expenseModelRef?.modelCode?.value;
        const classificationDescription: string = (this.rootViewModel.params.newLabels$.value && this.rootViewModel.params.newLabels$.value[this.rootViewModel.params.expenseSelection.expenseClassification.value]) ?
          this.rootViewModel.params.newLabels$.value[this.rootViewModel.params.expenseSelection.expenseClassification.value] :
          this.rootViewModel.params.expenseSelection.expenseClassification.formattedValue;

        return from(this.getExtendedAvailableExpenses(
          selectedModelCodeForExtendedAvailableExpense,
          currentExpenseClassificationForExtendedAvailableExpense,
          this.rootViewModel?.params?.userRef?.getDomainModel()?.currentIdentity
        ))
          .pipe(map((eaes: ExtendedAvailableExpense[]) => {
            response.result = eaes.map((p: ExtendedAvailableExpense) => {
              return [p.id, p.description, classificationDescription]
            })
            return response;
          }));

      case 'WebExpenseAnnotation.ExpenseModelObjects.Models.ExpenseModel':
        const availableModelsFromExpenseModelData = this.rootViewModel.params.expenseModelData.availableModels as ExpenseModel[];
        response.result = availableModelsFromExpenseModelData
          .map((e: ExpenseModel) => {
            return [e.modelCode, e.description]
          })
        return of(response);

      case 'CommissionMS.CommissionObjects.Models.Commission':
        if (this.onlineService.isOnline === false) {
          response.result = this.syncedCommissions.map((c: Commission) => {
            return [c.id, c.description]
          })
          return of(response);
        }
        break;

      case 'Subject.CustomerObjects.Models.Customer':
        if (this.onlineService.isOnline === false) {
          response.result = this.syncedCustomers.map((c: Customer) => {
            return [c.id, c.companyName]
          })
          return of(response);
        }
        break;

      case 'Subject.LeadObjects.Models.Lead':
        if (this.onlineService.isOnline === false) {
          response.result = this.syncedLeads.map((l: Lead) => {
            return [l.id, l.companyName]
          })
          return of(response);
        }
        break;
    }

    return super.findValues(
      findOptions,
      entityToLookUp,
      entityToLookUpFullName
    );
  }

  override getExternalAutoCompleteValues(options: AutoCompleteExternalOptions): Observable<FindValuesResponse> {
    const response = new FindValuesResponse();
    switch (options.fullRootModelName) {

      case 'WebExpenseAnnotation.ReceiptLongOpObjects.Models.ExtendedAvailablePayment':
        const fullExpenseModelFromPaymentData = this.rootViewModel.params.paymentData.fullExpenseModel as ExpenseModel;
        response.result = fullExpenseModelFromPaymentData.availablePayments.collectionItems

          // Filtro per testo di ricerca
          .filter((p: AvailablePayment) => p.availablePaymentDescription.toLowerCase().indexOf(options.searchValue.toLowerCase()) > -1)

          .map((p: AvailablePayment) => {
            return [p.availablePaymentId, p.availablePaymentDescription]
          })
        return of(response);


      case 'WebExpenseAnnotation.ExpenseTypeObjects.Models.ExpenseType':

        const fullExpenseModelFromExpenseData = this.rootViewModel.params.expenseData.fullExpenseModel as ExpenseModel;
        response.result = fullExpenseModelFromExpenseData.availableExpenses.collectionItems

          // Filtro per expenseSelection
          .filter((e: AvailableExpense) => this.rootViewModel.params.expenseSelection.expenseClassification.value === e.expenseTypeRef.refoundClass)

          // Filtro per testo di ricerca
          .filter((e: AvailableExpense) => e.description.toLowerCase().indexOf(options.searchValue.toLowerCase()) > -1)

          .map((e: AvailableExpense) => {
            return [e.expenseTypeRef.code, (e.description?.length > 0 ? e.description : e.expenseTypeRef.description), e.expenseTypeRef.refoundClass]
          })
        return of(response);

      case 'WebExpenseAnnotation.ReceiptLongOpObjects.Models.ExtendedAvailableExpense':
        const fullExpenseModelFromExpenseDataForExtendedAvailableExpense = this.rootViewModel.params.expenseData.fullExpenseModel as ExpenseModel;
        response.result = fullExpenseModelFromExpenseDataForExtendedAvailableExpense.availableExpenses.collectionItems

          // Filtro per testo di ricerca
          .filter((p: AvailableExpense) => p.description.toLowerCase().indexOf(options.searchValue.toLowerCase()) > -1)

          .map((p: AvailableExpense) => {
            return [p.availableExpenseId, p.description]
          })
        return of(response);

      case 'CommissionMS.CommissionObjects.Models.Commission':
        if (this.onlineService.isOnline === false) {
          response.result = this.syncedCommissions
            .filter((c: Commission) => c.description.toLowerCase().includes(options.searchValue.toLowerCase()))
            .map((c: Commission) => {
              return [c.id, c.description]
            })
          return of(response);
        }
        break;

      case 'Subject.CustomerObjects.Models.Customer':
        if (this.onlineService.isOnline === false) {
          response.result = this.syncedCustomers
            .filter((c: Customer) => c.companyName.toLowerCase().includes(options.searchValue.toLowerCase()))
            .map((c: Customer) => {
              return [c.id, c.companyName]
            })
          return of(response);
        }
        break;

      case 'Subject.LeadObjects.Models.Lead':
        if (this.onlineService.isOnline === false) {
          response.result = this.syncedLeads
            .filter((l: Lead) => l.companyName.toLowerCase().includes(options.searchValue.toLowerCase()))
            .map((l: Lead) => {
              return [l.id, l.companyName]
            })
          return of(response);
        }
        break;
    }
    return super.getExternalAutoCompleteValues(options);
  }

  protected async getExpenseToEditImplementation(expenseIdentity: ExpenseIdentity): Promise<ServiceResponse> {
    const response = await this.loadExpenseToUpdateImplementation(expenseIdentity);
    return response;
  }

  async getExpenseToEdit(expenseIdentity: ExpenseIdentity): Promise<ServiceResponse> {
    let response = new ServiceResponse();
    response.operationSuccedeed = false;
    try {
      if (this.actionInProgress == false) {
        this.eventDispatcher.onActionInProgress.next(true);
      }
      response = await this.getExpenseToEditImplementation(expenseIdentity);
    } catch (e) {
      LogService.warn('getExpenseToEdit failed', e);
    } finally {
      this.eventDispatcher.onActionInProgress.next(false);
    }
    return response;
  }

  protected async integrateExpenseImplementation(longOp: ReceiptLongOp): Promise<GenericServiceResponse<ReceiptResult>> {

    const response = await firstValueFrom(this.apiClient.integrateExpenseDocumentation(longOp));
    if (response.operationSuccedeed && response.result != null) {
      const dm = this.rootViewModel.getDomainModel();
      dm.result = response.result;
      await this.tryRebuildViewModelAsync(response, dm, true, true);

      this.currentState.modify();
      //await this.postExecute();
    } else {
      LogService.debug('integrateExpenseImplementation failed', response);
    }
    return response;
  }

  protected async updateExpenseImplementation(longOp: ReceiptLongOp): Promise<GenericServiceResponse<ReceiptResult>> {

    const response = await firstValueFrom(this.apiClient.updateExpense(longOp));
    if (response.operationSuccedeed && response.result != null) {
      const dm = this.rootViewModel.getDomainModel();
      dm.result = response.result;
      await this.tryRebuildViewModelAsync(response, dm, true, true);

      this.currentState.modify();
      //await this.postExecute();
    } else {
      LogService.debug('updateExpenseImplementation failed', response);
    }
    return response;
  }

  async updateExpense(longOp: ReceiptLongOp): Promise<GenericServiceResponse<ReceiptResult>> {
    let response = null;
    try {
      if (this.actionInProgress == false) {
        this.eventDispatcher.onActionInProgress.next(true);
      }
      response = await this.updateExpenseImplementation(longOp);
      LogService.debug('upodateExpense completed');
    } catch (error) {
      LogService.error('updateExpense failed', error);
    } finally {
      this.eventDispatcher.onActionInProgress.next(false);
    }
    return response;
  }

  async integrateExpense(longOp: ReceiptLongOp): Promise<GenericServiceResponse<ReceiptResult>> {
    let response = null;
    try {
      if (this.actionInProgress == false) {
        this.eventDispatcher.onActionInProgress.next(true);
      }
      response = await this.integrateExpenseImplementation(longOp);
      LogService.debug('integrateExpense completed');
    } catch (error) {
      LogService.error('integrateExpense failed', error);
    } finally {
      this.eventDispatcher.onActionInProgress.next(false);
    }
    return response;
  }

  getExtendedAvailablePaymentFromAvailablePayment(availablePayment: AvailablePayment): ExtendedAvailablePayment {
    const extendedAvailablePayment = new ExtendedAvailablePayment();
    extendedAvailablePayment.availablePaymentIdentity = new AvailablePaymentIdentity();
    extendedAvailablePayment.availablePaymentIdentity.availablePaymentId = availablePayment.availablePaymentId;
    extendedAvailablePayment.availablePaymentIdentity.modelCode = availablePayment.modelCode;
    extendedAvailablePayment.description = availablePayment.availablePaymentDescription;
    extendedAvailablePayment.id = availablePayment.absoluteId;
    return extendedAvailablePayment;
  }

  getExtendedAvailableExpenseFromAvailableExpense(availableExpense: AvailableExpense): ExtendedAvailableExpense {
    const extendedAvailableExpense = new ExtendedAvailableExpense();
    extendedAvailableExpense.availableExpenseIdentity = new AvailableExpenseIdentity();
    extendedAvailableExpense.availableExpenseIdentity.availableExpenseId = availableExpense.availableExpenseId;
    extendedAvailableExpense.availableExpenseIdentity.modelCode = availableExpense.modelCode;
    extendedAvailableExpense.description = availableExpense.description;
    extendedAvailableExpense.availableExpenseInstructions = availableExpense.instructions;
    extendedAvailableExpense.id = availableExpense.absoluteId;
    return extendedAvailableExpense;
  }

  getExtendedAvailablePaymentFromPersonalPayment(personalPayment: PersonalPayment): ExtendedAvailablePayment {
    const extendedAvailablePayment = new ExtendedAvailablePayment();
    extendedAvailablePayment.description = personalPayment.description;
    extendedAvailablePayment.personalPaymentIdentity = new PersonalPaymentIdentity();
    extendedAvailablePayment.personalPaymentIdentity.personalPaymentId = personalPayment.personalPaymentId;
    extendedAvailablePayment.personalPaymentIdentity.userId = personalPayment.userId;
    extendedAvailablePayment.personalPaymentIdentity.companyId = personalPayment.companyId;
    extendedAvailablePayment.id = personalPayment.absoluteId;
    return extendedAvailablePayment;
  }

  getExtendedAvailableExpenseFromPersonalExpense(personalExpense: PersonalExpense): ExtendedAvailableExpense {
    const extendedAvailableExpense = new ExtendedAvailableExpense();
    extendedAvailableExpense.description = personalExpense.description;
    extendedAvailableExpense.personalExpenseIdentity = new PersonalExpenseIdentity();
    extendedAvailableExpense.personalExpenseInstructions = personalExpense.instructions;
    extendedAvailableExpense.personalExpenseIdentity.personalExpenseId = personalExpense.personalExpenseId;
    extendedAvailableExpense.personalExpenseIdentity.userId = personalExpense.userId;
    extendedAvailableExpense.personalExpenseIdentity.companyId = personalExpense.companyId;
    extendedAvailableExpense.id = personalExpense.absoluteId;
    return extendedAvailableExpense;
  }

  async getPersonalPayments(): Promise<PersonalPayment[]> {
    const tenantId: number = await this.authService.getTenantId();
    const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);
    const identity = new UserAvailablePaymentsIdentity();
    identity.userId = await this.authService.getCurrentUserId()
    identity.companyId = enterpriseData.companyId;

    const userAvailablePaymentsResponse = await firstValueFrom(this.apiClient.getPersonalPayments(
      identity,
      {
        bypass: false,
        force: true
      }
    ))

    if (userAvailablePaymentsResponse.operationSuccedeed === true) {
      return userAvailablePaymentsResponse.result || [];
    } else {
      this.toastMessageService.showToastsFromResponse(userAvailablePaymentsResponse);
    }
    return [];
  }

  async getExtendedAvailablePayments(selectedModelCode: number): Promise<ExtendedAvailablePayment[]> {

    let availableModels: ExpenseModel[] = [];

    const tenantId: number = await this.authService.getTenantId();
    const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);

    const availableModelsResponse = await firstValueFrom(this.apiClient.getAvailableModels(undefined, enterpriseData.companyId))
    if (availableModelsResponse.operationSuccedeed === true) {
      availableModels = availableModelsResponse.result || [];
    } else {
      availableModels = [];
    }
    const fullExpenseModel = availableModels.find((e: ExpenseModel) => e.modelCode === selectedModelCode);
    const personalPayments = await this.getPersonalPayments() as PersonalPayment[];

    //#4651 - filtro per rimuovere i pagamenti cash non personali se ne ho uno personale di tipo cash
    var personalCashAdvance = personalPayments.find(payment => payment.personalPaymentType == PersonalPayments.CashAdvance)
    //questo absolute id rappresenta la posizione nella lista di ExtendedAvailablePayment che è l'unione di pagamenti personali e
    //pagamenti di modello
    let absoluteId = 0;

    //per costruire la lista di pagamenti ammessi per una spesa, ci sono alcuni step:
    //prima uso isOldPaymentSelected per includere la modalità di pagamento selezionata nel caso sia in modifica spesa
    //come secondo controllo vengono filtrate le modalità di pagamento in base alla data selezionata sulla spesa
    //usando filterPaymentsByDate. come ultimo controllo c'è la verifica dell'isCashAdvance;
    //se non c'è un fondo cassa personale, il filtro darà true e quindi non escluderà nulla altrimenti verranno esclusi i pagamenti
    //iscashadvance di modello(sempre che l'utente abbia un fondo cassa personale -> personalCashAdvance != undefined)
    const extendedAvailablePayments = fullExpenseModel.availablePayments.collectionItems.map(availablePayment => {
      //in questa map viene valorizzato l'absoluteId dell'availablePayment
      availablePayment.absoluteId = ++absoluteId;
      return availablePayment;
    }).filter(availablePayment =>
      this.isOldPaymentSelected(availablePayment) ||
      this.filterPaymentsByDate(availablePayment) && (personalCashAdvance == undefined || !availablePayment.isCashAdvance)).map(
        (ap: AvailablePayment) => this.getExtendedAvailablePaymentFromAvailablePayment(ap)
      ) as ExtendedAvailablePayment[];

    //dopo aver filtrato i pagamenti disponibili da modello, devo aggiungere i pagamenti personali; per poterlo fare
    //vengono aggiunti in fondo alla lista di extendedavailablepayment; per ottenere l'id giusto con cui aggiungerli
    //e gestire dunque il pagamento eventualmente già selezionato nella lista, devo partire dall'ultimo id dei
    //pagamenti di modello, dato che in quelli filtrati potrebbero non esserci quelli che hanno id più alto
    //#4666 prima di aggiungerli devo però filtrare per data e modello associato ai pagamenti, includendo però tutti
    //i pagamenti fondocassa. Quindi se sono fondocassa vengo sempre incluso, altrimenti devo avere il modello associato giusto
    //ed essere o un pagamento già selezionato o un pagamento che rispetta le date

    const filteredPersonalPayments = personalPayments.map(persPay => {
      //in questa map viene valorizzato l'absoluteId del personalPayment
      persPay.absoluteId = ++absoluteId;
      return persPay
    }).filter(personalPayment =>
      personalPayment.expenseModelNCTCode == undefined || (
        this.personalPaymentIsAssociatedToExpenseModel(personalPayment) &&
        (this.isOldPersonalPaymentSelected(personalPayment) ||
          this.filterPersonalPaymentsByDate(personalPayment))))

    if (filteredPersonalPayments?.length > 0) {
      extendedAvailablePayments.push(...filteredPersonalPayments.map(
        (pp) => this.getExtendedAvailablePaymentFromPersonalPayment(pp)
      ))
    }

    return extendedAvailablePayments;
  }

  async fixExtendedAvailableExpense(params: ReceiptParams) {

    if (params?.expenseData?.extendedExpenseTypeRef) {

      const extendedAvailableExpenses = await this.getExtendedAvailableExpenses(
        params?.expenseModelData?.expenseModelCode,
        params?.expenseSelection?.expenseClassification,
        params?.userRef?.currentIdentity
      );

      if (params?.expenseData?.extendedExpenseTypeRef?.availableExpenseIdentity?.availableExpenseId) {
        params.expenseData.extendedExpenseTypeRef = extendedAvailableExpenses.find((eae) =>
          eae?.availableExpenseIdentity?.availableExpenseId === params?.expenseData?.extendedExpenseTypeRef?.availableExpenseIdentity?.availableExpenseId
        )
      } else if (params?.expenseData?.extendedExpenseTypeRef?.personalExpenseIdentity?.personalExpenseId) {
        params.expenseData.extendedExpenseTypeRef = extendedAvailableExpenses.find((eae) =>
          eae?.personalExpenseIdentity?.personalExpenseId === params?.expenseData?.extendedExpenseTypeRef?.personalExpenseIdentity?.personalExpenseId
        )
      } else {
        params.expenseData.extendedExpenseTypeRef = null;
      }

      //Se sono in modifica di una spesa e sono riuscito a settare un extendedPaymentTypeRef, vado a valorizzare il campo oldPaymentAbsoluteId
      //nei params del rootviewmodel; così facendo mi posso salvare l'id "assoluto" del pagamento originale della spesa. Questo serve per permettere
      //di selezionare nell'ultimo step dell'aggiunta di una spesa il pagamento che era già selezionato per la spesa, anche se non potrebbe essere
      //selezionato. e.g. apro una spesa in modifica, cambio la data della spesa e ne imposto una in cui il pagamento selezionato per la spesa non
      //è valido; salvando l'oldPaymentAbsoluteId posso selezionare e salvare il pagamento originario. Imposto oldPaymentAbsoluteId = id
      //dell'extendedPaymentTypeRef perchè in questo momento siamo già passati per il metodo getExtendedAvailablePayments, metodo in cui vengono
      //valorizzati gli id dell'extendedPaymentTypeRef sfruttando il meccanismo degli absoluteId
      if (params.expenseAnnotationIdentity != undefined && params.expenseIdentity != undefined && params.expenseData.extendedExpenseTypeRef != null) {
        params.expenseData.oldExpenseAbsoluteId = params.expenseData.extendedExpenseTypeRef.id
      }
      /*
      if (params.paymentData.extendedPaymentTypeRef.personalPaymentIdentity?.userId > 0 &&
          params.paymentData.extendedPaymentTypeRef.personalPaymentIdentity?.companyId == null) {
          const tenantId = await this.authService.getTenantId();
          const enterpriseData = await this.authService.getEnterpriseData(tenantId);
          params.paymentData.extendedPaymentTypeRef.personalPaymentIdentity.companyId = enterpriseData?.companyId;
      }*/
    }
  }

  async fixExtendedAvailablePayment(params: ReceiptParams) {

    if (params?.paymentData?.extendedPaymentTypeRef) {

      const extendedAvailablePayments = await this.getExtendedAvailablePayments(params?.expenseModelData?.expenseModelCode);

      if (params?.paymentData?.extendedPaymentTypeRef?.availablePaymentIdentity?.availablePaymentId) {
        params.paymentData.extendedPaymentTypeRef = extendedAvailablePayments.find((eap) =>
          eap?.availablePaymentIdentity?.availablePaymentId === params?.paymentData?.extendedPaymentTypeRef?.availablePaymentIdentity?.availablePaymentId
        )
      } else if (params?.paymentData?.extendedPaymentTypeRef?.personalPaymentIdentity?.personalPaymentId) {
        params.paymentData.extendedPaymentTypeRef = extendedAvailablePayments.find((eap) =>
          eap?.personalPaymentIdentity?.personalPaymentId === params?.paymentData?.extendedPaymentTypeRef?.personalPaymentIdentity?.personalPaymentId
        )
      } else {
        params.paymentData.extendedPaymentTypeRef = null;
      }

      //Se sono in modifica di una spesa e sono riuscito a settare un extendedPaymentTypeRef, vado a valorizzare il campo oldPaymentAbsoluteId
      //nei params del rootviewmodel; così facendo mi posso salvare l'id "assoluto" del pagamento originale della spesa. Questo serve per permettere
      //di selezionare nell'ultimo step dell'aggiunta di una spesa il pagamento che era già selezionato per la spesa, anche se non potrebbe essere
      //selezionato. e.g. apro una spesa in modifica, cambio la data della spesa e ne imposto una in cui il pagamento selezionato per la spesa non
      //è valido; salvando l'oldPaymentAbsoluteId posso selezionare e salvare il pagamento originario. Imposto oldPaymentAbsoluteId = id
      //dell'extendedPaymentTypeRef perchè in questo momento siamo già passati per il metodo getExtendedAvailablePayments, metodo in cui vengono
      //valorizzati gli id dell'extendedPaymentTypeRef sfruttando il meccanismo degli absoluteId
      if (params.expenseAnnotationIdentity != undefined && params.expenseIdentity != undefined && params.paymentData.extendedPaymentTypeRef != null) {
        params.paymentData.oldPaymentAbsoluteId = params.paymentData.extendedPaymentTypeRef.id
      }
      /*
      if (params.paymentData.extendedPaymentTypeRef.personalPaymentIdentity?.userId > 0 &&
          params.paymentData.extendedPaymentTypeRef.personalPaymentIdentity?.companyId == null) {
          const tenantId = await this.authService.getTenantId();
          const enterpriseData = await this.authService.getEnterpriseData(tenantId);
          params.paymentData.extendedPaymentTypeRef.personalPaymentIdentity.companyId = enterpriseData?.companyId;
      }*/
    }
  }

  async getPersonalExpenses(): Promise<PersonalExpense[]> {
    const tenantId: number = await this.authService.getTenantId();
    const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);
    const identity = new UserAvailableExpensesIdentity();
    identity.userId = await this.authService.getCurrentUserId()
    identity.companyId = enterpriseData.companyId;

    const userAvailableExpensesResponse = await firstValueFrom(this.apiClient.getPersonalExpenses(
      identity,
      {
        bypass: false,
        force: true
      }
    ))

    if (userAvailableExpensesResponse.operationSuccedeed === true) {
      return userAvailableExpensesResponse.result || [];
    } else {
      this.toastMessageService.showToastsFromResponse(userAvailableExpensesResponse);
    }
    return [];
  }

  async getExtendedAvailableExpenses(
    selectedModelCode: number,
    currentExpenseClassification: ExpenseClassification,
    currentExpenseUserIdentity: UserOfTenantIdentity
  ): Promise<ExtendedAvailableExpense[]> {

    let availableModels: ExpenseModel[] = [];

    const tenantId: number = await this.authService.getTenantId();
    const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);

    let availableModelsResponse: ExpenseModelListResponse;

    const currentUserId = await this.authService.getCurrentUserId()

    // Verifico se la spesa da aprire è dell'utente corrente
    const isOwnExpense = currentUserId === currentExpenseUserIdentity?.id;

    if (isOwnExpense) {
      availableModelsResponse = await firstValueFrom(this.apiClient.getAvailableModels(undefined, enterpriseData.companyId))
    } else {
      availableModelsResponse = await firstValueFrom(this.apiClient.getAvailableModelsForUserAsync(currentExpenseUserIdentity))
    }

    if (availableModelsResponse.operationSuccedeed === true) {
      availableModels = availableModelsResponse.result || [];
    } else {
      availableModels = [];
    }

    if (availableModels?.length == 0) {
      throw new Error('Modello della spesa non disponibile!')
    }

    const fullExpenseModel = availableModels.find((e: ExpenseModel) => e.modelCode === selectedModelCode);

    const personalExpenses = await this.getPersonalExpenses() as PersonalExpense[];

    //questo absolute id rappresenta la posizione nella lista di ExtendedAvailableExpense che è l'unione di tipi di spesa personali e
    //tipi di spesa di modello
    let absoluteId = 0;

    //per costruire la lista di tipi di spesa ammessi per una spesa, ci sono alcuni step:
    //prima uso isOldExpenseSelected per includere la modalità di tipi di spesa selezionata nel caso sia in modifica spesa
    //come secondo controllo vengono filtrate le modalità di tipi di spesa in base alla data selezionata sulla spesa
    //usando filterExpensesByDate
    const extendedAvailableExpenses = fullExpenseModel.availableExpenses.collectionItems.map(availableExpense => {
      //in questa map viene valorizzato l'absoluteId dell'availableExpense
      availableExpense.absoluteId = ++absoluteId;
      return availableExpense;
    }).filter(availableExpense =>
      this.filterExpensesByClassification(availableExpense, currentExpenseClassification) &&
      (
        this.isOldExpenseSelected(availableExpense) ||
        this.filterExpensesByDate(availableExpense)
      )
    ).map((ap: AvailableExpense) => this.getExtendedAvailableExpenseFromAvailableExpense(ap)
    ) as ExtendedAvailableExpense[];

    //dopo aver filtrato i tipi di spesa disponibili da modello, devo aggiungere i tipi di spesa personali; per poterlo fare
    //vengono aggiunti in fondo alla lista di extendedavailableexpense; per ottenere l'id giusto con cui aggiungerli
    //e gestire dunque il tipo di spesa eventualmente già selezionato nella lista, devo partire dall'ultimo id dei
    //tipi di spesa di modello, dato che in quelli filtrati potrebbero non esserci quelli che hanno id più alto
    //#4666 prima di aggiungerli devo però filtrare per data e modello associato ai tipi di spesa.
    //Quindi devo avere il modello associato giusto ed essere o un tipo di spesa già selezionato o un tipo di spesa che rispetta le date

    const filteredPersonalExpenses = personalExpenses.map(persExp => {
      //in questa map viene valorizzato l'absoluteId del personalExpense
      persExp.absoluteId = ++absoluteId;
      return persExp
    }).filter(personalExpense =>
      this.filterPersonalExpenseByClassification(personalExpense, currentExpenseClassification) &&
      (
        personalExpense.expenseModelNCTCode == undefined ||
        (
          this.personalExpenseIsAssociatedToExpenseModel(personalExpense) &&
          (
            this.isOldPersonalExpenseSelected(personalExpense) ||
            this.filterPersonalExpensesByDate(personalExpense)
          )
        )
      )
    )

    if (filteredPersonalExpenses?.length > 0) {
      extendedAvailableExpenses.push(...filteredPersonalExpenses.map(
        (pp) => this.getExtendedAvailableExpenseFromPersonalExpense(pp)
      ))
    }

    return extendedAvailableExpenses;
  }

  preloadImages(expenseFiles: ExpenseFileCollectionViewModel) {
    for (const element of expenseFiles) {
      const fileExt = element.originalFileName.value.toLowerCase().split('.').pop();
      if (['png', 'jpg', 'jpeg', 'bmp', 'gif'].indexOf(fileExt) > -1) {


        if (element.fileObject) {

          /**
           * Se il file proviene dallo store locale, allora associo solo le variabili necessarie per la preview
           */

          element.filePreviewUrl = this.domSanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(element.fileObject));
          element.fileBlob = element.fileObject;

        } else {

          /**
           * Se il file proviene dallo store remoto, allora scarico il file e lo associo alla preview
           */

          const identity = element.getDomainModel().attachmentIdentity;

          this.apiClient.downloadFile(identity).subscribe({
            next: async (httpEvent: HttpEvent<Blob>) => {

              if (httpEvent) {
                if (httpEvent.type === HttpEventType.DownloadProgress) {
                  // this.progressBarValue = Math.round(100 * httpEvent.loaded / httpEvent.total);
                  // this.cd.detectChanges(); //TODO
                } else if (httpEvent.type === HttpEventType.Response) {
                  // download file
                  const blob = httpEvent.body;

                  element.fileBlob = blob;
                  element.filePreviewUrl = this.domSanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
                }
              }
            },
            error: (err) => {
              LogService.warn('ERRORE downloadFile', err)
            }
          });
        }
      }
    }
  }

  async loadExpenseToUpdateImplementation(expenseIdentity: ExpenseIdentity): Promise<GenericServiceResponse<ReceiptLongOp>> {
    return firstValueFrom(this.apiClient.getExpenseToUpdate(expenseIdentity))
      .then(async (response) => {
        if (response.operationSuccedeed && response.result != null) {

          await this.fixExtendedAvailableExpense(response.result?.params);

          await this.fixExtendedAvailablePayment(response.result?.params);

          await this.tryRebuildViewModelAsync(response, response.result, false, true);

        } else {
          this.toastMessageService.showToastsFromResponse(response);
        }
        return response;
      }).catch(err => {
        LogService.warn('loadExpenseToUpdateImplementation long op failed', err);
        const response = new GenericServiceResponse<ReceiptLongOp>(this.apiClient.rootModelType);
        response.operationSuccedeed = false;
        this.toastMessageService.showToast({
          type: ToastMessageType.warn,
          message: "Impossibile aprire la spesa!",
          title: MessageResourceManager.Current.getMessage(MessageCodes.Warning)
        })
        return response;
      });
  }

  override async executeLongOp(): Promise<GenericServiceResponse<ReceiptResult>> {
    let response = null;
    try {
      if (this.actionInProgress == false) {
        this.eventDispatcher.onActionInProgress.next(true);
      }
      response = await this.executeLongOpImplementationAsync(true);
      LogService.debug('execute longop completed');
    } catch (error) {
      LogService.error('execute longop failed', error);
    } finally {
      this.eventDispatcher.onActionInProgress.next(false);
    }
    return response;
  }

  protected override async executeLongOpImplementationAsync(clearPreviousMessages: boolean): Promise<GenericServiceResponse<ReceiptResult>> {

    const response = await this.apiClient.executeLongOp(this.rootViewModel.getDomainModel()).toPromise();
    if (response.operationSuccedeed && response.result != null) {
      const dm = this.rootViewModel.getDomainModel();
      dm.result = response.result;
      await this.tryRebuildViewModelAsync(response, dm, true, clearPreviousMessages);

      this.currentState.modify();
      //await this.postExecute();
    } else {
      LogService.debug('executeLongOpImplementationAsync failed', response);
    }
    return response;
  }

  //card #4651/#4536 metodo che filtra i pagamenti in base alle date di validità ed alla data selezionata sulla spese
  private filterPaymentsByDate(availablePayment: AvailablePayment): boolean {
    //cerco la data della spesa
    let selectedDate = this.rootViewModel?.params?.expenseData.expenseDate.value;
    //se la data esiste(la data non esiste al primo caricamento della maschera, no viewmodel ancora) allora voglio
    //che la modalità di pagamento abbia date validità che comprenda la data selezionata
    return selectedDate == undefined || selectedDate?.dateTime?.getTime() >= availablePayment.startDate.dateTime.getTime() &&
      selectedDate?.dateTime?.getTime() <= availablePayment.expireDate.dateTime.getTime();
  }

  private filterExpensesByClassification(availableExpense: AvailableExpense, currentExpenseClassification: ExpenseClassification): boolean {
    return availableExpense.expenseTypeRef.refoundClass === currentExpenseClassification;
  }

  //card #4651/#4536 metodo che filtra i tipi di spesa in base alle date di validità ed alla data selezionata sulla spese
  private filterExpensesByDate(availableExpense: AvailableExpense): boolean {

    if (availableExpense?.startDate?.dateTime == null) {
      // lo startDate sincronizzato da business, è nullo considero l'AvailableExpense valido
      return true;
    }

    if (availableExpense?.expireDate?.dateTime == null) {
      // lo expireDate sincronizzato da business, è nullo considero l'AvailableExpense valido
      return true;
    }

    //cerco la data della spesa
    let selectedDate = this.rootViewModel?.params?.expenseData.expenseDate.value;
    //se la data esiste(la data non esiste al primo caricamento della maschera, no viewmodel ancora) allora voglio
    //che il tipo di spesa abbia date validità che comprenda la data selezionata
    return selectedDate == undefined || selectedDate?.dateTime?.getTime() >= availableExpense.startDate.dateTime.getTime() &&
      selectedDate?.dateTime?.getTime() <= availableExpense.expireDate.dateTime.getTime();
  }

  //card #4666 metodo che filtra i pagamenti personali in base alle date di validità ed alla data selezionata sulla spese
  private filterPersonalPaymentsByDate(personalPayment: PersonalPayment): boolean {
    //cerco la data della spesa
    let selectedDate = this.rootViewModel?.params?.expenseData.expenseDate.value;
    //se la data esiste(la data non esiste al primo caricamento della maschera, no viewmodel ancora) allora voglio
    //che la modalità di pagamento abbia date validità che comprenda la data selezionata
    return selectedDate == undefined || selectedDate?.dateTime?.getTime() >= personalPayment.startDate?.dateTime.getTime() &&
      selectedDate?.dateTime?.getTime() <= personalPayment.expireDate?.dateTime.getTime();
  }

  //card #4666 metodo che filtra i tipi di spesa personali in base alle date di validità ed alla data selezionata sulla spese
  private filterPersonalExpensesByDate(personalExpense: PersonalExpense): boolean {
    //cerco la data della spesa
    let selectedDate = this.rootViewModel?.params?.expenseData.expenseDate.value;
    //se la data esiste(la data non esiste al primo caricamento della maschera, no viewmodel ancora) allora voglio
    //che il tipo di spesa abbia date validità che comprenda la data selezionata
    return selectedDate == undefined || selectedDate?.dateTime?.getTime() >= personalExpense.startDate?.dateTime.getTime() &&
      selectedDate?.dateTime?.getTime() <= personalExpense.expireDate?.dateTime.getTime();
  }

  //card #4651/#4536 metodo usato per filtrare i pagamenti da proporre nell'ultimo step dell'inserimento spesa.
  //Il metodo serve a mantenere tra le modalità di pagamento proposte durante la modifica di una spesa la modalità che era
  //già salvata sulla spesa, senza tenere quindi conto dei controlli sulle date
  private isOldPaymentSelected(availablePayment: AvailablePayment): boolean {

    //ottengo l'absoluteId della modalità di pagamento originale della spesa
    let oldPaymentId = this.rootViewModel?.params?.paymentData?.getDomainModel().oldPaymentAbsoluteId;
    //utilizzo questa expenseIdentity per capire se sono una spesa nuova o sono in modifica
    let expenseIdentity = this.rootViewModel?.params?.domainModel?.expenseIdentity;
    //per marcare una modalità di pagamento come "selezionata" voglio che la spesa sia in modifica(quindi expenseIdentity deve
    //esistere), devo avere un'absolute id della vecchia mod. pagamento (è undefined quando la classe è appena inizializzata, dato
    //che non avrò ancora un viewmodel)
    return expenseIdentity != undefined && oldPaymentId != undefined && (availablePayment.absoluteId == oldPaymentId);
  }

  //card #4651/#4536 metodo usato per filtrare i tipi di spesa da proporre al penultimo step dell'inserimento spesa.
  //Il tipo serve a mantenere tra i tipi di spesa proposti durante la modifica di una spesa la modalità che era
  //già salvata sulla spesa, senza tenere quindi conto dei controlli sulle date
  private isOldExpenseSelected(availableExpense: AvailableExpense): boolean {

    //ottengo l'absoluteId del tipo di spesa originale della spesa
    let oldExpenseId = this.rootViewModel?.params?.expenseData?.getDomainModel().oldExpenseAbsoluteId;
    //utilizzo questa expenseIdentity per capire se sono una spesa nuova o sono in modifica
    let expenseIdentity = this.rootViewModel?.params?.domainModel?.expenseIdentity;
    //per marcare un tipo di spesa come "selezionata" voglio che la spesa sia in modifica(quindi expenseIdentity deve
    //esistere), devo avere un'absolute id della vecchi o tipo di spesa (è undefined quando la classe è appena inizializzata, dato
    //che non avrò ancora un viewmodel)
    return expenseIdentity != undefined && oldExpenseId != undefined && (availableExpense.absoluteId == oldExpenseId);
  }

  //card #4666 metodo usato per filtrare i pagamenti personali da proporre nell'ultimo step dell'inserimento spesa.
  //Il metodo serve a mantenere tra le modalità di pagamento proposte durante la modifica di una spesa la modalità che era
  //già salvata sulla spesa, senza tenere quindi conto dei controlli sulle date
  private isOldPersonalPaymentSelected(personalPayment: PersonalPayment): boolean {

    //ottengo l'absoluteId della modalità di pagamento originale della spesa
    let oldPersonalPaymentId = this.rootViewModel?.params?.paymentData?.getDomainModel().oldPaymentAbsoluteId;
    //utilizzo questa expenseIdentity per capire se sono una spesa nuova o sono in modifica
    let expenseIdentity = this.rootViewModel?.params?.domainModel?.expenseIdentity;
    //per marcare una modalità di pagamento come "selezionata" voglio che la spesa sia in modifica(quindi expenseIdentity deve
    //esistere), devo avere un'absolute id della vecchia mod. pagamento (è undefined quando la classe è appena inizializzata, dato
    //che non avrò ancora un viewmodel)
    return expenseIdentity != undefined && oldPersonalPaymentId != undefined && (personalPayment.absoluteId == oldPersonalPaymentId);
  }

  //card #4666 metodo usato per filtrare i tipi di spesa personali da proporre nel penultimo step dell'inserimento spesa.
  //Il metodo serve a mantenere tra i tipi di spesa proposti durante la modifica di una spesa la tipologia che era
  //già salvata sulla spesa, senza tenere quindi conto dei controlli sulle date
  private isOldPersonalExpenseSelected(personalExpense: PersonalExpense): boolean {

    //ottengo l'absoluteId della modalità di pagamento originale della spesa
    let oldExpenseAbsoluteId = this.rootViewModel?.params?.expenseData?.getDomainModel().oldExpenseAbsoluteId;
    //utilizzo questa expenseIdentity per capire se sono una spesa nuova o sono in modifica
    let expenseIdentity = this.rootViewModel?.params?.domainModel?.expenseIdentity;
    //per marcare un tipo di pagamento come "selezionato" voglio che la spesa sia in modifica(quindi expenseIdentity deve
    //esistere), devo avere un'absolute id del veccio tipo di spesa (è undefined quando la classe è appena inizializzata, dato
    //che non avrò ancora un viewmodel)
    return expenseIdentity != undefined && oldExpenseAbsoluteId != undefined && (personalExpense.absoluteId == oldExpenseAbsoluteId);
  }


  //#4666 i pagamenti personali utilizzabili da un utente sono quelli senza modello associato o con modello associato =
  //a quello della spesa che sto inserendo
  private personalPaymentIsAssociatedToExpenseModel(personalPayment: PersonalPayment): boolean {

    let expenseModelCode = this.rootViewModel?.params.expenseModelData?.expenseModelCode.value;

    return expenseModelCode == undefined || personalPayment.expenseModelNCTCode == expenseModelCode;
  }

  //#4666 i tipi di spesa personali utilizzabili da un utente sono quelli senza modello associato o con modello associato =
  //a quello della spesa che sto inserendo
  private personalExpenseIsAssociatedToExpenseModel(personalExpense: PersonalExpense): boolean {

    let expenseModelCode = this.rootViewModel?.params.expenseModelData?.expenseModelCode.value;

    return expenseModelCode == undefined || personalExpense.expenseModelNCTCode == expenseModelCode;
  }

  private filterPersonalExpenseByClassification(personalExpense: PersonalExpense, currentExpenseClassification: ExpenseClassification): boolean {
    return personalExpense.expenseTypeRef.refoundClass === currentExpenseClassification;
  }

  async getClassificationLabels(): Promise<Label[]> {

    const res = await firstValueFrom(this.apiClient.getClassificationLabelsAsync().pipe(
      take(1),
    ));

    if (res.operationSuccedeed) {
      return res.result;
    } else {
      return null;
    }
  }
}
