
import {
  IdentityTypeDecorator, OCCModel, ModelTypeNameDecorator, Expose, AllowedChars, NumberDecorator,
  StringDecorator, EnumDecorator
} from '@nts/std';
import { UserOfTenantIdentity } from './user-of-tenant.identity';


@IdentityTypeDecorator(UserOfTenantIdentity)
@ModelTypeNameDecorator('UserOfTenant')
export class UserOfTenant extends OCCModel<UserOfTenantIdentity> {

  // tslint:disable-next-line: variable-name
  private _id: number;

  @Expose()
  @NumberDecorator({ displayNameKey: 'Tenant_Id' })
  get id(): number {
    return this.getValueForIdentityValue<number>(() => this._id, 'id');
  }
  set id(value: number) {
    this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
  }

  // tslint:disable-next-line: variable-name
  private _name: string;

  @Expose()
  get name(): string {
    return this.getValue<string>(() => this._name, 'name');
  }
  set name(value: string) {
    this.setValue<string>(() => { this._name = value; }, value, 'name');
  }

  // tslint:disable-next-line: variable-name
  private _lastName: string;

  @Expose()
  get lastName(): string {
    return this.getValue<string>(() => this._lastName, 'lastName');
  }
  set lastName(value: string) {
    this.setValue<string>(() => { this._lastName = value; }, value, 'lastName');
  }
}
