import { BaseError, EnumPropertyViewModel, ExternalViewModelTypeDecorator, MessageResourceManager, NNumericPropertyViewModel, PropertyViewModelPropertyChangedEventArgs } from '@nts/std';
import { BehaviorSubject, merge } from 'rxjs';
import { ExpenseModel } from 'src/app/expense-model/domain-models/expense-model';
import { StepViewModelAwareInterface } from 'src/app/shared/models/step-view-model-aware.interface';
import { ColorUtility } from 'src/app/shared/services/color-utility';
import { ExpenseClassificationMapper } from 'src/app/shared/services/expense-classification-mapper';
import { ExpenseClassification } from '../generated/domain-models/enums/generated-expense-classification';
import { GeneratedPaymentDataViewModel } from '../generated/view-models/generated-payment-data.view-model';
import { ExpenseStateMapper } from 'src/app/shared/services/expense-state-mapper';
import { ExtendedAvailablePaymentExtViewModel } from './extended-available-payment.ext-view-model';
import { ExtendedAvailablePayment } from '../domain-models/extended-available-payment';
import { OnlineService } from '@nts/std/src/lib/utility';
import { SupplierExtViewModel } from './supplier.ext.view-model';
import { ReceiptParamsViewModel } from './receipt-params.view-model';

export class PaymentDataViewModel extends GeneratedPaymentDataViewModel implements StepViewModelAwareInterface {


  @ExternalViewModelTypeDecorator(SupplierExtViewModel)
  public supplierRef: SupplierExtViewModel;

  currentColor = null;
  currentActiveColor = null;
  currentHoverColor = null;
  currentExpenseClassificationType: ExpenseClassification;
  expenseClassificationEnum = ExpenseClassification;
  isValid = new BehaviorSubject<boolean>(false);
  hasActionsButtons = new BehaviorSubject<boolean>(true);
  isPreviousDisabled = new BehaviorSubject<boolean>(false);
  hideNext = new BehaviorSubject<boolean>(false);
  fullExpenseModel: ExpenseModel = null;
  extendedAvailablePayments: ExtendedAvailablePayment[] = [];
  expenseState: EnumPropertyViewModel
  expenseStateMapper = ExpenseStateMapper;
  showCurrentState: boolean;
  onlineService: OnlineService;

  private _extendedPaymentTypeId: NNumericPropertyViewModel;

  public get extendedPaymentTypeId(): NNumericPropertyViewModel {
    return this.getNNumericPropertyViewModel((value) => { this._extendedPaymentTypeId = value; }, this._extendedPaymentTypeId, 'extendedPaymentTypeId');
  }

  @ExternalViewModelTypeDecorator(ExtendedAvailablePaymentExtViewModel)
  public extendedPaymentTypeRef: ExtendedAvailablePaymentExtViewModel;

  async initPresentationLogic(
    expenseClassificationType: ExpenseClassification,
    fullExpenseModel: ExpenseModel,
    extendedAvailablePayments: ExtendedAvailablePayment[],
    expenseAnnotationId: number,
    remoteExpense = false,
    expenseState: EnumPropertyViewModel,
    onlineService: OnlineService,
    isEnabled: boolean
  ) {

    this.isEnabled = isEnabled;
    this.showCurrentState = expenseAnnotationId > 0 && remoteExpense === true;
    this.currentExpenseClassificationType = expenseClassificationType;
    this.extendedPaymentTypeRef.isEnabled = this.currentExpenseClassificationType !== ExpenseClassification.MileageRefound;
    this.currentColor = ColorUtility.shade(ExpenseClassificationMapper.getColorByClassificationType(expenseClassificationType), -0.1);
    this.currentActiveColor = ColorUtility.shade(this.currentColor, -0.1)
    this.currentHoverColor = ColorUtility.shade(this.currentColor, -0.3)
    this.fullExpenseModel = fullExpenseModel;
    this.expenseState = expenseState;
    this.onlineService = onlineService;

    if (extendedAvailablePayments.length == 0) {
      await this.extendedPaymentTypeRef.setCodeValue(null);
    } else {
      if (this.extendedPaymentTypeRef?.id?.value > 0) {
        const found = extendedAvailablePayments.find((eap) => eap.id === this.extendedPaymentTypeRef?.id?.value)
        if (!found) {
          await this.extendedPaymentTypeRef.setCodeValue(null);
        }
      }
    }

    if (!isEnabled) {
      this.id.isEnabled = isEnabled;
      this.extendedPaymentTypeRef.isEnabled = isEnabled;
      this.reliableSupplier.isEnabled = isEnabled;
      this.supplierId.isEnabled = isEnabled;
      this.supplierRef.isEnabled = isEnabled;
      this.businessName.isEnabled = isEnabled;
      this.vatNumber.isEnabled = isEnabled;
      this.paymentTypeDescription.isEnabled = isEnabled;
    }

    this.extendedPaymentTypeId.defaultColor = this.currentColor;
    this.extendedPaymentTypeId.activeColor = this.currentActiveColor;
    this.extendedPaymentTypeId.hoverColor = this.currentHoverColor;

    this.extendedPaymentTypeRef.defaultColor = this.currentColor;
    this.extendedPaymentTypeRef.activeColor = this.currentActiveColor;
    this.extendedPaymentTypeRef.hoverColor = this.currentHoverColor;

    this.reliableSupplier.defaultColor = this.currentColor;
    this.reliableSupplier.activeColor = this.currentActiveColor;
    this.reliableSupplier.hoverColor = this.currentHoverColor;

    this.supplierId.defaultColor = this.currentColor;
    this.supplierId.activeColor = this.currentActiveColor;
    this.supplierId.hoverColor = this.currentHoverColor;

    this.supplierRef.defaultColor = this.currentColor;
    this.supplierRef.activeColor = this.currentActiveColor;
    this.supplierRef.hoverColor = this.currentHoverColor;

    this.businessName.defaultColor = this.currentColor;
    this.businessName.activeColor = this.currentActiveColor;
    this.businessName.hoverColor = this.currentHoverColor;

    this.vatNumber.defaultColor = this.currentColor;
    this.vatNumber.activeColor = this.currentActiveColor;
    this.vatNumber.hoverColor = this.currentHoverColor;

    this.extendedPaymentTypeRef.isRequired = this.extendedPaymentTypeRef.isEnabled;
    this.supplierRef.isRequired = this.supplierRef.isEnabled;

    this.handleExtendedPaymentTypeRefValidation();

    this.handleOfflineLogics();
  }

  handleOfflineLogics() {
    this.onlineService.isOnline$.subscribe(() => {
      const isOnline = this.onlineService.isOnline;
      this.supplierRef.zoomSearchIsVisible = isOnline;

      this.supplierRef.zoomAddIsVisible = isOnline;

      this.supplierRef.zoomViewIsVisible = isOnline;
    })
  }

  async validateStep(): Promise<void> {

    this.extendedPaymentTypeRef.validate();
    this.supplierRef.validate();

    const validPaymentType = this.currentExpenseClassificationType !== ExpenseClassification.MileageRefound ? this.extendedPaymentTypeRef.id?.value != null && this.extendedPaymentTypeRef.id?.value > 0 : true;
    const validSupplier = this.supplierRef.isEnabled ? this.supplierRef.id?.value != null && this.supplierRef.id?.value > 0 : true;
    this.isValid.next(!this.hasErrors && validPaymentType && validSupplier)
  };

  override async postInit(): Promise<void> {

    this.supplierRef.isEnabled = this.reliableSupplier.value;
    this.businessName.isEnabled = !this.reliableSupplier.value;
    this.vatNumber.isEnabled = !this.reliableSupplier.value;
    this.businessName.canNotifyModified = false;
    this.vatNumber.canNotifyModified = false;

    this.supplierRef.isRequired = this.supplierRef.isEnabled;

    this.reliableSupplier.propertyChanged.subscribe((arg: PropertyViewModelPropertyChangedEventArgs) => {
      if (arg.propertyName === this.reliableSupplier.bindedValuePropertyName && this.isEnabled) {
        this.businessName.isEnabled = !this.reliableSupplier.value;
        this.vatNumber.isEnabled = !this.reliableSupplier.value;
        this.supplierRef.isEnabled = this.reliableSupplier.value;
        this.vatNumber.value = '';
        this.businessName.value = '';

        this.supplierRef.isRequired = this.supplierRef.isEnabled;

        this.handleSupplierRefValidation();
      }
    })

    merge(
      this.supplierRef.externalDomainModelChanged,
      this.supplierRef.decodeCompleted
    ).subscribe(() => {
      if (this.supplierRef.id?.value > 0 && this.supplierRef.isEnabled === true) {
        this.businessName.value = this.supplierRef.companyName?.value;
        this.vatNumber.value = this.supplierRef.vatNumber?.value;
      } else if (this.supplierRef.id?.value === null && this.supplierRef.isEnabled === true) {
        this.businessName.value = '';
        this.vatNumber.value = '';
      }
      this.handleSupplierRefValidation();
    })

    merge(
      this.extendedPaymentTypeRef.externalDomainModelChanged,
      this.extendedPaymentTypeRef.decodeCompleted
    ).subscribe(() => {
      this.handleExtendedPaymentTypeRefValidation();
    })

    this.handleExtendedPaymentTypeRefValidation();
    this.handleSupplierRefValidation();
  }

  handleExtendedPaymentTypeRefValidation() {
    if (this.extendedPaymentTypeRef.isEnabled && this.extendedPaymentTypeRef.id?.value == null) {
      this.validateStep();
    } else if (!this.extendedPaymentTypeRef.isEnabled) {
      this.validateStep();
    }
  }

  handleSupplierRefValidation() {
    if (this.supplierRef.isEnabled && (this.supplierRef.id?.value == 0 || this.supplierRef.id?.value == null)) {
      this.validateStep();
    } else if (!this.supplierRef.isEnabled) {
      this.supplierRef.reset();
      this.validateStep();
    } else if (this.supplierRef.isEnabled && this.supplierRef.id?.value > 0) {
      this.validateStep();
    }
  }

  newLabels(): BehaviorSubject<any[]> {
    return (this.parent as ReceiptParamsViewModel).newLabels$;
  }
}
