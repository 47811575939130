import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, Transform, DateTimeOffset, TimeSpan, CoreModel
} from '@nts/std';
import { PersonalExpenseIdentity } from '../../domain-models/personal-expense.identity';

@IdentityTypeDecorator(PersonalExpenseIdentity)
@ModelTypeNameDecorator('PersonalCompilationInstructions')
export class GeneratedPersonalCompilationInstructions extends CoreModel<PersonalExpenseIdentity> {

	private _personalExpenseId: number = 0;

	/**
	 Id spesa personale
	 */
	@Expose()
	public get personalExpenseId(): number {
		return this.getValueForIdentityValue<number>(() => this._personalExpenseId, 'personalExpenseId');
	}
	public set personalExpenseId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._personalExpenseId = value; }, value, 'personalExpenseId');
	}

	private _userId: number;

	/**
	 Id utente
	 */
	@Expose()
	public get userId(): number {
		return this.getValueForIdentityValue<number>(() => this._userId, 'userId');
	}
	public set userId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._userId = value; }, value, 'userId');
	}

	private _companyId: number = 0;

	/**

	 */
	@Expose()
	public get companyId(): number {
		return this.getValueForIdentityValue<number>(() => this._companyId, 'companyId');
	}
	public set companyId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._companyId = value; }, value, 'companyId');
	}

	private _instructions: string;

	/**
	 istruzioni di compilazione
	 */
	@Expose()
	public get instructions(): string {
		return this.getValue<string>(() => this._instructions, 'instructions');
	}
	public set instructions(value: string) {
		this.setValue<string>(() => { this._instructions = value; }, value, 'instructions');
	}
}
