import {
	InternalViewModel, StringPropertyViewModel, GuidPropertyViewModel, EnumPropertyViewModel, BoolPropertyViewModel, NumericPropertyViewModel, DateTimeOffsetPropertyViewModel, DateTimePropertyViewModel, 
	NNumericPropertyViewModel, NDateTimeOffsetPropertyViewModel, NDateTimePropertyViewModel, NEnumPropertyViewModel, NTimeSpanPropertyViewModel
} from '@nts/std';
import { PersonalCompilationInstructions } from '../../domain-models/personal-compilation-instructions';
import { PersonalExpenseIdentity } from '../../domain-models/personal-expense.identity';

export class GeneratedPersonalCompilationInstructionsViewModel extends InternalViewModel<PersonalCompilationInstructions, PersonalExpenseIdentity> {

	private _personalExpenseId: NumericPropertyViewModel;

	public get personalExpenseId(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._personalExpenseId = value; }, this._personalExpenseId, 'personalExpenseId');
	}

	private _instructions: StringPropertyViewModel;

	public get instructions(): StringPropertyViewModel {
		return this.getStringPropertyViewModel((value) => { this._instructions = value; }, this._instructions, 'instructions');
	}

}
