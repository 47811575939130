import { ExternalDomainModelTypeDecorator, ExternalDomainModelTypeNameDecorator, ExternalViewModel, Filter, FilterOperators, IdentityTypeDecorator, NumericPropertyViewModel, OrderBy, StringPropertyViewModel, Where, WhereOperators, ZoomAdvancedOptions } from "@nts/std";
import { Lead } from "src/app/external-remote/lead/lead";
import { LeadIdentity } from "src/app/external-remote/lead/lead.identity";
import { ReceiptLongOpOrchestratorViewModel } from "./receipt-long-op.orchestrator-view-model";
import { Observable } from "rxjs";

@IdentityTypeDecorator(LeadIdentity)
@ExternalDomainModelTypeDecorator(Lead)
@ExternalDomainModelTypeNameDecorator('Lead')
export class LeadExtViewModel extends ExternalViewModel<Lead, LeadIdentity> {

    // tslint:disable-next-line: variable-name
    private _id: NumericPropertyViewModel;

    get id(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
    }

    private _companyId: NumericPropertyViewModel;

    public get companyId(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._companyId = value; }, this._companyId, 'companyId');
    }

    // tslint:disable-next-line: variable-name
    private _companyName: StringPropertyViewModel;

    get companyName(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._companyName = value; }, this._companyName, 'companyName');
    }

    override async postInit(): Promise<void> {
        await super.postInit();

        this.searchProperties = ['Id', 'CompanyName'];
        this.decodeProperties = ['Id', 'CompanyName'];
    }

    override execAutocomplete(term: string, _languageCode?: string, showCodeInDescription?: boolean, basePlainPascalCaseFixedIdentity?: any, decodeProperties?: string[] | null, searchProperties?: string[] | null, orderByPropertyNames?: OrderBy[] | null, additionalFilters?: Filter[] | null, joinOperator?: string | undefined, additionalWheres?: Where[] | null): Observable<Array<{ description: string; identity: any; all: any; }>> {
        if (additionalWheres == null) {
            additionalWheres = [];
        }
        additionalWheres.push(...this.popolaFilters())
        return super.execAutocomplete(term, _languageCode, showCodeInDescription, basePlainPascalCaseFixedIdentity, decodeProperties, searchProperties, orderByPropertyNames, additionalFilters, joinOperator, additionalWheres)
    }



    popolaFilters(): Where[] {
        const date = (this.externalRetriever as ReceiptLongOpOrchestratorViewModel).rootViewModel.params.expenseData.expenseDate.value;

        if (date) {
            const open = new Where();
            open.operator = WhereOperators.OpenParenthesis
            open.position = 0;

            const startDate = new Where();
            startDate.filter.name = "StartDate";
            startDate.filter.operator = FilterOperators.LessOrEqualThan;
            startDate.filter.dateTimeOffSetValue = date;
            startDate.operator = WhereOperators.Filter;
            startDate.position = 1;

            const firstOr = new Where();
            firstOr.operator = WhereOperators.Or;
            firstOr.position = 2;

            const nullStartDate = new Where();
            nullStartDate.filter.name = "StartDate";
            nullStartDate.filter.operator = FilterOperators.IsNull;
            nullStartDate.operator = WhereOperators.Filter;
            nullStartDate.position = 3;

            const close = new Where();
            close.operator = WhereOperators.CloseParenthesis;
            close.position = 4;

            const and = new Where();
            and.operator = WhereOperators.And;
            and.position = 5;

            const reOpen = new Where();
            reOpen.operator = WhereOperators.OpenParenthesis;
            reOpen.position = 6;

            const endDate = new Where();
            endDate.filter.name = "EndDate";
            endDate.filter.operator = FilterOperators.GreaterOrEqualThan;
            endDate.filter.dateTimeOffSetValue = date;
            endDate.operator = WhereOperators.Filter;
            endDate.position = 7;

            const secondOr = new Where();
            secondOr.operator = WhereOperators.Or;
            secondOr.position = 8;


            const nullEndDate = new Where();
            nullEndDate.filter.name = "EndDate";
            nullEndDate.filter.operator = FilterOperators.IsNull;
            nullEndDate.operator = WhereOperators.Filter
            nullEndDate.position = 9;

            const reClose = new Where();
            reClose.operator = WhereOperators.CloseParenthesis;
            reClose.position = 10;



            return [open, startDate, firstOr, nullStartDate, close, and, reOpen, endDate, secondOr, nullEndDate, reClose];
        }
        return [];
    }
}
