import { HttpEventType } from "@angular/common/http";
import { EventEmitter } from "@angular/core";
import { Component, Input, ViewChild, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Output } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { BaseError, CollectionViewModelInterface, ComponentLocator, GenericServiceResponse, ModalService, NTSTranslatePipe, NumericPropertyViewModel, TextButtonComponent, ToastMessageService } from "@nts/std";
import { firstValueFrom, forkJoin, lastValueFrom, Observable, tap } from "rxjs";
import { UploadFileOutputDto } from "src/app/receipt-long-op/domain-models/dto/upload-file-output.dto";
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgFor, NgIf } from "@angular/common";
import { ProgressComponent } from "./progress/progress.component";
import { DndDirective } from "./dnd/dnd.directive";
import { ArraySortPropertyViewModelByPropertyPipe } from "./array-sort-property-view-model-by-property.pipe";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UploadFileSettingsViewModel } from "./modals/upload-file-settings/upload-file-settings.view-model";
import { UploadFileSettingsModalContainerComponent } from "./modals/upload-file-settings/upload-file-settings-modal-container.component";
import { Utils } from "./utils/utils";

export interface PropertyViewModelRowNumberInterface {
  rowNumber: NumericPropertyViewModel;
}

export interface FileUploadInterface {
  fileUploadOriginalFileName: string;
  fileUploadStorageName?: string;
  fileUploadFileSize?: number;
  fileObject?: File
  filePreviewUrl: SafeResourceUrl;
  fileErrors: BaseError[]
}
export interface FileUploadStatusInterface extends FileUploadInterface {
  progress?: number;
}

export interface FileCollectionViewModelInterface extends CollectionViewModelInterface<FileUploadStatusInterface & PropertyViewModelRowNumberInterface> {
}

@UntilDestroy()
@Component({
  selector: 'nts-multiple-file-upload',
  templateUrl: './multiple-file-upload.component.html',
  styleUrls: ['./multiple-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SvgIconComponent,
    NgIf,
    TextButtonComponent,
    ProgressComponent,
    NgFor,
    NTSTranslatePipe,
    DndDirective,
    ArraySortPropertyViewModelByPropertyPipe,
  ]
})
export class MultipleFileUploadComponent implements OnChanges, OnInit {

  /**
   * Utils class exposed to the template
   */
  Utils = Utils;

  @Input() editable = true;
  @Input() files: FileCollectionViewModelInterface; // In ingresso passo i file già caricati
  @Input() isRemoteUploadEnabled = false
  @Input() moveUpCallBack: (itemIndex: number) => Promise<void> = null;
  @Input() moveDownCallBack: (itemIndex: number) => Promise<void> = null;
  @Input() uploadCallBack: (item: FileUploadStatusInterface, file: File) => Observable<GenericServiceResponse<UploadFileOutputDto>> = null;
  @Input() viewFileCallBack: (item: FileUploadStatusInterface) => Observable<any> = null;
  @Input() addCallBack: () => Promise<FileUploadStatusInterface> = null;
  @Input() removeCallBack: (itemIndex: number) => Promise<void> = null;

  @Output() filesChanged: EventEmitter<void> = new EventEmitter<void>()
  @ViewChild('fileDropRef') fileDropRef;

  isHover = false;
  isActive = false;
  overrideBorderColor = null;

  constructor(
    private sanitizer: DomSanitizer,
    private readonly cd: ChangeDetectorRef,
    private readonly toastMessageService: ToastMessageService,
    private readonly modalService: ModalService,
    private readonly componentLocator: ComponentLocator
  ) {
    this.componentLocator.registerViewModelComponentAssociation(UploadFileSettingsViewModel, UploadFileSettingsModalContainerComponent);
  }

  ngOnInit(): void {
    this.handleOverridesColors();
  }

  mouseout(e) {
    this.isHover = false;
    this.handleOverridesColors();
  }

  onBlur(e) {
    this.isActive = false;
    this.handleOverridesColors();
  }

  onFocus(e) {
    this.isActive = true;
    this.handleOverridesColors();
  }

  mouseover(e) {
    this.isHover = true;
    this.handleOverridesColors();
  }

  handleOverridesColors() {
    if (!this.files.defaultColor || !this.files.activeColor || !this.files.hoverColor) {
      // devono essere impostate tutte e tre le variabili
      return;
    }

    this.overrideBorderColor = this.files.defaultColor;
    if (this.isActive) {
      this.overrideBorderColor = this.files.activeColor;
    }
    if (this.isHover) {
      this.overrideBorderColor = this.files.hoverColor;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Imposto il progress 100% nei file fià caricati
    if (changes['files']?.currentValue) {
      const arr: FileUploadStatusInterface[] = changes['files']?.currentValue;
      for (const singleFile of arr) {
        singleFile.progress = 100;

        // if (Utils.isFileImage(singleFile?.fileObject)) {
        //   const previewReader: FileReader = new FileReader();
        //   previewReader.onloadend = (e) => {
        //     singleFile.filePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result as string);
        //     singleFile.fileUploadOriginalFileName = singleFile.fileUploadOriginalFileName;
        //     this.cd.markForCheck();
        //   }
        //   previewReader.readAsDataURL(singleFile.fileObject);
        // }
      }
      this.files.collectionChanged.pipe(untilDestroyed(this)).subscribe(() => this.cd.detectChanges());
    }
  }

  onFileDropped(e: FileList) {
    if (this.editable) {
      this.uploadFiles(e);
    }
  }

  /**
   * Upload a file
   *
   * @param item
   * @param file
   * @returns
   */
  async uploadFile(item: FileUploadStatusInterface, file: File) {
    this.filesChanged.emit();
    return lastValueFrom(
      this.uploadCallBack(item, file).pipe(
        tap((httpEvent: any) => {
          if (httpEvent?.type === HttpEventType.UploadProgress) {
            item.progress = Math.round(100 * (httpEvent.loaded / httpEvent.total) * 0.75);
            this.cd.markForCheck();
          } else if (httpEvent?.result !== undefined && httpEvent?.errors !== undefined) {
            item.progress = 100;
            item.fileUploadStorageName = httpEvent.result?.storageFileName;
            item.fileErrors = httpEvent.errors;

            if (Utils.isFileImage(item?.fileObject)) {
              const previewReader: FileReader = new FileReader();
              previewReader.onloadend = (e) => {
                item.filePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result as string);
                this.cd.markForCheck();
              };
              previewReader.readAsDataURL(item.fileObject);
            }

            this.filesChanged.emit();
            this.cd.markForCheck();
          } else if (httpEvent?.type === HttpEventType.Sent) {
          } else if (httpEvent?.type === HttpEventType.Sent) {
            item.progress = 25;
            this.cd.markForCheck();
          }
        })
      )
    );
  }

  /**
   * Handle image upload
   *
   * @param file
   * @param isRemote
   * @returns
   */
  async handleImageUpload(file: File, isRemote: boolean) {
    const uploadFileSettingsViewModel = new UploadFileSettingsViewModel(file);
    const panelClass = ['upload-file-settings-modal'];

    if (window !== window.top || window.location.search.includes('iframe=true')) {
      panelClass.push('iframe');
    }

    const result = await this.modalService.showCustomModalWithResultAsync<string>(
      uploadFileSettingsViewModel,
      false,
      false,
      false,
      {
        disableClose: true,
        panelClass
      }
    );

    if (result.cancel === false) {
      let optimizedBlob: Blob;
      try {
        const response = await fetch(result.result);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        optimizedBlob = await response.blob();
      } catch (error) {
        console.error('Fetch error:', error);
        return;
      }
      const fileObject = new File([optimizedBlob], file.name, { type: optimizedBlob.type });
      const item = await this.addCallBack();
      item.fileObject = fileObject;
      item.fileUploadFileSize = item.fileObject.size;
      item.fileUploadOriginalFileName = item.fileObject.name;
      this.cd.markForCheck();

      if (isRemote) {
        await this.uploadFile(item, item.fileObject);
      } else {
        await this.simulateUpload(item, false);
      }
    }

    // reset the input value
    this.fileDropRef.nativeElement.value = '';
  }

  /**
   * Upload files
   *
   * @param files
   */
  async uploadFiles(files: FileList) {
    if (this.isRemoteUploadEnabled) {
      if (files.length === 1 && files[0].type.startsWith('image')) {
        await this.handleImageUpload(files[0], true);
      } else {
        const tasks$ = Array.from(files).map(async (file) => {
          const item = await this.addCallBack();
          item.fileObject = file;
          item.fileUploadFileSize = file.size;
          item.fileUploadOriginalFileName = item.fileObject.name;
          return this.uploadFile(item, file);
        });
        await firstValueFrom(forkJoin(tasks$));
      }
    } else {
      if (files.length === 1 && files[0].type.startsWith('image')) {
        await this.handleImageUpload(files[0], false);
      } else {
        for (const singleFile of Array.from(files)) {
          const item = await this.addCallBack();
          item.fileObject = singleFile;
          item.fileUploadFileSize = item.fileObject.size;
          item.fileUploadOriginalFileName = item.fileObject.name;
          this.cd.markForCheck();
          await this.simulateUpload(item, false);
        }
      }
    }

    this.cd.detectChanges();
  }

  /**
   * Simulate the upload of a file
   *
   * @param item
   * @param instant
   * @returns
   */
  simulateUpload(item: FileUploadStatusInterface, instant: boolean): Promise<void> {
    return new Promise((resolve, reject) => {

      item.progress = 0;
      setTimeout(() => {
        const progressInterval = setInterval(() => {
          if (item.progress === 100) {
            clearInterval(progressInterval);

            if (Utils.isFileImage(item?.fileObject)) {
              const previewReader: FileReader = new FileReader();
              previewReader.onloadend = (e) => {
                item.filePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result as string);
                this.cd.markForCheck();
              }
              previewReader.readAsDataURL(item.fileObject);
            }

            resolve();

          } else {
            item.progress = Math.min(item.progress + 5, 100);
          }
          this.cd.markForCheck();
        }, instant ? 0 : 20);
        this.cd.markForCheck();
      }, instant ? 0 : 500);
    });
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(e) {
    this.uploadFiles(e.target.files);
  }

  viewFile(e) {
    this.viewFileCallBack(e)
  }

  async retryUpload(file: FileUploadStatusInterface) {
    file.fileErrors = []
    await this.uploadFile(file, file.fileObject)
  }

  getKeyFromFile(item: FileUploadStatusInterface) {
    if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('avi')) {
      return 'avi-format'
    }
    if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('gif')) {
      return 'gif-format'
    }
    if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('jpeg') ||
      item?.fileUploadOriginalFileName?.toLowerCase().endsWith('jpg')) {
      return 'jpeg-format'
    }
    if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('svg')) {
      return 'svg-format'
    }
    if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('zip')) {
      return 'compress'
    }
    if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('mpeg')) {
      return 'mpeg-format'
    }
    if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('png')) {
      return 'png-format'
    }
    if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('raw')) {
      return 'raw-format'
    }
    if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('tif')) {
      return 'tif-format'
    }
    if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('tiff')) {
      return 'tiff-format'
    }
    if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('webp')) {
      return 'webp-format'
    }
    if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('mkv')) {
      return 'media-video'
    }
    return 'journal';
  }

  async moveUpFile(fileIndex: number) {
    await this.moveUpCallBack(fileIndex);
  }

  async moveDownFile(fileIndex: number) {
    await this.moveDownCallBack(fileIndex);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  async deleteFile(index: number) {
    await this.removeCallBack(index);
    this.fileDropRef.nativeElement.value = '';
    // this.onFilesChanged.emit(this.files);
    this.filesChanged.emit()
  }

}
