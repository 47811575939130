import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Sanitizer } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActivatedRoute } from '@angular/router';
import { ViewModelAwareInterface } from 'src/app/shared/models/view-model-aware.interface';
import { RouteStateService } from 'src/app/shared/services/route-state.service';
import { ExpenseDataViewModel } from 'src/app/receipt-long-op/view-models/expense-data.view-model';
import { ReceiptStepsService } from 'src/app/receipt-long-op/services/receipt-steps.service';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { DecodeTextBoxComponent, EnterpriseDataDto, ExpandableComponent, ExternalListComponent, LabelBoxComponent, LabelledTextBoxComponent, MessageResourceManager, ModalService, NTSTranslatePipe, ServiceResponse, ToastMessage, ToastMessageType } from '@nts/std';
import { ReceiptLongOpApiClient } from 'src/app/receipt-long-op/api-clients/receipt-long-op.api-client';
import { ExpenseModel } from 'src/app/expense-model/domain-models/expense-model';
import getSymbolFromCurrency from 'currency-symbol-map'
import { ReceiptLongOpOrchestratorViewModel } from 'src/app/receipt-long-op/view-models/receipt-long-op.orchestrator-view-model';
import { LogService, OnlineService } from '@nts/std/src/lib/utility';
import { AuthService } from '@nts/std';
import { TelemetryService } from '@nts/std/src/lib/telemetry';
import { CreateExpenseAnnotationLongOpApiClient } from 'src/app/create-expense-annotation-long-op/api-clients/create-expense-annotation-long-op.api-client';
import { WeaSettingsDataDto } from 'src/app/create-expense-annotation-long-op/api-clients/dto/wea-settings-data.dto';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf } from '@angular/common';
import { MultipleFileUploadComponent } from 'src/app/shared/components/multiple-file-upload/multiple-file-upload.component';
import { FullscreenPreviewComponent } from 'src/app/shared/components/fullscreen-preview/fullscreen-preview.component';
import { ExpenseFileCollectionItemViewModel } from 'src/app/receipt-long-op/view-models/expense-file.collection-item-view-model';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { ExpenseFileCollectionViewModel } from 'src/app/receipt-long-op/view-models/expense-file.collection-view-model';
import { ExpenseState } from 'src/app/expense-annotation/generated/domain-models/enums/generated-expense-state';
import { BaseStepComponent } from '../base-step.component';


@UntilDestroy()
@Component({
  selector: 'nts-expense-data-step',
  templateUrl: './expense-data-step.component.html',
  styleUrls: ['./expense-data-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NTSTranslatePipe,
    ExpandableComponent,
    SvgIconComponent,
    LabelledTextBoxComponent,
    NgIf,
    ExternalListComponent,
    DecodeTextBoxComponent,
    LabelBoxComponent,
    MultipleFileUploadComponent,
    FullscreenPreviewComponent
  ]
})
export class ExpenseDataStepComponent extends BaseStepComponent<ExpenseDataViewModel> {

  orchestratorViewModel: ReceiptLongOpOrchestratorViewModel;
  files$ = new BehaviorSubject<ExpenseFileCollectionViewModel>(new ExpenseFileCollectionViewModel());

  untilDestroyed(): <U>(source: Observable<U>) => Observable<U> {
    return untilDestroyed(this);
  }

  async onViewModelLoaded() {

    this.receiptStepsService.stepLoading.next(true);
    this.orchestratorViewModel = this.receiptStepsService.orchestratorViewModel;

    const viewModel = this.receiptStepsService.getCurrentState() as ExpenseDataViewModel;

    const rootViewModel = this.receiptStepsService.orchestratorViewModel.rootViewModel;
    const selectedModelCode = rootViewModel.params.expenseModelData.expenseModelRef?.modelCode?.value;

    const tenantId: number = await this.authService.getTenantId();
    const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);

    // se non ho popolato gli availableModels li popolo
    if (rootViewModel.params.expenseModelData.availableModels == null) {
      const availableModelsResponse = await firstValueFrom(this.receiptLongOpApiClient.getAvailableModels(undefined, enterpriseData.companyId))
      if (availableModelsResponse.operationSuccedeed === true) {
        rootViewModel.params.expenseModelData.availableModels = availableModelsResponse.result || [];
      } else {
        rootViewModel.params.expenseModelData.availableModels = [];
      }
    }

    let fullExpenseModel: ExpenseModel = null;

    if (rootViewModel.params.expenseModelData.availableModels.length > 0) {
      fullExpenseModel = rootViewModel.params.expenseModelData.availableModels.find((e: ExpenseModel) => e.modelCode === selectedModelCode)
    }

    const queryParams = await firstValueFrom(this.routeStateService.queryParams);
    const expenseAnnotationId = parseInt(queryParams['expenseAnnotationId'], 10);
    const remoteExpense = queryParams['remoteExpense'] === 'true';

    let currencySymbol = null;

    const isoCode = queryParams['isoCode'];
    if (isoCode?.length > 0) {
      currencySymbol = getSymbolFromCurrency(isoCode)
    } else {
      const currencyName = queryParams['currencyName'];
      currencySymbol = currencyName;
    }

    let configuration: WeaSettingsDataDto = null;
    const configurationResponse = await firstValueFrom(this.createExpenseAnnotationLongOpApiClient.getWEASettingsData());
    if (configurationResponse && configurationResponse.operationSuccedeed === true) {
      configuration = configurationResponse.result;
    }

    const currentExpenseClassification = rootViewModel.params.expenseSelection.expenseClassification.value;
    const extendedAvailableExpenses = await this.receiptStepsService.orchestratorViewModel.getExtendedAvailableExpenses(
      selectedModelCode,
      currentExpenseClassification,
      rootViewModel?.params?.userRef?.getDomainModel()?.currentIdentity
    );

    const customerIdentity = rootViewModel.params.getDomainModel().customerIdentity;
    const leadIdentity = rootViewModel.params.getDomainModel().leadIdentity;
    const commissionIdentity = rootViewModel.params.getDomainModel().commissionIdentity;

    await viewModel.initPresentationLogic(
      rootViewModel.params.expenseSelection.expenseClassification.value,
      fullExpenseModel,
      extendedAvailableExpenses,
      expenseAnnotationId,
      remoteExpense,
      currencySymbol,
      rootViewModel.params.expenseState,
      configuration,
      customerIdentity,
      leadIdentity,
      commissionIdentity,
      this.onlineService,
      this.modalService,
      !(await this.isReadOnly(rootViewModel))
    );

    this.receiptStepsService.orchestratorViewModel.rootViewModelModified.pipe(untilDestroyed(this)).subscribe(() => {
      viewModel.validateStep();
    });

    await viewModel.validateStep();

    const params = await firstValueFrom(this.routeStateService.params);
    const currentAction = params.get('action');

    if (currentAction === 'add' && viewModel.isValid.value === true) {
      if (!this.receiptStepsService.orchestratorViewModel.errorDuringAdd) {
        this.receiptStepsService.nextStep();
      } else {
        this.receiptStepsService.stepLoading.next(false);
      }
    } else if (currentAction === 'add' && viewModel.isValid.value === false) {
      const toastMessage: ToastMessage = {
        message: MessageResourceManager.Current.getMessage('MissinigDataInStep'),
        type: ToastMessageType.warn,
        title: MessageResourceManager.Current.getMessage('std_Warning')
      };
      this.receiptStepsService.orchestratorViewModel.toastMessageService.showToast(toastMessage);
      this.receiptStepsService.orchestratorViewModel.errorDuringAdd = true;
      this.receiptStepsService.stepLoading.next(false);
    } else {
      this.receiptStepsService.stepLoading.next(false);
    }
    this.viewModel = viewModel;

    this.inIframe = this.activatedRoute.snapshot.queryParams['iframe'] === 'true' || window.self !== window.top;

    const customTitle = viewModel.newLabels().value[viewModel.currentExpenseClassificationType];
    const title = customTitle ?? MessageResourceManager.Current.getMessage('ExpenseClassification_' + viewModel.expenseClassificationEnum[viewModel.currentExpenseClassificationType]);
    this.telemetryService.trackPageView({ name: title });

    this.cd.detectChanges();
  };

  fileUploadValidation() {
    this.viewModel.validateStep();
    this.cd.detectChanges()
  }

  async viewExpenseFile(item: ExpenseFileCollectionItemViewModel): Promise<void> {

    const orchestrator = this.receiptStepsService.orchestratorViewModel;
    if (item.filePreviewUrl != null) {

      this.viewModel.openFileFullScreen(
        item.filePreviewUrl,
        item.fileObject ?? item.fileBlob,
        item.originalFileName.value,
        item
      )
      this.cd.detectChanges();
    } else {

      // Gestisco il download quando il file è remoto (se remoto, l'identity del file è valorizzata)
      if (item.domainModel?.attachmentIdentity != null && item.domainModel?.attachmentIdentity != undefined) {
        const identity = item.domainModel.attachmentIdentity;

        orchestrator.apiClient.downloadFile(identity).subscribe({
          next: async (httpEvent: HttpEvent<Blob>) => {

            if (httpEvent) {
              if (httpEvent.type === HttpEventType.DownloadProgress) {
                // this.progressBarValue = Math.round(100 * httpEvent.loaded / httpEvent.total);
                // this.cd.detectChanges(); //TODO
              } else if (httpEvent.type === HttpEventType.Response) {
                // download file
                const blob = httpEvent.body;
                let extension = item.originalFileName.value.split(".").pop()?.toLowerCase();
                switch (extension) {
                  case 'png':
                  case 'jpg':
                  case 'gif':
                  case 'bmp':
                  case 'jpeg':
                    const currentImageModalSrc = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
                    this.viewModel.openFileFullScreen(
                      currentImageModalSrc,
                      blob,
                      item.originalFileName.value,
                      item
                    );

                    this.cd.detectChanges();
                    break;
                  default:
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = item.originalFileName.value;
                    link.click();
                }
              } else if (httpEvent instanceof ServiceResponse) {
                orchestrator.toastMessageService.showToastsFromResponse(httpEvent);
              }
            }
          },
          error: (err) => {
            LogService.warn('ERRORE downloadFile', err)
          }
        });
      } else {

        if (item?.fileObject?.type?.startsWith('image') && item?.filePreviewUrl != null) {

          this.viewModel.openFileFullScreen(
            item.filePreviewUrl,
            item?.fileObject,
            item.originalFileName.value,
            item
          )

        } else {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(item.fileObject);
          link.download = item.originalFileName.value;
          link.click();
        }
      }
    }
  }
}

