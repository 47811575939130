
import {
    IdentityTypeDecorator, ExternalDomainModelTypeDecorator,
    ExternalDomainModelTypeNameDecorator, StringPropertyViewModel, OrderByType, OrderBy, NumericPropertyViewModel, AutoCompleteExternalOptions, CustomPropertyViewModelDecorator, StringDecorator, PropertyViewModelFactory, StringMetaData
} from '@nts/std';
import { ExternalViewModel } from '@nts/std';
import { UserOfTenantExtended } from 'src/app/external-remote/user-of-tenant/user-of-tenant-extended';
import { UserOfTenantIdentity } from 'src/app/external-remote/user-of-tenant/user-of-tenant.identity';

@IdentityTypeDecorator(UserOfTenantIdentity)
@ExternalDomainModelTypeDecorator(UserOfTenantExtended)
@ExternalDomainModelTypeNameDecorator('UserOfTenantExtended')
export class UserOfTenantExtendedExtViewModel extends ExternalViewModel<UserOfTenantExtended, UserOfTenantIdentity> {

    get id(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
    }

    get name(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._name = value; }, this._name, 'name');
    }

    get lastName(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._lastName = value; }, this._lastName, 'lastName');
    }

    private _customUsername: StringPropertyViewModel;

    @CustomPropertyViewModelDecorator()
    @StringDecorator({ displayNameKey: 'customUsername' })
    public get customUsername(): StringPropertyViewModel {
        if (this._customUsername == null) {
            this._customUsername = this.getStringPropertyViewModel((value) => { this._customUsername = value; }, this._customUsername, 'customUsername', () => this.composeUsername());
        }
        return this._customUsername;
    }

    private _id: NumericPropertyViewModel;
    private _name: StringPropertyViewModel;
    private _lastName: StringPropertyViewModel;

    override async postInit(): Promise<void> {
        this.autocomplete = true;
        this.searchProperties = ['Id', 'Name', 'LastName'];
        this.orderByPropertyNames = [
            new OrderBy({ propertyName: 'Id', sortType: OrderByType.Ascending })
        ];
        this.additionalOutputProperties = ['Id', 'Name', 'LastName'];
        this.decodeProperties = ['Id', 'Name', 'LastName'];
        this.joinOperator = " "

        this.zoomAddIsEnabled = false;
        this.zoomAddIsVisible = false;

        this.zoomSearchIsEnabled = true;
        this.zoomSearchIsVisible = true;

        this.zoomViewIsEnabled = false;
        this.zoomViewIsVisible = false;
    }

    private composeUsername = (): string => {
        var name = this.name.value != null ? this.name.value : "";
        var lastName = this.lastName.value != null ? this.lastName.value : "";
        var composedName = `${name} ${lastName}`
        return composedName.trim().length > 0 ? composedName : (this.id.value?.toString().length > 0 ? this.id.value?.toString() : '');
    }
}

