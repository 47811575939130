
import {
    IdentityTypeDecorator, OCCModel, ModelTypeNameDecorator, Expose, AllowedChars, NumberDecorator,
    StringDecorator, EnumDecorator, DateTimeOffset, Transform, Type
} from '@nts/std';
import { Customer } from '../customer/customer';
import { GeneralSubjectIdentity } from '../general-subject/general-subject.identity';
import { CommissionIdentity } from './commission.identity';


@IdentityTypeDecorator(CommissionIdentity)
@ModelTypeNameDecorator('Commission')
export class Commission extends OCCModel<CommissionIdentity> {

    // tslint:disable-next-line: variable-name
    private _id: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'Commission_Id' })
    get id(): number {
        return this.getValueForIdentityValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
    }

    private _companyId: number = 0;

    /**

     */
    @Expose()
    public get companyId(): number {
        return this.getValueForIdentityValue<number>(() => this._companyId, 'companyId');
    }
    public set companyId(value: number) {
        this.setValueForIdentityValue<number>(() => { this._companyId = value; }, value, 'companyId');
    }


    // tslint:disable-next-line: variable-name
    private _description: string;

    @Expose()
    get description(): string {
        return this.getValue<string>(() => this._description, 'description');
    }
    set description(value: string) {
        this.setValue<string>(() => { this._description = value; }, value, 'description');
    }

    private _commissionCreationDate: DateTimeOffset;

    /**
     Data inizio
     */
    @Expose()
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('commissionCreationDate', value, obj, type);
    })
    public get commissionCreationDate(): DateTimeOffset {
        return this.getValue<DateTimeOffset>(() => this._commissionCreationDate, 'commissionCreationDate');
    }
    public set commissionCreationDate(value: DateTimeOffset) {
        this.setValue<DateTimeOffset>(() => { this._commissionCreationDate = value; }, value, 'commissionCreationDate');
    }

    private _expirationDate: DateTimeOffset;

    /**
     Data inizio
     */
    @Expose()
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('expirationDate', value, obj, type);
    })
    public get expirationDate(): DateTimeOffset {
        return this.getValue<DateTimeOffset>(() => this._expirationDate, 'expirationDate');
    }
    public set expirationDate(value: DateTimeOffset) {
        this.setValue<DateTimeOffset>(() => { this._expirationDate = value; }, value, 'expirationDate');
    }

    private _closureDate: DateTimeOffset;

    /**
     Data inizio
     */
    @Expose()
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('closureDate', value, obj, type);
    })
    public get closureDate(): DateTimeOffset {
        return this.getValue<DateTimeOffset>(() => this._closureDate, 'closureDate');
    }
    public set closureDate(value: DateTimeOffset) {
        this.setValue<DateTimeOffset>(() => { this._closureDate = value; }, value, 'closureDate');
    }

    private _customerId: number;

    /**
     Id cliente
     */
    @Expose()
    public get customerId(): number {
        return this.getValue<number>(() => this._customerId, 'customerId');
    }
    public set customerId(value: number) {
        this.setValue<number>(() => { this._customerId = value; }, value, 'customerId');
    }

    private _customerCompanyId: number;

    /**
     Id cliente
     */
    @Expose()
    public get customerCompanyId(): number {
        return this.getValue<number>(() => this._customerCompanyId, 'customerCompanyId');
    }
    public set customerCompanyId(value: number) {
        this.setValue<number>(() => { this._customerCompanyId = value; }, value, 'customerCompanyId');
    }

    private _customerRef: Customer;

    /**
     Cliente
     */
    @Expose()
    @Type(() => Customer)
    public get customerRef(): Customer {
        return this.getValueForExternal<Customer>(
            () => this._customerRef,
            'customerRef',
            Customer
        );
    }
    public set customerRef(value: Customer) {
        this.setValueForExternal<Customer, GeneralSubjectIdentity>(() => { this._customerRef = value; }, value, 'customerRef');
    }

    private _isClosed: boolean;

    /**

     */
    @Expose()
    public get isClosed(): boolean {
        return this.getValue<boolean>(() => this._isClosed, 'isClosed');
    }
    public set isClosed(value: boolean) {
        this.setValue<boolean>(() => { this._isClosed = value; }, value, 'isClosed');
    }

}
