import { CommandFactory, FilledButtonType, MessageResourceManager, ModalViewModelInterface, UIResultCommandInterface, ViewModelTypeDecorator } from "@nts/std";
import { ChangeDetectorRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { DataUrl } from "ngx-image-compress";
import { BehaviorSubject, map } from "rxjs";
import { CropperPosition, OutputFormat } from "ngx-image-cropper";

@ViewModelTypeDecorator(UploadFileSettingsViewModel)
export class UploadFileSettingsViewModel implements ModalViewModelInterface<any, any> {

  /**
   * The toggle has just been used to switch between optimized and original image or vice versa
   */
  justToggleOptimized = false;

  /**
   * The cropped image url
   */
  croppedImageUrl: DataUrl;

  /**
   * Is the image being optimized?
   */
  optimizeImageSize = false;

  /**
   * The optimized image url
   */
  optimizedImageUrl: DataUrl;

  /**
   * The original image url
   */
  originalImageUrl: DataUrl;

  /**
   * The image url used in the cropper component
   */
  imageUrl: DataUrl;

  /**
   * The current output format of cropped image
   */
  currentOutputFormat: OutputFormat;

  /**
   * The dom sanitizer
   */
  sanitizer: DomSanitizer;

  /**
   * The change detector
   */
  cd: ChangeDetectorRef;

  /**
   * The filled button type
   */
  filledButtonType = FilledButtonType;

  /**
   * The modal title
   */
  modalTitle = MessageResourceManager.Current.getMessage('ExpenseData_UploadFileSettings_Title');

  /**
   * The modal subtitle
   */
  modalSubtitle = '';

  /**
   * The modal commands
   */
  modalCommands = [];

  /**
   * The show footer flag
   */
  showFooter = true;

  /**
   * The fixed width of the wrapper
   */
  fixedWidth = null;

  /**
   * The fixed height of the wrapper
   */
  fixedHeight = null;

  /**
   * The loading flag
   */
  isLoading$ = new BehaviorSubject<boolean>(true);

  /**
   * The optimize image error
   */
  optimizeImageError$ = new BehaviorSubject<string>('');

  /**
   * The cropper position
   */
  cropperPosition?: CropperPosition = { x1: 0, y1: 0, x2: 0, y2: 0 };

  /**
   * The last cropper position
   */
  lastCropperPosition: CropperPosition = null;

  /**
   * The cropped image size in human readable format
   */
  croppedImageSize$ = new BehaviorSubject<string>('0');

  /**
   * The loading text
   */
  loadingText$ = new BehaviorSubject<string>(MessageResourceManager.Current.getMessage('ExpenseData_UploadFileSettings_Loading'));

  /**
   * The confirm command
   */
  private confirmCommand: UIResultCommandInterface<string, any>;

  /**
   * @param file The file to upload
   */
  constructor(
    public file: File,
  ) {
    this.setupCommands();
  }

  /**
   * Setup the commands
   */
  private setupCommands() {
    this.confirmCommand = CommandFactory.createResultUICommand<string>(async () => this.croppedImageUrl, () => this.isLoading$.pipe(map((isLoading) => !isLoading)));
    this.confirmCommand.displayName = MessageResourceManager.Current.getMessage("ExpenseData_UploadFileSettings_Confirm_DisplayName");
    this.confirmCommand.closeModal = true;
    this.confirmCommand.isCancel = false;
    this.confirmCommand.isDefault = true;
    this.modalCommands.push(this.confirmCommand);
  }

  /**
   * Initialize the view model
   *
   * @param param0
   */
  async initialize({ sanitizer, cd }): Promise<void> {
    this.sanitizer = sanitizer
    this.cd = cd;
  }
}
