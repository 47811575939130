import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { NTSTranslatePipe } from "@nts/std";
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'nts-fullscreen-preview',
  templateUrl: './fullscreen-preview.component.html',
  styleUrls: ['./fullscreen-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NTSTranslatePipe,
    NgIf,
    SvgIconComponent
  ]
})
export class FullscreenPreviewComponent implements OnChanges {

  @Input() imageSrc: SafeResourceUrl;
  @Input() imageBlob: Blob = null;
  @Input() imageName: string = null;
  @Input() show: boolean;
  @Input() inIframe: boolean = true;
  @Input() showDownloadButton: boolean = true;
  @Input() showNextPreviousButtons: boolean = false;

  @Output() imageSrcChange: EventEmitter<SafeResourceUrl> = new EventEmitter<SafeResourceUrl>();
  @Output() imageBlobChange: EventEmitter<Blob> = new EventEmitter<Blob>();
  @Output() imageNameChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() askNextImage: EventEmitter<void> = new EventEmitter<void>();
  @Output() askPreviousImage: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('imageModalPreview') imageModalPreview: ElementRef<HTMLDivElement>;

  constructor(
    private cd: ChangeDetectorRef,
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageSrc']) {
      if (changes['imageSrc'].currentValue != null) {
        setTimeout(() => {
          this.imageModalPreview.nativeElement.focus();
        }, 0);
      }
      this.cd.detectChanges();
    }
  }

  closeFullScreen() {
    this.imageSrc = null;
    this.imageBlob = null;
    this.imageName = null;
    this.imageSrcChange.next(null);
    this.imageBlobChange.next(null);
    this.imageNameChange.next(null);
    this.cd.detectChanges();
  }

  downloadFileFromFullscreen() {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(this.imageBlob);
    link.download = this.imageName;
    link.click();
  }
}
