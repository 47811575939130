import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ExpenseModelDataViewModel } from 'src/app/receipt-long-op/view-models/expense-model-data.view-model';
import { firstValueFrom, Observable } from 'rxjs';
import { EnterpriseDataDto, MessageResourceManager, NTSTranslatePipe, ToastMessage, ToastMessageType } from '@nts/std';
import { NgFor, NgIf } from '@angular/common';
import { ModelBoxComponent } from 'src/app/shared/components/model-box/model-box.component';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { BaseStepComponent } from '../base-step.component';

@UntilDestroy()
@Component({
  selector: 'nts-expense-model-data-step',
  templateUrl: './expense-model-data-step.component.html',
  styleUrls: ['./expense-model-data-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NTSTranslatePipe,
    NgIf,
    ModelBoxComponent,
    SvgIconComponent,
    NgFor
  ]
})
export class ExpenseModelDataStepComponent extends BaseStepComponent<ExpenseModelDataViewModel> {

  untilDestroyed(): <U>(source: Observable<U>) => Observable<U> {
    return untilDestroyed(this);
  }

  async onViewModelLoaded() {

    this.receiptStepsService.stepLoading.next(true);

    const queryParams = await firstValueFrom(this.routeStateService.queryParams);
    const expenseModelId = parseInt(queryParams['expenseModelId'], 10);

    const viewModel = this.receiptStepsService.getCurrentState() as ExpenseModelDataViewModel;

    viewModel.availableModels = [];

    const tenantId: number = await this.authService.getTenantId();
    const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);

    const availableModelsResponse = await firstValueFrom(this.receiptLongOpApiClient.getAvailableModels(undefined, enterpriseData.companyId))
    if (availableModelsResponse.operationSuccedeed === true) {
      viewModel.availableModels = availableModelsResponse.result || [];
    }

    const modelList = await firstValueFrom(viewModel.expenseModelRef.execExternalList(undefined, false));
    const rootViewModel = this.receiptStepsService.orchestratorViewModel.rootViewModel;

    const expenseAnnotationId = parseInt(queryParams['expenseAnnotationId'], 10);
    const remoteExpense = queryParams['remoteExpense'] === 'true';

    await viewModel.initPresentationLogic(
      expenseAnnotationId,
      remoteExpense,
      modelList,
      expenseModelId,
      rootViewModel.params.expenseState,
      !(await this.isReadOnly(rootViewModel))
    )

    this.receiptStepsService.orchestratorViewModel.rootViewModelModified.pipe(untilDestroyed(this)).subscribe(() => {
      viewModel.validateStep();
    });

    await viewModel.validateStep();

    this.inIframe = this.activatedRoute.snapshot.queryParams['iframe'] === 'true' || window.self !== window.top;

    const params = await firstValueFrom(this.routeStateService.params);
    const currentAction = params.get('action');

    if (expenseModelId > 0 && viewModel.expenseModelRef.modelCode.value > 0) {
      this.receiptStepsService.nextStep();
    } else if (currentAction === 'add' && viewModel.isValid.value === true) {
      if (!this.receiptStepsService.orchestratorViewModel.errorDuringAdd) {
        this.receiptStepsService.nextStep();
      } else {
        this.receiptStepsService.stepLoading.next(false);
      }
    } else if (currentAction === 'add' && viewModel.isValid.value === false) {
      const toastMessage: ToastMessage = {
        message: MessageResourceManager.Current.getMessage('MissinigDataInStep'),
        type: ToastMessageType.warn,
        title: MessageResourceManager.Current.getMessage('std_Warning')
      };
      this.receiptStepsService.orchestratorViewModel.toastMessageService.showToast(toastMessage);
      this.receiptStepsService.orchestratorViewModel.errorDuringAdd = true;
      this.receiptStepsService.stepLoading.next(false);
    }

    this.viewModel = viewModel;
    this.receiptStepsService.stepLoading.next(false);

    const title = MessageResourceManager.Current.getMessage("ExpenseModelDataStep_Title");
    this.telemetryService.trackPageView({
      name: title,
      properties: {
        selectedModelCode: this.viewModel?.expenseModelRef?.modelCode?.value
      }
    });

    this.cd.detectChanges();
  };

  async selectModel(model: {
    identity: { [key: string]: string | number; },
    description: string,
    all: { [key: string]: string | number; }[]
  }) {
    this.receiptStepsService.stepLoading.next(true);
    await this.viewModel.expenseModelRef.setPlainPascalCaseIdentity(model.identity);
    await this.viewModel.validateStep();
    this.receiptStepsService.stepLoading.next(false);
    this.telemetryService.trackEvent({
      name: 'WEA_ReceiptLongOp_ExpenseModelDataStep_SelectModelCode',
      properties: {
        modelCode: this.viewModel?.expenseModelRef?.modelCode?.value
      }
    });
    this.receiptStepsService.nextStep();
  }
}

