<div class="step-template" *ngIf="viewModel">
  <h2>{{viewModel.newLabels().value[viewModel.currentExpenseClassificationType] ?? ('ExpenseClassification_' +
    viewModel.expenseClassificationEnum[viewModel.currentExpenseClassificationType] |
    NTSTranslate)}}</h2>

  <div class="state-container" *ngIf="viewModel.showCurrentState">
    <div class="state-box">
      <div class="state-color"
        [style.backgroundColor]="viewModel.expenseStateMapper.getColorByExpenseStateType(viewModel.expenseState.value)">
        <div class="state-icon">
          <svg-icon [key]="viewModel.expenseStateMapper.getIconByExpenseStateType(viewModel.expenseState.value)">
          </svg-icon>
        </div>
      </div>
      <div class="state-label">{{viewModel.expenseState.formattedValue}}</div>
    </div>
  </div>

  <div class="field-list" [class.has-state-visible]="viewModel.showCurrentState">
    <nts-expandable [title]="'ExpenseDataStep_Expandable_Main' | NTSTranslate" [disableAdditionalFields]="true"
      [isCollapsed]="false" [disableToggle]="true" [promotedFields]="">

      <nts-labelled-text-box #expandableChild [propertyViewModel]="viewModel.expenseDate">
      </nts-labelled-text-box>

      <nts-external-list #expandableChild [showCodeInDescription]="false" *ngIf="viewModel.isEnabled"
        [externalPropertyViewModel]="viewModel.extendedExpenseTypeRef" [scrollElementClass]="'field-list'"
        [code]="viewModel.extendedExpenseTypeRef.id" [decodeProperties]="['description','classification']">
      </nts-external-list>

      <nts-labelled-text-box #expandableChild *ngIf="!viewModel.isEnabled"
        [propertyViewModel]="viewModel.expenseTypeDescription">
      </nts-labelled-text-box>

      <nts-decode-text-box #expandableChild [showCodeInDescription]="false"
        [externalPropertyViewModel]="viewModel.commissionRef" [code]="viewModel.commissionRef.id"
        [scrollElementClass]="'field-list'">
      </nts-decode-text-box>

      <nts-decode-text-box #expandableChild [showCodeInDescription]="false"
        [externalPropertyViewModel]="viewModel.leadRef" [code]="viewModel.leadRef.id"
        [scrollElementClass]="'field-list'">
      </nts-decode-text-box>

      <nts-decode-text-box #expandableChild [showCodeInDescription]="false"
        [externalPropertyViewModel]="viewModel.customerRef" [code]="viewModel.customerRef.id"
        [scrollElementClass]="'field-list'">
      </nts-decode-text-box>

      <nts-labelled-text-box #expandableChild [propertyViewModel]="viewModel.description">
      </nts-labelled-text-box>

      <nts-labelled-text-box
        *ngIf="viewModel.expenseClassificationEnum.MileageRefound !== viewModel.currentExpenseClassificationType"
        #expandableChild [propertyViewModel]="viewModel.expenseTotalAmount">
      </nts-labelled-text-box>

      <div class="custom-component full-column">
        <div class="main-container full-width">
          <div class="label-container">
            <nts-label-box [title]="viewModel.files.metadataDescription"
              [label]="viewModel.files.metadataShortDescription">
            </nts-label-box>
          </div>
          <div class="edit-container">
            <nts-multiple-file-upload [editable]="viewModel.files.isEnabled"
              [isRemoteUploadEnabled]="this.viewModel.isRemoteUpload"
              [moveDownCallBack]="viewModel.files.moveDown.bind(this.viewModel.files)"
              [moveUpCallBack]="viewModel.files.moveUp.bind(this.viewModel.files)" [files]="this.viewModel.files"
              [removeCallBack]="viewModel.files.removeFile.bind(this.viewModel.files)"
              [viewFileCallBack]="viewExpenseFile.bind(this)"
              [addCallBack]="viewModel.files.addNewItem.bind(this.viewModel.files)"
              [uploadCallBack]="orchestratorViewModel.uploadExpenseFile.bind(orchestratorViewModel)"
              (filesChanged)="fileUploadValidation()">
            </nts-multiple-file-upload>
          </div>
        </div>
      </div>

      <nts-labelled-text-box #expandableChild [propertyViewModel]="viewModel.note" [fullColumn]="true"
        [multiline]="true">
      </nts-labelled-text-box>

    </nts-expandable>
  </div>
  <nts-fullscreen-preview [showNextPreviousButtons]="true" (askNextImage)="viewModel.showFullScreenNextImage()"
    (askPreviousImage)="viewModel.showFullScreenPreviousImage()" [inIframe]="inIframe" [show]="true"
    [(imageSrc)]="viewModel.currentImageModalSrc" [(imageBlob)]="viewModel.currentImageModalBlob"
    [(imageName)]="viewModel.currentImageModalName">
  </nts-fullscreen-preview>
</div>
