<div class="step-template" *ngIf="viewModel">
  <h2>{{viewModel.newLabels().value[viewModel.currentExpenseClassificationType] ?? ('ExpenseClassification_' +
    viewModel.expenseClassificationEnum[viewModel.currentExpenseClassificationType] |
    NTSTranslate)}}</h2>

  <div class="state-container" *ngIf="viewModel.showCurrentState">
    <div class="state-box">
      <div class="state-color"
        [style.backgroundColor]="viewModel.expenseStateMapper.getColorByExpenseStateType(viewModel.expenseState.value)">
        <div class="state-icon">
          <svg-icon [key]="viewModel.expenseStateMapper.getIconByExpenseStateType(viewModel.expenseState.value)">
          </svg-icon>
        </div>
      </div>
      <div class="state-label">{{viewModel.expenseState.formattedValue}}</div>
    </div>
  </div>

  <div class="field-list" [class.has-state-visible]="viewModel.showCurrentState">
    <!-- <nts-loader [isVisible]="!stepLoaded"></nts-loader> -->
    <nts-expandable [title]="'PaymentDataStep_Expandable_Main' | NTSTranslate" [disableAdditionalFields]="true"
      [isCollapsed]="false" [disableToggle]="true" [promotedFields]="">

      <nts-external-list #expandableChild [showCodeInDescription]="false"
        *ngIf="viewModel.currentExpenseClassificationType !== viewModel.expenseClassificationEnum.MileageRefound && viewModel.isEnabled"
        [externalPropertyViewModel]="viewModel.extendedPaymentTypeRef" [scrollElementClass]="'field-list'"
        [code]="viewModel.extendedPaymentTypeRef.id" [decodeDescription]="viewModel.extendedPaymentTypeRef.description">
      </nts-external-list>

      <nts-labelled-text-box #expandableChild [propertyViewModel]="viewModel.paymentTypeDescription"
        *ngIf="viewModel.currentExpenseClassificationType !== viewModel.expenseClassificationEnum.MileageRefound && !viewModel.isEnabled">
      </nts-labelled-text-box>

      <nts-spacer-box
        *ngIf="viewModel.currentExpenseClassificationType !== viewModel.expenseClassificationEnum.MileageRefound">
      </nts-spacer-box>

      <nts-labelled-text-box #expandableChild [propertyViewModel]=" viewModel.reliableSupplier">
      </nts-labelled-text-box>

      <nts-decode-text-box #expandableChild [scrollElementClass]="'field-list'" [showCodeInDescription]="false"
        [externalPropertyViewModel]="viewModel.supplierRef" [code]="viewModel.supplierRef.id">
      </nts-decode-text-box>

      <nts-labelled-text-box #expandableChild [propertyViewModel]="viewModel.businessName">
      </nts-labelled-text-box>

      <nts-labelled-text-box #expandableChild [propertyViewModel]="viewModel.vatNumber">
      </nts-labelled-text-box>

    </nts-expandable>
  </div>
</div>
