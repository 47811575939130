import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CacheOptionsInterface, GenericArrayServiceResponse, GenericServiceRequest, GenericServiceResponse } from "@nts/std";
import { LongOpApiClient, LongOpResultTypeDecorator, RootModelTypeDecorator, RootModelTypeNameDecorator } from "@nts/std";
import { map, Observable } from "rxjs";
import { AttachmentIdentity } from "src/app/expense-annotation/domain-models/attachment.identity";
import { ExpenseAnnotationIdentity } from "src/app/expense-annotation/domain-models/expense-annotation.identity";
import { ExpenseIdentity } from "src/app/expense-annotation/domain-models/expense.identity";
import { ExpenseModel } from "src/app/expense-model/domain-models/expense-model";
import { Customer } from "src/app/external-remote/customer/customer";
import { LeadIdentity } from "src/app/external-remote/lead/lead.identity";
import { UserAvailablePaymentsIdentity } from "src/app/user-available-payments/domain-models/user-available-payments.identity";
import { UploadFileOutputDto } from "../domain-models/dto/upload-file-output.dto";
import { ReceiptLongOp } from "../domain-models/receipt-long-op";
import { ReceiptLongOpIdentity } from "../domain-models/receipt-long-op-identity";
import { ReceiptParams } from "../domain-models/receipt-params";
import { ReceiptResult } from "../domain-models/receipt-result";
import { ExpenseModelListResponse } from "../responses/expense-model-list-response";
import { PersonalPaymentListResponse } from "../responses/personal-payment-list-response";
import { UserAvailableExpensesIdentity } from "src/app/user-available-expenses/domain-models/user-available-expenses.identity";
import { PersonalExpenseListResponse } from "../responses/personal-expense-list-response";
import { Label } from "src/app/classification-labels/domain-models/label";
import { ClassificationLabelResponse } from "../responses/classification-label-response";
import { UserOfTenantIdentity } from "src/app/external-remote/user-of-tenant/user-of-tenant.identity";

@Injectable()
@RootModelTypeNameDecorator('ReceiptLongOp')
@RootModelTypeDecorator(ReceiptLongOp)
@LongOpResultTypeDecorator(ReceiptResult)
export class ReceiptLongOpApiClient extends LongOpApiClient<ReceiptLongOp, ReceiptLongOpIdentity, ReceiptParams, ReceiptResult> {

  uploadFile(fileToUpload): Observable<GenericServiceResponse<UploadFileOutputDto>> {

    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    const responseInstance = new GenericServiceResponse<UploadFileOutputDto>(UploadFileOutputDto);
    return this.getWebApiServiceAgent().invokePostUploadFileWithInstance<GenericServiceResponse<UploadFileOutputDto>>('UploadFile', formData, responseInstance);
  }

  downloadFile(attachmentIdentity: AttachmentIdentity): Observable<any> {

    const request: GenericServiceRequest<AttachmentIdentity> = new GenericServiceRequest<AttachmentIdentity>();
    request.requestData = attachmentIdentity;

    return this.getWebApiServiceAgent().invokePostDownloadFile
      <GenericServiceRequest<AttachmentIdentity>, any>('DownloadFile', request, null);
  }

  getExpenseToUpdate(expenseIdentity: ExpenseIdentity) {
    const request: GenericServiceRequest<ExpenseIdentity> = new GenericServiceRequest<ExpenseIdentity>();
    request.requestData = expenseIdentity;

    const response = new GenericServiceResponse<ReceiptLongOp>(ReceiptLongOp);
    return this.getWebApiServiceAgent()
      .invokePostWithResponseWithInstance('GetExpenseToUpdate', request, response);
  }

  getPersonalPayments(
    userAvailablePaymentsIdentity: UserAvailablePaymentsIdentity,
    cacheOptions: CacheOptionsInterface = {
      bypass: false,
      expirationTime: 60, // scadono dopo 60 secondi
      force: false,
    },
    customHeaders?: HttpHeaders
  ): Observable<PersonalPaymentListResponse> {

    const request: GenericServiceRequest<UserAvailablePaymentsIdentity> = new GenericServiceRequest<UserAvailablePaymentsIdentity>();
    request.requestData = userAvailablePaymentsIdentity;

    return this.getWebApiServiceAgent()
      .invokePostWithResponse(
        'GetPersonalPayments',
        request,
        PersonalPaymentListResponse,
        false,
        customHeaders,
        null,
        null,
        cacheOptions
      );
  }

  getPersonalExpenses(
    userAvailableExpensesIdentity: UserAvailableExpensesIdentity,
    cacheOptions: CacheOptionsInterface = {
      bypass: false,
      expirationTime: 60, // scadono dopo 60 secondi
      force: false,
    },
    customHeaders?: HttpHeaders
  ): Observable<PersonalExpenseListResponse> {

    const request: GenericServiceRequest<UserAvailableExpensesIdentity> = new GenericServiceRequest<UserAvailableExpensesIdentity>();
    request.requestData = userAvailableExpensesIdentity;

    return this.getWebApiServiceAgent()
      .invokePostWithResponse(
        'GetPersonalExpenses',
        request,
        PersonalExpenseListResponse,
        false,
        customHeaders,
        null,
        null,
        cacheOptions
      );
  }

  getAvailableModels(
    cacheOptions: CacheOptionsInterface = {
      bypass: false,
      expirationTime: 60, // scadono dopo 60 secondi
      force: false,
    },
    companyId: number = null,
    customHeaders: HttpHeaders = null
  ): Observable<ExpenseModelListResponse> {
    return this.getWebApiServiceAgent()
      .invokeGetWithOutQuery(
        'GetAvailableModels',
        ExpenseModelListResponse,
        false,
        customHeaders,
        null,
        null,
        cacheOptions
      ).pipe(
        map((expenseModelListResponse: ExpenseModelListResponse) => {
          if (expenseModelListResponse.operationSuccedeed && expenseModelListResponse.result?.length > 0 && companyId > 0) {
            expenseModelListResponse.result = expenseModelListResponse.result.filter((model: ExpenseModel) =>
              model.companyId === companyId
            )
          }
          return expenseModelListResponse
        })
      );
  }


  getAvailableModelsForUserAsync(identity: UserOfTenantIdentity): Observable<GenericArrayServiceResponse<ExpenseModel>> {

    const request: GenericServiceRequest<UserOfTenantIdentity> = new GenericServiceRequest<UserOfTenantIdentity>();
    request.requestData = identity;

    const response: GenericArrayServiceResponse<ExpenseModel> = new GenericArrayServiceResponse<ExpenseModel>(ExpenseModel);

    return this.getWebApiServiceAgent()
      .invokePostWithResponseWithInstance<
        GenericServiceRequest<UserOfTenantIdentity>,
        GenericArrayServiceResponse<ExpenseModel>
      >('GetAvailableModelsForUser', request, response);
  }

  updateExpense(longOp: ReceiptLongOp): Observable<GenericServiceResponse<ReceiptResult>> {
    const request: GenericServiceRequest<ReceiptLongOp> = new GenericServiceRequest<ReceiptLongOp>();
    request.requestData = longOp;

    const response = new GenericServiceResponse<ReceiptResult>(ReceiptResult);
    return this.getWebApiServiceAgent()
      .invokePostWithResponseWithInstance('UpdateExpense', request, response);
  }

  integrateExpenseDocumentation(longOp: ReceiptLongOp): Observable<GenericServiceResponse<ReceiptResult>> {
    const request: GenericServiceRequest<ReceiptLongOp> = new GenericServiceRequest<ReceiptLongOp>();
    request.requestData = longOp;

    const response = new GenericServiceResponse<ReceiptResult>(ReceiptResult);
    return this.getWebApiServiceAgent()
      .invokePostWithResponseWithInstance('IntegrateExpenseDocumentation', request, response);
  }

  createExpenseLongOp(identity: ExpenseAnnotationIdentity): Observable<GenericServiceResponse<ReceiptLongOp>> {
    const request: GenericServiceRequest<ExpenseAnnotationIdentity> = new GenericServiceRequest<ExpenseAnnotationIdentity>();
    request.requestData = identity;

    const response = new GenericServiceResponse<ReceiptLongOp>(ReceiptLongOp);
    return this.getWebApiServiceAgent()
      .invokePostWithResponseWithInstance('CreateExpenseLongOp', request, response);
  }

  getCustomerByLeadIdentity(identity: LeadIdentity): Observable<GenericServiceResponse<Customer>> {
    const request: GenericServiceRequest<LeadIdentity> = new GenericServiceRequest<LeadIdentity>();
    request.requestData = identity;

    const response = new GenericServiceResponse<Customer>(Customer);
    return this.getWebApiServiceAgent()
      .invokePostWithResponseWithInstance('GetCustomerByLeadIdentity', request, response);
  }

  public getClassificationLabelsAsync(cacheOptions: CacheOptionsInterface = {
    bypass: false,
    expirationTime: 60, // scadono dopo 60 secondi
    force: false,
  }): Observable<ClassificationLabelResponse> {

    const response: ClassificationLabelResponse = new ClassificationLabelResponse(Label);
    return this.getWebApiServiceAgent()
      .invokeGetWithOutQueryWithInstance<ClassificationLabelResponse>('GetClassificationLabels', response, false, null, null, null, cacheOptions);
  }
}
