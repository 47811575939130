import { ChangeDetectorRef, Component } from '@angular/core';
import { BaseCheckboxTextBoxComponent, BaseTextAreaBoxComponent, LabelBoxComponent, ModalComponentInterface, NTSTranslatePipe } from '@nts/std';
import { ExpenseTypeInstructionViewModel } from 'src/app/receipt-long-op/view-models/modals/expense-type-instruction.view-model';

@Component({
  selector: 'nts-expense-type-instruction-modal-container',
  templateUrl: 'expense-type-instruction-modal-container.component.html',
  styleUrls: ['./expense-type-instruction-modal-container.component.scss'],
  standalone: true,
  imports: [
    BaseTextAreaBoxComponent,
    NTSTranslatePipe,
    LabelBoxComponent,
    BaseCheckboxTextBoxComponent
  ]
})
export class ExpenseTypeInstructionModalContainerComponent implements ModalComponentInterface {

  /**
   * The view model
   */
  viewModel: ExpenseTypeInstructionViewModel;

  /**
   * @param sanitizer
   * @param cd
   * @param imageCompress
   */
  constructor(
    private cd: ChangeDetectorRef,
  ) {

  }

  /**
   * Initialize the component
   */
  async initialize(): Promise<void> {
    await this.viewModel.initialize(this.cd);

    this.cd.detectChanges();
  }
}
