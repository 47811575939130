import { CoreModel, Expose, IdentityTypeDecorator, ModelTypeNameDecorator, Type } from '@nts/std';
import { ExtendedAvailableExpenseIdentity } from './extended-available-expense.identity';
import { AvailableExpenseIdentity } from 'src/app/expense-model/domain-models/available-expense.identity';
import { PersonalExpenseIdentity } from 'src/app/user-available-expenses/domain-models/personal-expense.identity';
import { CompilationInstructions } from 'src/app/expense-model/domain-models/compilation-instructions';
import { PersonalCompilationInstructions } from 'src/app/user-available-expenses/domain-models/personal-compilation-instructions';

@IdentityTypeDecorator(ExtendedAvailableExpenseIdentity)
@ModelTypeNameDecorator('ExtendedAvailableExpense')
export class ExtendedAvailableExpense extends CoreModel<ExtendedAvailableExpenseIdentity> {

  private _id: number = 0;

  /**
   * Id modalità pagamento
   */
  @Expose()
  public get id(): number {
    return this.getValueForIdentityValue<number>(() => this._id, 'id');
  }
  public set id(value: number) {
    this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
  }

  private _description: string;

  /**
   * Descrizione modalità pagamento
   */
  @Expose()
  public get description(): string {
    return this.getValue<string>(() => this._description, 'description');
  }
  public set description(value: string) {
    this.setValue<string>(() => { this._description = value; }, value, 'description');
  }

  @Expose()
  @Type(() => AvailableExpenseIdentity)
  public availableExpenseIdentity: AvailableExpenseIdentity;

  @Expose()
  @Type(() => PersonalExpenseIdentity)
  public personalExpenseIdentity: PersonalExpenseIdentity;

  private _availableExpenseInstructions: CompilationInstructions;

  /**
   Istruzioni compilazione
    */
  @Expose()
  @Type(() => CompilationInstructions)
  public get availableExpenseInstructions(): CompilationInstructions {
    return this.getValueForInternalWithDifferentIdentity<CompilationInstructions, AvailableExpenseIdentity>(
      (value) => {
        this._availableExpenseInstructions = value;
      },
      this._availableExpenseInstructions,
      'availableExpenseInstructions',
      CompilationInstructions
    );
  }
  public set availableExpenseInstructions(value: CompilationInstructions) {
    this.setValueForInternalWithDifferentIdentity<CompilationInstructions, AvailableExpenseIdentity>(() => { this._availableExpenseInstructions = value; }, value, 'availableExpenseInstructions');
  }

  private _personalExpenseInstructions: PersonalCompilationInstructions;

  /**
   Istruzioni compilazione
    */
  @Expose()
  @Type(() => PersonalCompilationInstructions)
  public get personalExpenseInstructions(): PersonalCompilationInstructions {
    return this.getValueForInternalWithDifferentIdentity<PersonalCompilationInstructions, PersonalExpenseIdentity>(
      (value) => {
        this._personalExpenseInstructions = value;
      },
      this._personalExpenseInstructions,
      'personalExpenseInstructions',
      PersonalCompilationInstructions
    );
  }
  public set personalExpenseInstructions(value: PersonalCompilationInstructions) {
    this.setValueForInternalWithDifferentIdentity<PersonalCompilationInstructions, PersonalExpenseIdentity>(() => { this._personalExpenseInstructions = value; }, value, 'personalExpenseInstructions');
  }

}
