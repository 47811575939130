
import {
    IdentityTypeDecorator, OCCModel, ModelTypeNameDecorator, Expose, AllowedChars, NumberDecorator,
    StringDecorator, EnumDecorator, Type,
    Transform,
    DateTimeOffset
} from '@nts/std';
import { Lead } from '../lead/lead';
import { LeadIdentity } from '../lead/lead.identity';
import { GeneralSubjectIdentity } from './general-subject.identity';

@IdentityTypeDecorator(GeneralSubjectIdentity)
@ModelTypeNameDecorator('GeneralSubject')
export class GeneralSubject extends OCCModel<GeneralSubjectIdentity> {

    // tslint:disable-next-line: variable-name
    private _id: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'General_Subject_Id' })
    get id(): number {
        return this.getValueForIdentityValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
    }

    private _companyId: number = 0;

    /**

     */
    @Expose()
    public get companyId(): number {
        return this.getValueForIdentityValue<number>(() => this._companyId, 'companyId');
    }
    public set companyId(value: number) {
        this.setValueForIdentityValue<number>(() => { this._companyId = value; }, value, 'companyId');
    }

    // tslint:disable-next-line: variable-name
    private _companyName: string;

    @Expose()
    get companyName(): string {
        return this.getValue<string>(() => this._companyName, 'companyName');
    }
    set companyName(value: string) {
        this.setValue<string>(() => { this._companyName = value; }, value, 'companyName');
    }

    private _vatNumber: string;

    @Expose()
    get vatNumber(): string {
        return this.getValue<string>(() => this._vatNumber, 'vatNumber');
    }
    set vatNumber(value: string) {
        this.setValue<string>(() => { this._vatNumber = value; }, value, 'vatNumber');
    }

    private _fiscalCode: string;

    @Expose()
    get fiscalCode(): string {
        return this.getValue<string>(() => this._fiscalCode, 'fiscalCode');
    }
    set fiscalCode(value: string) {
        this.setValue<string>(() => { this._fiscalCode = value; }, value, 'fiscalCode');
    }

    private _city: string;

    @Expose()
    get city(): string {
        return this.getValue<string>(() => this._city, 'city');
    }
    set city(value: string) {
        this.setValue<string>(() => { this._city = value; }, value, 'city');
    }

    private _zipCode: string;

    @Expose()
    get zipCode(): string {
        return this.getValue<string>(() => this._zipCode, 'zipCode');
    }
    set zipCode(value: string) {
        this.setValue<string>(() => { this._zipCode = value; }, value, 'zipCode');
    }

    private _leadId: number;

    /**
     Id cliente
     */
    @Expose()
    public get leadId(): number {
        return this.getValue<number>(() => this._leadId, 'leadId');
    }
    public set leadId(value: number) {
        this.setValue<number>(() => { this._leadId = value; }, value, 'leadId');
    }

    private _leadCompanyId: number;

    /**
     Id Lead
     */
    @Expose()
    public get leadCompanyId(): number {
        return this.getValue<number>(() => this._leadCompanyId, 'leadCompanyId');
    }
    public set leadCompanyId(value: number) {
        this.setValue<number>(() => { this._leadCompanyId = value; }, value, 'leadCompanyId');
    }

    private _leadRef: Lead
    /**
     Lead
     */
    @Expose()
    @Type(() => Lead)
    public get leadRef(): Lead {
        return this.getValueForExternal<Lead>(
            () => this._leadRef,
            'leadRef',
            Lead
        );
    }
    public set leadRef(value: Lead) {
        this.setValueForExternal<Lead, LeadIdentity>(() => { this._leadRef = value; }, value, 'leadRef');
    }


    private _startDate: DateTimeOffset;

    /**
     Data inizio
     */
    @Expose()
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('startDate', value, obj, type);
    })
    public get startDate(): DateTimeOffset {
        return this.getValue<DateTimeOffset>(() => this._startDate, 'startDate');
    }
    public set startDate(value: DateTimeOffset) {
        this.setValue<DateTimeOffset>(() => { this._startDate = value; }, value, 'startDate');
    }

    private _endDate: DateTimeOffset;

    /**
     Data inizio
     */
    @Expose()
    @Transform((value, obj, type) => {
        return DateTimeOffset.transform('endDate', value, obj, type);
    })
    public get endDate(): DateTimeOffset {
        return this.getValue<DateTimeOffset>(() => this._endDate, 'endDate');
    }
    public set endDate(value: DateTimeOffset) {
        this.setValue<DateTimeOffset>(() => { this._endDate = value; }, value, 'endDate');
    }

}
