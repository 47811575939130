import { EnumPropertyViewModel } from '@nts/std';
import { BehaviorSubject } from 'rxjs';
import { ExpenseState } from 'src/app/expense-annotation/generated/domain-models/enums/generated-expense-state';
import { ExpenseStateMapper } from 'src/app/shared/services/expense-state-mapper';
import { GeneratedExpenseSelectionViewModel } from '../generated/view-models/generated-expense-selection.view-model';
import { Label } from 'src/app/classification-labels/domain-models/label';
import { ReceiptParamsViewModel } from './receipt-params.view-model';

export class ExpenseSelectionViewModel extends GeneratedExpenseSelectionViewModel {

  hasActionsButtons = new BehaviorSubject<boolean>(true);
  isPreviousDisabled = new BehaviorSubject<boolean>(false);
  isValid = new BehaviorSubject<boolean>(false);
  hideNext = new BehaviorSubject<boolean>(false);
  expenseModelId: number = null;
  expenseState: EnumPropertyViewModel;
  expenseStateEnum = ExpenseState;
  expenseStateMapper = ExpenseStateMapper;
  showCurrentState: boolean;

  newLabels(): BehaviorSubject<any[]> {
    return (this.parent as ReceiptParamsViewModel).newLabels$;
  }

  async validateStep(): Promise<void> {
    this.isValid.next(this.expenseClassification.value != null)
  };

  async initPresentationLogic(
    expenseAnnotationId: number,
    remoteExpense: boolean,
    expenseClassificationList: number[],
    expenseModelId = null,
    expenseState: EnumPropertyViewModel,
    isEnabled: boolean
  ) {
    this.showCurrentState = expenseAnnotationId > 0 && remoteExpense === true;
    this.expenseState = expenseState;

    if (expenseModelId) {
      this.expenseModelId = expenseModelId;
    }
    if (expenseClassificationList.length === 0) {
      this.expenseClassification.setValue(null);
    } else {
      if (expenseClassificationList.length === 1) {
        this.expenseClassification.setValue(expenseClassificationList[0]);
      } else if (this.expenseClassification.value > 0) {
        const found = expenseClassificationList.find((e) => e === this.expenseClassification.value);
        if (found == null) {
          this.expenseClassification.setValue(null);
        }
      }
    }
    if (!isEnabled) {
      this.expenseClassification.isEnabled = isEnabled;
    }
  }
}
