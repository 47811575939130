import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActivatedRoute } from '@angular/router';
import { ViewModelAwareInterface } from 'src/app/shared/models/view-model-aware.interface';
import { RouteStateService } from 'src/app/shared/services/route-state.service';
import { ReceiptStepsService } from 'src/app/receipt-long-op/services/receipt-steps.service';
import { AccommodationDataViewModel } from 'src/app/receipt-long-op/view-models/accommodation-data.view-model';
import { AuthService, ExpandableComponent, LabelledTextBoxComponent, MessageResourceManager, NTSTranslatePipe, ToastMessage, ToastMessageType } from '@nts/std';
import { firstValueFrom, Observable } from 'rxjs';
import { TelemetryService } from '@nts/std/src/lib/telemetry';
import { NgIf } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { ExpenseState } from 'src/app/expense-annotation/generated/domain-models/enums/generated-expense-state';
import { BaseStepComponent } from '../base-step.component';

@UntilDestroy()
@Component({
  selector: 'nts-accommodation-data-step',
  templateUrl: './accommodation-data-step.component.html',
  styleUrls: ['./accommodation-data-step.component.scss'],
  standalone: true,
  imports: [
    NTSTranslatePipe,
    NgIf,
    SvgIconComponent,
    ExpandableComponent,
    LabelledTextBoxComponent
  ]
})
export class AccommodationDataStepComponent extends BaseStepComponent<AccommodationDataViewModel> {

  untilDestroyed(): <U>(source: Observable<U>) => Observable<U> {
    return untilDestroyed(this);
  }

  async onViewModelLoaded() {

    this.receiptStepsService.stepLoading.next(true);
    const viewModel = this.receiptStepsService.getCurrentState() as AccommodationDataViewModel;

    const rootViewModel = this.receiptStepsService.orchestratorViewModel.rootViewModel;

    const queryParams = await firstValueFrom(this.routeStateService.queryParams);
    const expenseAnnotationId = parseInt(queryParams['expenseAnnotationId'], 10);
    const remoteExpense = queryParams['remoteExpense'] === 'true';

    viewModel.initPresentationLogic(
      expenseAnnotationId,
      remoteExpense,
      rootViewModel.params.expenseSelection.expenseClassification.value,
      rootViewModel.params.expenseState,
      !(await this.isReadOnly(rootViewModel))
    );

    this.receiptStepsService.orchestratorViewModel.rootViewModelModified.pipe(untilDestroyed(this)).subscribe(() => {
      viewModel.validateStep();
    });

    viewModel.validateStep();

    this.inIframe = this.activatedRoute.snapshot.queryParams['iframe'] === 'true' || window.self !== window.top;

    const params = await firstValueFrom(this.routeStateService.params);
    const currentAction = params.get('action');

    if (currentAction === 'add' && viewModel.isValid.value === true) {
      if (!this.receiptStepsService.orchestratorViewModel.errorDuringAdd) {
        this.receiptStepsService.nextStep();
      } else {
        this.receiptStepsService.stepLoading.next(false);
      }
    } else if (currentAction === 'add' && viewModel.isValid.value === false) {
      const toastMessage: ToastMessage = {
        message: MessageResourceManager.Current.getMessage('MissinigDataInStep'),
        type: ToastMessageType.warn,
        title: MessageResourceManager.Current.getMessage('std_Warning')
      };
      this.receiptStepsService.orchestratorViewModel.toastMessageService.showToast(toastMessage);
      this.receiptStepsService.orchestratorViewModel.errorDuringAdd = true;
      this.receiptStepsService.stepLoading.next(false);
    } else {
      this.receiptStepsService.stepLoading.next(false);
    }

    this.viewModel = viewModel;

    const customTitle = viewModel.newLabels().value[viewModel.currentExpenseClassificationType];
    const title = customTitle ?? MessageResourceManager.Current.getMessage('ExpenseClassification_' + viewModel.expenseClassificationEnum[viewModel.currentExpenseClassificationType]);
    this.telemetryService.trackPageView({ name: title });
  };

}

