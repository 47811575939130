import {
	InternalViewModel, StringPropertyViewModel, GuidPropertyViewModel, EnumPropertyViewModel, BoolPropertyViewModel, NumericPropertyViewModel, DateTimeOffsetPropertyViewModel, DateTimePropertyViewModel, 
	NNumericPropertyViewModel, NDateTimeOffsetPropertyViewModel, NDateTimePropertyViewModel
} from '@nts/std';
import { CompilationInstructions } from '../../domain-models/compilation-instructions';
import { AvailableExpenseIdentity } from '../../domain-models/available-expense.identity';

export class GeneratedCompilationInstructionsViewModel extends InternalViewModel<CompilationInstructions, AvailableExpenseIdentity> {

	private _availableExpenseId: NumericPropertyViewModel;

	public get availableExpenseId(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._availableExpenseId = value; }, this._availableExpenseId, 'availableExpenseId');
	}

	private _instructions: StringPropertyViewModel;

	public get instructions(): StringPropertyViewModel {
		return this.getStringPropertyViewModel((value) => { this._instructions = value; }, this._instructions, 'instructions');
	}

}
