import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';

@IdentityTypeNameDecorator('UserAvailableExpensesIdentity')
export class GeneratedUserAvailableExpensesIdentity extends BaseIdentity {

	private _userId: number;

	@Expose()
	public get userId(): number {
		return this.getValue<number>(() => this._userId, 'userId');
	}
	public set userId(value: number) {
		this.setValue<number>(() => { this._userId = value; }, value, 'userId');
	}

	private _companyId: number;

	@Expose()
	public get companyId(): number {
		return this.getValue<number>(() => this._companyId, 'companyId');
	}
	public set companyId(value: number) {
		this.setValue<number>(() => { this._companyId = value; }, value, 'companyId');
	}
}
