import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseToggleTextBoxComponent, LabelBoxComponent, LoaderComponent, MessageResourceManager, ModalComponentInterface, NTSTranslatePipe } from '@nts/std';
import { AsyncPipe, NgIf } from '@angular/common';
import { TreeTableModule } from 'primeng/treetable';
import { UploadFileSettingsViewModel } from './upload-file-settings.view-model';
import { CropperPosition, ImageCroppedEvent, ImageCropperModule, LoadedImage } from 'ngx-image-cropper';
import { DataUrl, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';
import { BehaviorSubject } from 'rxjs';
import cloneDeep from 'lodash-es/cloneDeep';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'nts-upload-file-settings-modal-container',
  templateUrl: 'upload-file-settings-modal-container.component.html',
  styleUrls: ['./upload-file-settings-modal-container.component.scss'],
  standalone: true,
  imports: [
    BaseToggleTextBoxComponent,
    NTSTranslatePipe,
    LabelBoxComponent,
    TreeTableModule,
    ImageCropperModule,
    AsyncPipe,
    LoaderComponent,
    NgIf
  ]
})
export class UploadFileSettingsModalContainerComponent implements ModalComponentInterface {

  @ViewChild('cropperWrapper') cropperWrapper: ElementRef<HTMLDivElement>;

  /**
   * The view model
   */
  viewModel: UploadFileSettingsViewModel;

  /**
   * @param sanitizer
   * @param cd
   * @param imageCompress
   */
  constructor(
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    public imageCompress: NgxImageCompressService,
  ) {

  }

  /**
   * Initialize the component
   */
  async initialize(): Promise<void> {
    await this.viewModel.initialize({ sanitizer: this.sanitizer, cd: this.cd });

    this.viewModel.originalImageUrl = await Utils.convertFileToBase64(this.viewModel.file);
    this.viewModel.imageUrl = this.viewModel.originalImageUrl;

    this.viewModel.currentOutputFormat = 'png';

    const extension = Utils.getExtension(this.viewModel.file.name);

    switch (extension) {
      case 'jpeg':
      case 'jpg':
        this.viewModel.currentOutputFormat = 'jpeg';
        break;
      case 'bmp':
        this.viewModel.currentOutputFormat = 'bmp';
        break;
      case 'webp':
        this.viewModel.currentOutputFormat = 'webp';
        break;
      case 'ico':
        this.viewModel.currentOutputFormat = 'ico';
        break;
      default:
        this.viewModel.currentOutputFormat = 'png';
        break;
    }

    this.cd.detectChanges();
  }

  /**
   * The image cropped event
   * @param event
   */
  imageCropped(event: ImageCroppedEvent) {
    this.viewModel.isLoading$.next(false);

    this.viewModel.croppedImageUrl = event.objectUrl;

    if (this.viewModel.justToggleOptimized) {
      this.viewModel.cropperPosition = this.viewModel.lastCropperPosition;
      this.viewModel.justToggleOptimized = false;
    } else {
      if (JSON.stringify(this.viewModel.cropperPosition) !== JSON.stringify(event.cropperPosition)) {
        this.viewModel.cropperPosition = event.cropperPosition;

      }
    }

    this.viewModel.croppedImageSize$.next(Utils.formatBytes(event.blob.size));

    this.cd.detectChanges();
  }

  /**
   * The image loaded event
   *
   * @param image The loaded image
   */
  imageLoaded(image: LoadedImage) {
    if (this.viewModel.fixedHeight == null || this.viewModel.fixedWidth == null) {
      setTimeout(() => {
        this.viewModel.fixedHeight = this.cropperWrapper.nativeElement.clientHeight;
        this.viewModel.fixedWidth = this.cropperWrapper.nativeElement.clientWidth;
        this.cd.detectChanges();
      })
    }
  }

  /**
   * The cropper ready event
   */
  cropperReady() {
    // cropper ready
  }

  /**
   * The load image failed event
   */
  loadImageFailed() {
    // show message
  }

  /**
   * The toggle label
   *
   * @returns the toggle label
   */
  getToggleLabel(): string {
    if (this.viewModel.optimizeImageError$.value?.length > 0) {
      return MessageResourceManager.Current.getMessage('ExpenseData_UploadFileSettings_ErrorOptimizing');
    }

    if (this.viewModel.isLoading$.value) {

      if (this.viewModel.optimizeImageSize) {
        return MessageResourceManager.Current.getMessage('ExpenseData_UploadFileSettings_LoadingOptimized');
      } else {
        return MessageResourceManager.Current.getMessage('ExpenseData_UploadFileSettings_Loading');
      }
    } else {
      if (this.viewModel.optimizeImageSize) {
        return MessageResourceManager.Current.getMessage('ExpenseData_UploadFileSettings_OptimizeImage_Optimized');
      } else {
        return MessageResourceManager.Current.getMessage('ExpenseData_UploadFileSettings_OptimizeImage_NotOptimized');
      }
    }
  }

  /**
   * The toggle optimize image size
   *
   * @param optimizeImageSize
   */
  async toggleOptimizeImageSize(optimizeImageSize: boolean) {
    this.viewModel.optimizeImageSize = optimizeImageSize;
    this.viewModel.optimizeImageError$.next('');

    if (optimizeImageSize == true) {
      this.viewModel.isLoading$.next(true);
      // Se non ho già l'immagine ottimizzata
      if (!this.viewModel.optimizedImageUrl) {
        const result = await this.compressImage(
          this.viewModel.file.name,
          await Utils.convertFileToBase64(this.viewModel.file)
        );

        /**
         * Se il processo di compressione è andato a buon fine
         */
        if (result) {
          this.viewModel.optimizedImageUrl = result.image;
          this.viewModel.imageUrl = this.viewModel.optimizedImageUrl;
          this.viewModel.justToggleOptimized = true;
        } else {
          this.viewModel.optimizeImageSize = false;
          this.viewModel.imageUrl = this.viewModel.originalImageUrl;
          this.viewModel.isLoading$.next(false);
          this.viewModel.optimizeImageError$.next(MessageResourceManager.Current.getMessage('ExpenseData_UploadFileSettings_ErrorOptimizingDescription'));
        }
      } else {
        this.viewModel.imageUrl = this.viewModel.optimizedImageUrl;
        this.viewModel.justToggleOptimized = true;
      }

      // Clono l'ultima posizione del cropper
      this.viewModel.lastCropperPosition = cloneDeep(this.viewModel.cropperPosition);

    } else {
      this.viewModel.isLoading$.next(true);

      // Clono l'ultima posizione del cropper
      this.viewModel.lastCropperPosition = cloneDeep(this.viewModel.cropperPosition);

      this.viewModel.imageUrl = this.viewModel.originalImageUrl;
      this.viewModel.justToggleOptimized = true;

    }
    this.cd.detectChanges();
  }

  byteCount = (imgString: DataUrl): number => encodeURI(imgString).split(/%..|./).length - 1;

  async getImageMaxSize(myFile: UploadResponse, maxSizeMb: number, debugMode: boolean, ratio: number = 50, quality: number = 100): Promise<UploadResponse> {
    const MAX_TRIES = 10;

    const bytesToMB = (bytes: number) => (bytes / 1024 / 1024).toFixed(2);

    if (debugMode) {
      console.debug('Ngxthis - Opening upload window');
    }

    let compressedFile;

    for (let i = 0; i < MAX_TRIES; i++) {
      const previousSize = this.byteCount(myFile.image);
      compressedFile = await this.imageCompress.compressFile(myFile.image, myFile.orientation, ratio, quality);
      const newSize = this.byteCount(compressedFile);
      console.debug('Ngxthis -', 'Compression from', bytesToMB(previousSize), 'MB to', bytesToMB(newSize), 'MB');
      if (newSize >= previousSize) {
        if (i === 0) {
          if (debugMode) {
            console.debug(
              'Ngxthis -',
              "File can't be reduced at all - returning the original",
              bytesToMB(previousSize),
              'MB large'
            );
          }
          throw { ...myFile, image: compressedFile };
        } else {
          if (debugMode) {
            console.debug(
              'Ngxthis -',
              "File can't be reduced more - returning the best we can, which is ",
              bytesToMB(previousSize),
              'MB large'
            );
          }
          throw { ...myFile, image: compressedFile };
        }
      } else {
        if (newSize < maxSizeMb * 1024 * 1024) {
          if (debugMode) {
            console.debug('Ngxthis -', 'Here your file', bytesToMB(newSize), 'MB large');
          }
          return { ...myFile, image: compressedFile };
        } else if (i === 9) {
          if (debugMode) {
            console.debug(
              'Ngxthis -',
              "File can't reach the desired size after",
              MAX_TRIES,
              'tries. Returning file ',
              bytesToMB(previousSize),
              'MB large'
            );
          }
          throw { ...myFile, image: compressedFile };
        }
      }
      if (debugMode) {
        console.debug('Ngxthis -', 'Reached', bytesToMB(newSize), 'MB large. Trying another time after', i + 1, 'times');
      }
      myFile.image = compressedFile;
    }
    if (debugMode) {
      console.debug('Ngxthis - Unexpected error');
    }
    throw {};
  }

  /**
   * Compress the image
   *
   * @param fileName    file name
   * @param base64Image base64 image
   * @returns
   */
  async compressImage(fileName: string, base64Image: string): Promise<UploadResponse | null> {
    const orientation = this.imageCompress.DOC_ORIENTATION.NotDefined;
    return this.getImageWithMaxSizeAndMetas(
      {
        image: base64Image,
        orientation,
        fileName,
      },
      2,
      false,
      80,
      70
    ).then((result: UploadResponse) => {
      return result;
    }).catch((error) => {
      console.error('Error while compressing image:', error);
      return null;
    });
  }

  /**
   * Not handling the upload, you need to provide the file and the orientation by yourself
   *
   * @param file The file to compress
   * @param maxSizeMb The maximum size in MB
   * @param debugMode Enable debug mode
   * @param ratio The compression ratio
   * @param quality The compression quality
   * @returns The compressed file
   */
  getImageWithMaxSizeAndMetas(
    file: UploadResponse,
    maxSizeMb = 1,
    debugMode: boolean,
    ratio: number = 50,
    quality: number = 100
  ): Promise<UploadResponse> {
    return this.getImageMaxSize(file, maxSizeMb, debugMode, ratio, quality);
  }

}
