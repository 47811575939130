import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { MileageRefoundDataViewModel } from 'src/app/receipt-long-op/view-models/mileage-refound-data.view-model';
import { firstValueFrom, Observable } from 'rxjs';
import { ExpandableComponent, LabelledTextBoxComponent, MessageResourceManager, NTSTranslatePipe, ToastMessage, ToastMessageType } from '@nts/std';
import { ReceiptParamsViewModel } from 'src/app/receipt-long-op/view-models/receipt-params.view-model';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf } from '@angular/common';
import { BaseStepComponent } from '../base-step.component';

@UntilDestroy()
@Component({
  selector: 'nts-mileage-refound-data-step',
  templateUrl: './mileage-refound-data-step.component.html',
  styleUrls: ['./mileage-refound-data-step.component.scss'],
  standalone: true,
  imports: [
    NTSTranslatePipe,
    SvgIconComponent,
    ExpandableComponent,
    LabelledTextBoxComponent,
    NgIf
  ]
})
export class MileageRefoundDataStepComponent extends BaseStepComponent<MileageRefoundDataViewModel> {

  parentViewModel: ReceiptParamsViewModel;

  untilDestroyed(): <U>(source: Observable<U>) => Observable<U> {
    return untilDestroyed(this);
  }

  async onViewModelLoaded() {
    this.receiptStepsService.stepLoading.next(true);

    const viewModel = this.receiptStepsService.getCurrentState() as MileageRefoundDataViewModel;

    const rootViewModel = this.receiptStepsService.orchestratorViewModel.rootViewModel;

    const queryParams = await firstValueFrom(this.routeStateService.queryParams);
    const expenseAnnotationId = parseInt(queryParams['expenseAnnotationId'], 10);
    const remoteExpense = queryParams['remoteExpense'] === 'true';

    viewModel.initPresentationLogic(
      expenseAnnotationId,
      remoteExpense,
      rootViewModel.params.expenseSelection.expenseClassification.value,
      rootViewModel.params.expenseState,
      !(await this.isReadOnly(rootViewModel))
    );

    this.receiptStepsService.orchestratorViewModel.rootViewModelModified.pipe(untilDestroyed(this)).subscribe(() => {
      viewModel.validateStep();
    });

    viewModel.validateStep();

    this.inIframe = this.activatedRoute.snapshot.queryParams['iframe'] === 'true' || window.self !== window.top;

    const params = await firstValueFrom(this.routeStateService.params);
    const currentAction = params.get('action');

    if (currentAction === 'add' && viewModel.isValid.value === true) {
      if (!this.receiptStepsService.orchestratorViewModel.errorDuringAdd) {
        this.receiptStepsService.nextStep();
      } else {
        this.receiptStepsService.stepLoading.next(false);
      }
    } else if (currentAction === 'add' && viewModel.isValid.value === false) {
      const toastMessage: ToastMessage = {
        message: MessageResourceManager.Current.getMessage('MissinigDataInStep'),
        type: ToastMessageType.warn,
        title: MessageResourceManager.Current.getMessage('std_Warning')
      };
      this.receiptStepsService.orchestratorViewModel.toastMessageService.showToast(toastMessage);
      this.receiptStepsService.orchestratorViewModel.errorDuringAdd = true;
      this.receiptStepsService.stepLoading.next(false);
    } else {
      this.receiptStepsService.stepLoading.next(false);
    }

    this.viewModel = viewModel;

    //card #4532
    this.parentViewModel = viewModel.parent as ReceiptParamsViewModel;

    const customTitle = viewModel.newLabels().value[viewModel.currentExpenseClassificationType];
    const title = customTitle ??
      MessageResourceManager.Current.getMessage('ExpenseClassification_' + viewModel.expenseClassificationEnum[viewModel.currentExpenseClassificationType]);
    this.telemetryService.trackPageView({ name: title });
  };
}

