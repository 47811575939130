<div class="cropper-wrapper" #cropperWrapper [style.width.px]="viewModel.fixedWidth"
  [style.height.px]="viewModel.fixedHeight">
  <nts-loader *ngIf="viewModel.isLoading$ | async" [skipHeader]="false" [isVisible]="true"
    [animation]="true"></nts-loader>
  <image-cropper [maintainAspectRatio]="false" [format]="viewModel.currentOutputFormat"
    (imageCropped)="imageCropped($event)" [imageURL]="viewModel.imageUrl" (imageLoaded)="imageLoaded($event)"
    (cropperReady)="cropperReady()" [cropper]="viewModel.cropperPosition"
    (loadImageFailed)="loadImageFailed()"></image-cropper>
</div>

<div class="size-info">
  {{'ExpenseData_UploadFileSettings_CurrentImageSize_DisplayName'|NTSTranslate}}: {{ viewModel.croppedImageSize$ | async
  }}
</div>

<div class="custom-component">
  <div class="label-container">
    <nts-label-box [title]="'ExpenseData_UploadFileSettings_OptimizeImage_Title' | NTSTranslate"
      [label]="'ExpenseData_UploadFileSettings_OptimizeImage_Label' | NTSTranslate">
    </nts-label-box>
  </div>
  <div class="edit-container">
    <nts-base-toggle-text-box #baseToggleTextBox (onChange)="toggleOptimizeImageSize($event)" [isReadOnly]="false"
      [isDisabled]="(viewModel.optimizeImageError$ | async)?.length > 0" [isDisabled]="false"
      [checkStatus]="this.viewModel.optimizeImageSize" [showLabelText]="true" [showErrorTooltip]="false"
      [errorList]="(viewModel.optimizeImageError$ | async).length > 0 ? [ viewModel.optimizeImageError$ | async] : []"
      [labelText]="getToggleLabel()">
    </nts-base-toggle-text-box>
  </div>
</div>
