<div class="image-modal-preview" [class.in-iframe]="inIframe" *ngIf="imageSrc && show" tabindex="0"
  (keydown.esc)="closeFullScreen()" #imageModalPreview (keydown.arrowleft)="askPreviousImage.emit()"
  (keydown.arrowright)="askNextImage.emit()">

  <span class="close" [attr.title]="'FullScreen_Close' | NTSTranslate" (click)="closeFullScreen()" tabindex="0"
    (keydown.esc)="closeFullScreen()">
    <svg-icon key="cancel"></svg-icon>
  </span>
  <span class="download" *ngIf="showDownloadButton" [attr.title]="'FullScreen_Download' | NTSTranslate"
    (click)="downloadFileFromFullscreen()" tabindex="0" (keydown.esc)="closeFullScreen()">
    <svg-icon key="download"></svg-icon>
  </span>

  <img class="modal-content" [attr.src]="imageSrc">

  <span class="previous" *ngIf="showNextPreviousButtons" [attr.title]="'FullScreen_Previous' | NTSTranslate"
    (click)="askPreviousImage.emit()" tabindex="0" (keydown.esc)="closeFullScreen()">
    <svg-icon key="arrow-back"></svg-icon>
  </span>
  <span class="next" *ngIf="showNextPreviousButtons" [attr.title]="'FullScreen_Next' | NTSTranslate"
    (click)="askNextImage.emit()" tabindex="0" (keydown.esc)="closeFullScreen()">
    <svg-icon key="arrow-forward"></svg-icon>
  </span>

</div>
