/**
 * Utils class
 */
export class Utils {

  /**
   * Get the extension of a file
   *
   * @param filename file name
   * @returns file extension
   */
  static getExtension(filename: string): string {
    const parts = filename.split('.');
    return parts[parts.length - 1];
  }

  /**
   * Check if the file is an image from the file extension
   *
   * @param filename file name
   * @returns true if the file is an image
   */
  static isImageFromFilename(filename: string): boolean {
    const ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
        return true;
    }
    return false;
  }

  /**
   * Format bytes to human readable string
   *
   * @param bytes bytes to format
   * @param decimals number of decimals
   * @returns formatted string
   */
  static formatBytes(bytes: number | null, decimals: number = 2): string {
    if (bytes === null) {
      return 'N/A Bytes';
    }
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   * Check if the file is an image
   * @param file file
   * @returns true if the file is an image
   */
  static isFileImage(file: File): boolean {
    return file.type.split('/')[0] === 'image';
  }

  /**
   * Convert a file to base64
   *
   * @param f file
   * @returns base64 string
   */
  static convertFileToBase64(f: File): Promise<string> {
    return new Promise(resolve => Object.assign(new FileReader(), { onload() { resolve(this.result) } }).readAsDataURL(f));
  }
}
