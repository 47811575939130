<div class="custom-component">
  <div class="edit-container full-column">
    <nts-base-text-area-box [isDisabled]="true" [value]="viewModel.currentInstructions"
      [activeBorderColor]="viewModel.currentActiveColor" [defaultBorderColor]="viewModel.currentColor"
      [hoverBorderColor]="viewModel.currentHoverColor">
    </nts-base-text-area-box>
  </div>
</div>

<div class="custom-component">
  <div class="edit-container">
    <nts-base-checkbox-text-box #baseToggleTextBox [isReadOnly]="false"
      [activeBorderColor]="viewModel.currentActiveColor" [defaultBorderColor]="viewModel.currentColor"
      [hoverBorderColor]="viewModel.currentHoverColor" [isDisabled]="false"
      [(checkStatus)]="viewModel.rememberToHideThisMessage">
    </nts-base-checkbox-text-box>
  </div>

  <div class="label-container">
    <nts-label-box class="clickable"
      (click)="viewModel.rememberToHideThisMessage = !viewModel.rememberToHideThisMessage"
      [title]="'ExpenseData_ExpenseTypeInstruction_RememberToNotShow_DisplayName' | NTSTranslate"
      [label]="'ExpenseData_ExpenseTypeInstruction_RememberToNotShow_DisplayName' | NTSTranslate">
    </nts-label-box>
  </div>

</div>
