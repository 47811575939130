import { ExternalViewModelTypeDecorator, Filter, FilterOperators, NNumericPropertyViewModel, OrderBy, Where, WhereOperators } from "@nts/std";
import { ExternalDomainModelTypeDecorator, ExternalDomainModelTypeNameDecorator, ExternalViewModel, IdentityTypeDecorator, NumericPropertyViewModel, StringPropertyViewModel } from "@nts/std";
import { Customer } from "src/app/external-remote/customer/customer";
import { GeneralSubjectIdentity } from "src/app/external-remote/general-subject/general-subject.identity";
import { LeadExtViewModel } from "./lead.ext-view-model";
import { ReceiptLongOpOrchestratorViewModel } from "./receipt-long-op.orchestrator-view-model";
import { Observable } from "rxjs";

@IdentityTypeDecorator(GeneralSubjectIdentity)
@ExternalDomainModelTypeDecorator(Customer)
@ExternalDomainModelTypeNameDecorator('Customer')
export class CustomerExtViewModel extends ExternalViewModel<Customer, GeneralSubjectIdentity> {

    // tslint:disable-next-line: variable-name
    private _id: NumericPropertyViewModel;

    get id(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
    }

    private _companyId: NumericPropertyViewModel;

    public get companyId(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._companyId = value; }, this._companyId, 'companyId');
    }

    // tslint:disable-next-line: variable-name
    private _companyName: StringPropertyViewModel;

    get companyName(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._companyName = value; }, this._companyName, 'companyName');
    }

    // tslint:disable-next-line: variable-name
    private _vatNumber: StringPropertyViewModel;

    get vatNumber(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._vatNumber = value; }, this._vatNumber, 'vatNumber');
    }
    // tslint:disable-next-line: variable-name
    private _fiscalCode: StringPropertyViewModel;

    get fiscalCode(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._fiscalCode = value; }, this._fiscalCode, 'fiscalCode');
    }
    // tslint:disable-next-line: variable-name
    private _city: StringPropertyViewModel;

    get city(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._city = value; }, this._city, 'city');
    }
    // tslint:disable-next-line: variable-name
    private _zipCode: StringPropertyViewModel;

    get zipCode(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._zipCode = value; }, this._zipCode, 'zipCode');
    }

    private _leadId: NNumericPropertyViewModel;

    public get leadId(): NNumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._leadId = value; }, this._leadId, 'leadId');
    }

    private _leadCompanyId: NNumericPropertyViewModel;

    public get leadCompanyId(): NNumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._leadCompanyId = value; }, this._leadCompanyId, 'leadCompanyId');
    }


    @ExternalViewModelTypeDecorator(LeadExtViewModel)
    public leadRef: LeadExtViewModel;

    override async postInit(): Promise<void> {
        await super.postInit();

        this.searchProperties = ['Id', 'CompanyName'];
        this.decodeProperties = ['Id', 'CompanyName'];
    }

    override execAutocomplete(term: string, _languageCode?: string, showCodeInDescription?: boolean, basePlainPascalCaseFixedIdentity?: any, decodeProperties?: string[] | null, searchProperties?: string[] | null, orderByPropertyNames?: OrderBy[] | null, additionalFilters?: Filter[] | null, joinOperator?: string | undefined, additionalWheres?: Where[] | null): Observable<Array<{ description: string; identity: any; all: any; }>> {
        if (additionalWheres == null) {
            additionalWheres = [];
        }
        additionalWheres.push(...this.popolaFilters())
        return super.execAutocomplete(term, _languageCode, showCodeInDescription, basePlainPascalCaseFixedIdentity, decodeProperties, searchProperties, orderByPropertyNames, additionalFilters, joinOperator, additionalWheres)
    }



    popolaFilters(): Where[] {
        const date = (this.externalRetriever as ReceiptLongOpOrchestratorViewModel).rootViewModel.params.expenseData.expenseDate.value;

        if (date) {
            const open = new Where();
            open.operator = WhereOperators.OpenParenthesis
            open.position = 0;

            const startDate = new Where();
            startDate.filter.name = "StartDate";
            startDate.filter.operator = FilterOperators.LessOrEqualThan;
            startDate.filter.dateTimeOffSetValue = date;
            startDate.operator = WhereOperators.Filter;
            startDate.position = 1;

            const firstOr = new Where();
            firstOr.operator = WhereOperators.Or;
            firstOr.position = 2;

            const nullStartDate = new Where();
            nullStartDate.filter.name = "StartDate";
            nullStartDate.filter.operator = FilterOperators.IsNull;
            nullStartDate.operator = WhereOperators.Filter;
            nullStartDate.position = 3;

            const close = new Where();
            close.operator = WhereOperators.CloseParenthesis;
            close.position = 4;

            const and = new Where();
            and.operator = WhereOperators.And;
            and.position = 5;

            const reOpen = new Where();
            reOpen.operator = WhereOperators.OpenParenthesis;
            reOpen.position = 6;

            const endDate = new Where();
            endDate.filter.name = "EndDate";
            endDate.filter.operator = FilterOperators.GreaterOrEqualThan;
            endDate.filter.dateTimeOffSetValue = date;
            endDate.operator = WhereOperators.Filter;
            endDate.position = 7;

            const secondOr = new Where();
            secondOr.operator = WhereOperators.Or;
            secondOr.position = 8;


            const nullEndDate = new Where();
            nullEndDate.filter.name = "EndDate";
            nullEndDate.filter.operator = FilterOperators.IsNull;
            nullEndDate.operator = WhereOperators.Filter
            nullEndDate.position = 9;

            const reClose = new Where();
            reClose.operator = WhereOperators.CloseParenthesis;
            reClose.position = 10;



            return [open, startDate, firstOr, nullStartDate, close, and, reOpen, endDate, secondOr, nullEndDate, reClose];
        }
        return [];
    }

}
