import { EnumPropertyViewModel, MessageResourceManager, SourceMessage } from '@nts/std';
import { BehaviorSubject } from 'rxjs';
import { ExpenseState } from 'src/app/expense-annotation/generated/domain-models/enums/generated-expense-state';
import { StepViewModelAwareInterface } from 'src/app/shared/models/step-view-model-aware.interface';
import { ValidCheckInterface } from 'src/app/shared/models/valid-check.interface';
import { ColorUtility } from 'src/app/shared/services/color-utility';
import { ExpenseClassificationMapper } from 'src/app/shared/services/expense-classification-mapper';
import { ExpenseStateMapper } from 'src/app/shared/services/expense-state-mapper';
import { ExpenseClassification } from '../generated/domain-models/enums/generated-expense-classification';
import { GeneratedOtherDataViewModel } from '../generated/view-models/generated-other-data.view-model';
import { ErrorManager } from '../services/errorr-manager.service';
import { ReceiptParamsViewModel } from './receipt-params.view-model';

export class OtherDataViewModel extends GeneratedOtherDataViewModel implements StepViewModelAwareInterface {

  currentColor = null;
  currentActiveColor = null;
  currentHoverColor = null;
  currentExpenseClassificationType: ExpenseClassification;
  expenseClassificationEnum = ExpenseClassification;
  isValid = new BehaviorSubject<boolean>(false);
  hasActionsButtons = new BehaviorSubject<boolean>(true);
  isPreviousDisabled = new BehaviorSubject<boolean>(false);
  hideNext = new BehaviorSubject<boolean>(false);
  expenseState: EnumPropertyViewModel;
  expenseStateMapper = ExpenseStateMapper;
  showCurrentState: boolean;

  initPresentationLogic(
    expenseAnnotationId: number,
    remoteExpense: boolean,
    expenseClassificationType: ExpenseClassification,
    expenseState: EnumPropertyViewModel,
    isEnabled: boolean
  ) {

    this.isEnabled = isEnabled;
    this.showCurrentState = expenseAnnotationId > 0 && remoteExpense === true;
    this.expenseState = expenseState;
    this.currentExpenseClassificationType = expenseClassificationType;
    this.currentColor = ColorUtility.shade(ExpenseClassificationMapper.getColorByClassificationType(expenseClassificationType), -0.1);
    this.currentActiveColor = ColorUtility.shade(this.currentColor, -0.1)
    this.currentHoverColor = ColorUtility.shade(this.currentColor, -0.3)

    if (!isEnabled) {
      this.peopleQuantity.isEnabled = isEnabled;
      this.guestQuantity.isEnabled = isEnabled;
    }

    // #5331 - per attivare lo sblocco del tasto continua appena si modifica e senza uscire dal campo.
    // ATTENZIONE, instantModelChange crea problemi con inputMask, perchè valida appena premi un tasto, non usarlo
    // this.peopleQuantity.instantModelChange = true;

    this.peopleQuantity.defaultColor = this.currentColor;
    this.peopleQuantity.activeColor = this.currentActiveColor;
    this.peopleQuantity.hoverColor = this.currentHoverColor;

    this.guestQuantity.defaultColor = this.currentColor;
    this.guestQuantity.activeColor = this.currentActiveColor;
    this.guestQuantity.hoverColor = this.currentHoverColor;
  }

  async validateStep(): Promise<void> {
    this.validate();
    if (this.peopleQuantity.isEnabled && (this.peopleQuantity?.value <= 0 || this.peopleQuantity == undefined)) {
      const errorObj = ErrorManager.getCustomError(
        this.peopleQuantity.metadataDescription,
        'peopleQuantity',
        MessageResourceManager.Current.getMessage("PeopleNumberRequired"),
        "MIN_VALUE_ERROR",
      )
      this.peopleQuantity.addError(SourceMessage.ViewModel, errorObj)
    }
    this.isValid.next(!this.hasErrors)
  };

  newLabels(): BehaviorSubject<any[]> {
    return (this.parent as ReceiptParamsViewModel).newLabels$;
  }

}
