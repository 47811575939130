import {
	StringPropertyViewModel, GuidPropertyViewModel, EnumPropertyViewModel, BoolPropertyViewModel, NumericPropertyViewModel, DateTimeOffsetPropertyViewModel, DateTimePropertyViewModel,
	InternalViewModelTypeDecorator,
	NNumericPropertyViewModel, NDateTimeOffsetPropertyViewModel, NDateTimePropertyViewModel, InternalViewModel,
	ExternalViewModelTypeDecorator
} from '@nts/std';
import { ReceiptParams } from '../../domain-models/receipt-params';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';
import { ExpenseDataViewModel } from '../../view-models/expense-data.view-model';
import { FoodDataViewModel } from '../../view-models/food-data.view-model';
import { TransportDataViewModel } from '../../view-models/transport-data.view-model';
import { MileageRefoundDataViewModel } from '../../view-models/mileage-refound-data.view-model';
import { OtherDataViewModel } from '../../view-models/other-data.view-model';
import { PaymentDataViewModel } from '../../view-models/payment-data.view-model';
import { AccommodationDataViewModel } from '../../view-models/accommodation-data.view-model';
import { ExpenseModelDataViewModel } from '../../view-models/expense-model-data.view-model';
import { ExpenseSelectionViewModel } from '../../view-models/expense-selection.view-model';
import { UserOfTenantExtendedExtViewModel } from '../../view-models/user-of-tenant-extended.ext-view-model';

export class GeneratedReceiptParamsViewModel extends InternalViewModel<ReceiptParams, ReceiptParamsIdentity> {

	private _id: NumericPropertyViewModel;

	public get id(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
	}

	@InternalViewModelTypeDecorator(ExpenseSelectionViewModel)
	public expenseSelection: ExpenseSelectionViewModel;

	@InternalViewModelTypeDecorator(ExpenseDataViewModel)
	public expenseData: ExpenseDataViewModel;

	@InternalViewModelTypeDecorator(FoodDataViewModel)
	public foodData: FoodDataViewModel;

	@InternalViewModelTypeDecorator(AccommodationDataViewModel)
	public accommodationData: AccommodationDataViewModel;

	@InternalViewModelTypeDecorator(TransportDataViewModel)
	public transportData: TransportDataViewModel;

	@InternalViewModelTypeDecorator(MileageRefoundDataViewModel)
	public mileageRefoundData: MileageRefoundDataViewModel;

	@InternalViewModelTypeDecorator(OtherDataViewModel)
	public otherData: OtherDataViewModel;

	@InternalViewModelTypeDecorator(PaymentDataViewModel)
	public paymentData: PaymentDataViewModel;

	@InternalViewModelTypeDecorator(ExpenseModelDataViewModel)
	public expenseModelData: ExpenseModelDataViewModel;

	private _expenseState: EnumPropertyViewModel;

	public get expenseState(): EnumPropertyViewModel {
		return this.getEnumPropertyViewModel((value) => { this._expenseState = value; }, this._expenseState, 'expenseState');
	}


	private _userId: NNumericPropertyViewModel;

	public get userId(): NNumericPropertyViewModel {
		return this.getNNumericPropertyViewModel((value) => { this._userId = value; }, this._userId, 'userId');
	}

	@ExternalViewModelTypeDecorator(UserOfTenantExtendedExtViewModel)
	public userRef: UserOfTenantExtendedExtViewModel;

}
